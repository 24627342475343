import { useTranslation } from 'react-i18next';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/iconify';
import { useAuthContext } from '../../../auth/useAuthContext';
// import Label from '../../../components/label';

// ----------------------------------------------------------------------
const useNavConfig = () => {
  const { t } = useTranslation();
  const { user: logedUser } = useAuthContext();
  const icon = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

  const ICONS = {
    accountability: icon('map:accounting'),
    financial: icon('fluent-mdl2:financial-solid'),
    banking: icon('ph:bank-bold'),
    humanResources: icon('ic:round-group'),
    contracts: icon('teenyicons:contract-solid'),
    logistics: icon('mdi:truck'),
    services: icon('bi:kanban-fill'),
    bidding: icon('material-symbols:unknown-document'),
    lawsuit: icon('octicon:law-16'),
    reports: icon('mdi:report-bar'),
    email: icon('ic:round-email'),
    chat: icon('ic:baseline-chat'),
    calendar: icon('material-symbols:calendar-month-rounded'),
    kanban: icon('tabler:layout-board'),
    user: icon('mdi:user-box'),
    userList: icon('mdi:user-group'),
    config: icon('material-symbols:settings-applications-rounded'),
    faqs: icon('mdi:question-mark-box'),
  };

  const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      items: [
        {
          title: t('accountability'),
          path: PATH_DASHBOARD.accountability.root,
          icon: ICONS.accountability,
          children: [
            { title: t('general'), path: PATH_DASHBOARD.accountability.dashboards },
            { title: t('declarations'), path: PATH_DASHBOARD.accountability.declarations },
          ],
        },
        {
          title: t('financial'),
          path: PATH_DASHBOARD.financial.root,
          icon: ICONS.financial,
          children: [
            { title: t('transfers'), path: PATH_DASHBOARD.financial.transfer },
            { title: t('commitments'), path: PATH_DASHBOARD.financial.commitments },
            { title: t('banks'), path: PATH_DASHBOARD.financial.bank },
            { title: t('financialAccounts'), path: PATH_DASHBOARD.financial.financialAccounts },
            { title: t('bankStatements'), path: PATH_DASHBOARD.financial.bankStatements },
            { title: t('financialEntries'), path: PATH_DASHBOARD.financial.financialEntries },
          ],
        },
        {
          title: t('accounting'),
          path: PATH_DASHBOARD.accounting.root,
          icon: ICONS.banking,
          children: [
            {
              title: t('register'),
              path: PATH_DASHBOARD.accounting.register.root,
              children: [
                {
                  title: t('ledgerAccount'),
                  path: PATH_DASHBOARD.accounting.register.ledgerAccount,
                },
                { title: t('accountTypes'), path: PATH_DASHBOARD.accounting.register.accountTypes },
                { title: t('costCenter'), path: PATH_DASHBOARD.accounting.register.costCenter },
                { title: t('history'), path: PATH_DASHBOARD.accounting.register.history },
                { title: t('department'), path: PATH_DASHBOARD.accounting.register.department },
                {
                  title: t('bankReconciliation'),
                  path: PATH_DASHBOARD.accounting.register.bankReconciliation,
                },
              ],
            },
            {
              title: t('movements'),
              path: PATH_DASHBOARD.accounting.movements.root,
              children: [
                {
                  title: t('accountingEntries'),
                  path: PATH_DASHBOARD.accounting.movements.accountingEntries,
                },
              ],
            },
          ],
        },
        {
          title: t('humanResources'),
          path: PATH_DASHBOARD.humanResources.root,
          icon: ICONS.humanResources,
          children: [
            { title: t('payroll'), path: PATH_DASHBOARD.humanResources.payroll },
            { title: t('collaborators'), path: PATH_DASHBOARD.humanResources.collaborators },
            { title: t('positions'), path: PATH_DASHBOARD.humanResources.positions },
            { title: t('references'), path: PATH_DASHBOARD.humanResources.references },
            { title: t('incomeTax'), path: PATH_DASHBOARD.humanResources.incomeTax },
            { title: t('inss'), path: PATH_DASHBOARD.humanResources.inss },
            {
              title: t('payrollPreferences'),
              path: PATH_DASHBOARD.humanResources.payrollPreferences,
            },
          ],
        },
        {
          title: t('contracts'),
          path: PATH_DASHBOARD.contracts.root,
          icon: ICONS.contracts,
          children: [
            { title: t('generalContracts'), path: PATH_DASHBOARD.contracts.contracts },
            { title: t('managementContract'), path: PATH_DASHBOARD.contracts.managementContract },
            { title: t('supplierContract'), path: PATH_DASHBOARD.contracts.supplierContract },
            { title: t('additives'), path: PATH_DASHBOARD.contracts.additive },
            { title: t('occurrences'), path: PATH_DASHBOARD.contracts.occurrences },
            { title: t('resources'), path: PATH_DASHBOARD.contracts.resources },
            { title: t('people'), path: PATH_DASHBOARD.contracts.people },
          ],
        },
        {
          title: t('logistics'),
          path: PATH_DASHBOARD.logistics.root,
          icon: ICONS.logistics,
          children: [
            { title: t('balance'), path: PATH_DASHBOARD.logistics.balance },
            { title: t('orderBlocks'), path: PATH_DASHBOARD.logistics.orderBlocks },
            { title: t('routes'), path: PATH_DASHBOARD.logistics.routes },
            { title: t('vehicles'), path: PATH_DASHBOARD.logistics.vehicles },
            { title: t('sellerDriver'), path: PATH_DASHBOARD.logistics.sellerDriver },
          ],
        },
        {
          title: t('projects'),
          path: PATH_DASHBOARD.projects.root,
          icon: ICONS.services,
          children: [
            { title: t('projects'), path: PATH_DASHBOARD.projects.projects },
            { title: t('services'), path: PATH_DASHBOARD.projects.services },
            { title: t('serviceOrders'), path: PATH_DASHBOARD.projects.serviceOrders },
            { title: t('clients'), path: PATH_DASHBOARD.projects.clients },
          ],
        },
        {
          title: t('biddings'),
          path: PATH_DASHBOARD.bidding.root,
          icon: ICONS.bidding,
          children: [
            { title: t('biddingList'), path: PATH_DASHBOARD.bidding.biddings },
            { title: t('events'), path: PATH_DASHBOARD.bidding.events },
            { title: t('lots'), path: PATH_DASHBOARD.bidding.lots },
            { title: t('results'), path: PATH_DASHBOARD.bidding.results },
            { title: t('contractors'), path: PATH_DASHBOARD.bidding.contractors },
            { title: t('companies'), path: PATH_DASHBOARD.bidding.companies },
          ],
        },
        { title: t('lawsuits'), path: PATH_DASHBOARD.lawsuits.root, icon: ICONS.lawsuit },
        {
          title: t('reports'),
          path: PATH_DASHBOARD.reports.root,
          icon: ICONS.reports,
          children: [
            // {
            //   title: t('accountability'),
            //   path: PATH_DASHBOARD.reports.accountability.root,
            // },
            {
              title: t('accounting'),
              path: PATH_DASHBOARD.reports.accounting.root,
              children: [
                { title: t('is'), path: PATH_DASHBOARD.reports.accounting.is },
                {
                  title: t('balanceSheet'),
                  path: PATH_DASHBOARD.reports.accounting.balanceSheet.root,
                  children: [
                    {
                      title: t('Balancete Analítico'),
                      path: PATH_DASHBOARD.reports.accounting.balanceSheet.analyticalAccumulated,
                    },
                    {
                      title: t('Balancete Sintético'),
                      path: PATH_DASHBOARD.reports.accounting.balanceSheet.synthetic,
                    },
                  ],
                },
                { title: t('Diário'), path: PATH_DASHBOARD.reports.accounting.diary },
                { title: t('Razão'), path: PATH_DASHBOARD.reports.accounting.ledger },
                {
                  title: t('Balanço Patrimonial'),
                  path: PATH_DASHBOARD.reports.accounting.balanceSheetTrial,
                },
                // { title: t('Razão'), path: PATH_DASHBOARD.reports.root },
                // { title: t('Estoque'), path: PATH_DASHBOARD.reports.root },
                // { title: t('Demonstrativo de Imobilizado'), path: PATH_DASHBOARD.reports.root },
                // { title: t('Demonstrativo de Depreciação'), path: PATH_DASHBOARD.reports.root },
              ],
            },
            {
              title: t('financial'),
              path: PATH_DASHBOARD.reports.financial.root,
              children: [
                {
                  title: t('transfers'),
                  path: PATH_DASHBOARD.reports.financial.transfer.root,
                  children: [
                    {
                      title: t('general'),
                      path: PATH_DASHBOARD.reports.financial.transfer.general,
                    },
                    {
                      title: t('approved'),
                      path: PATH_DASHBOARD.reports.financial.transfer.approved,
                    },
                    {
                      title: t('reproved'),
                      path: PATH_DASHBOARD.reports.financial.transfer.reproved,
                    },
                    {
                      title: t('awaiting_audition'),
                      path: PATH_DASHBOARD.reports.financial.transfer.awaiting_audition,
                    },
                  ],
                },
                {
                  title: t('commitments'),
                  path: PATH_DASHBOARD.reports.financial.commitments.root,
                  children: [
                    {
                      title: t('general'),
                      path: PATH_DASHBOARD.reports.financial.commitments.general,
                    },
                    {
                      title: t('approved'),
                      path: PATH_DASHBOARD.reports.financial.commitments.approved,
                    },
                    {
                      title: t('reproved'),
                      path: PATH_DASHBOARD.reports.financial.commitments.reproved,
                    },
                    {
                      title: t('awaiting_audition'),
                      path: PATH_DASHBOARD.reports.financial.commitments.awaiting_audition,
                    },
                  ],
                },
                {
                  title: t('financialEntries'),
                  path: PATH_DASHBOARD.reports.financial.financialEntries.root,
                  children: [
                    {
                      title: t('general'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries.general,
                    },
                    {
                      title: t('approved'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries.approved,
                    },
                    {
                      title: t('reproved'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries.reproved,
                    },
                    {
                      title: t('awaiting_audition'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries.awaiting_audition,
                    },
                    {
                      title: t('formalError'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries.formalError,
                    },
                    {
                      title: t('damageToThePublicTreasury'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries
                        .damageToThePublicTreasury,
                    },
                    {
                      title: t('otherNonConformities'),
                      path: PATH_DASHBOARD.reports.financial.financialEntries.otherNonConformities,
                    },
                  ],
                },
                {
                  title: t('penalties'),
                  path: PATH_DASHBOARD.reports.financial.penaltiesReport,
                },
                {
                  title: t('futureEntry'),
                  path: PATH_DASHBOARD.reports.financial.futureEntriesReport,
                },
                {
                  title: t('entryByContract'),
                  path: PATH_DASHBOARD.reports.financial.entriesByContractReport,
                },
              ],
            },
          ],
        },
      ],
    },

    // APP
    // ----------------------------------------------------------------------
    {
      subheader: t('system'),
      items: [
        // {
        //   title: t('InMail'),
        //   path: PATH_DASHBOARD.mail.root,
        //   icon: ICONS.email,
        //   info: <Label color="error">+32</Label>,
        // },
        // {
        //   title: t('Chat'),
        //   path: PATH_DASHBOARD.chat.root,
        //   icon: ICONS.chat,
        // },
        {
          title: t('Calendario'),
          path: PATH_DASHBOARD.calendar,
          icon: ICONS.calendar,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: t('configurations'),
      items: [
        {
          title: t('userList'),
          path: PATH_DASHBOARD.user.list,
          icon: ICONS.userList,
        },
        {
          title: t('userAccount'),
          path: PATH_DASHBOARD.user.account,
          icon: ICONS.user,
        },
      ],
    },
  ];

  if (!logedUser.isAuditor) {
    navConfig[0].items[2].children[0].children.push({
      title: t('structures'),
      path: PATH_DASHBOARD.accounting.register.structures.root,
      children: [
        { title: t('al'), path: PATH_DASHBOARD.accounting.register.structures.al },
        { title: t('se'), path: PATH_DASHBOARD.accounting.register.structures.se },
        { title: t('is'), path: PATH_DASHBOARD.accounting.register.structures.is },
        { title: t('ais'), path: PATH_DASHBOARD.accounting.register.structures.ais },
        {
          title: t('scse'),
          path: PATH_DASHBOARD.accounting.register.structures.scse,
        },
        { title: t('scf'), path: PATH_DASHBOARD.accounting.register.structures.scf },
        {
          title: t('ebitda'),
          path: PATH_DASHBOARD.accounting.register.structures.ebitda,
        },
      ],
    });

    navConfig[0].items[2].children[1].children.push({
      title: t('entriesImport'),
      path: PATH_DASHBOARD.accounting.movements.entriesImport,
    });
  }

  return navConfig;
};

export default useNavConfig;
