import { InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

RHFPercentageField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFPercentageField({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask mask="99,99" value={field.value} onChange={field.onChange} {...other}>
          {(inputProps) => (
            <TextField
              {...inputProps}
              fullWidth
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
              error={!!error}
              helperText={error ? error?.message : helperText}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              {...other}
            />
          )}
        </InputMask>
      )}
    />
  );
}
