import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import { PATH_AFTER_LOGIN } from '../config-global';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import {
  AISStructure,
  AISStructureEditPage,
  AISStructureNewPage,
  ALStructure,
  ALStructureEditPage,
  ALStructureNewPage,
  AccountFinancialEntries,
  AccountTypes,
  AccountingEntries,
  AccumulatedAnalyticalBalanceSheetReport,
  Additive,
  AnalyticalBalanceSheetReport,
  Balance,
  BalanceSheetReport,
  Bank,
  BankReconciliation,
  BankStatements,
  Biddings,
  BlankPage,
  BlogNewPostPage,
  BlogPostPage,
  BlogPostsPage,
  CalendarPage,
  ChatPage,
  Clients,
  Collaborators,
  ComingSoonPage,
  Commitments,
  CommitmentsReportApproved,
  CommitmentsReportAwaitingAudition,
  CommitmentsReportGeneral,
  CommitmentsReportReproved,
  Companies,
  Contractors,
  Contracts,
  CostCenter,
  Declarations,
  Department,
  DiaryReport,
  EBITDAStructure,
  EBITDAStructureEditPage,
  EBITDAStructureNewPage,
  EcommerceCheckoutPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  EcommerceProductEditPage,
  EcommerceProductListPage,
  EcommerceShopPage,
  EditAccountTypes,
  EditAccountingEntries,
  EditAdditive,
  EditBalance,
  EditBank,
  EditBankStatements,
  EditBiddings,
  EditClients,
  EditCollaborators,
  EditCommitments,
  EditCompanies,
  EditContractors,
  EditContracts,
  EditCostCenter,
  EditDeclarations,
  EditDepartment,
  EditEntriesImport,
  EditEvents,
  EditFinancialAccounts,
  EditFinancialEntries,
  EditFinancialEntriesAudition,
  EditHistory,
  EditINSS,
  EditIncomeTax,
  EditLawsuits,
  EditLedgerAccount,
  EditLots,
  EditManagementContract,
  EditOccurrences,
  EditOrderBlocks,
  EditPayrolls,
  EditPeople,
  EditPositions,
  EditProjects,
  EditRefenreces,
  EditResources,
  EditResults,
  EditRoutes,
  EditSellerDrivers,
  EditServiceOrders,
  EditServices,
  EditSupplierContract,
  EditTransfer,
  EditVehicles,
  EntriesByContractReport,
  EntriesImport,
  Events,
  FaqsPage,
  FileManagerPage,
  FinancialAccounts,
  FinancialEntries,
  FinancialEntriesAudition,
  FinancialEntryReportApproved,
  FinancialEntryReportAwaitingAudition,
  FinancialEntryReportDamageToThePublicTreasury,
  FinancialEntryReportFormalError,
  FinancialEntryReportGeneral,
  FinancialEntryReportOtherNonConformities,
  FinancialEntryReportReproved,
  FutureEntriesReport,
  GeneralAnalyticsPage,
  GeneralAppPage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralFilePage,
  GeneralSettingsPage,
  History,
  INSS,
  ISReport,
  ISStructure,
  ISStructureEditPage,
  ISStructureNewPage,
  IncomeTax,
  InvoiceCreatePage,
  InvoiceDetailsPage,
  InvoiceEditPage,
  InvoiceListPage,
  KanbanPage,
  Lawsuits,
  LedgerAccount,
  LedgerReport,
  LoginPage,
  Lots,
  MailPage,
  MaintenancePage,
  ManagementContract,
  NewAccountTypes,
  NewAccountability,
  NewAccountingEntries,
  NewAdditive,
  NewBalance,
  NewBank,
  NewBankStatements,
  NewBiddings,
  NewClients,
  NewCollaborators,
  NewCommitments,
  NewCompanies,
  NewContractors,
  NewContracts,
  NewCostCenter,
  NewDeclarations,
  NewDepartment,
  NewEvents,
  NewFinancialAccounts,
  NewFinancialEntries,
  NewFinancialEntriesAudition,
  NewHistory,
  NewINSS,
  NewIncomeTax,
  NewLawsuits,
  NewLedgerAccount,
  NewLots,
  NewManagementContract,
  NewOccurrences,
  NewOrderBlocks,
  NewPasswordPage,
  NewPayrollPreferences,
  NewPayrolls,
  NewPeople,
  NewPositions,
  NewProjects,
  NewRefenreces,
  NewResources,
  NewResults,
  NewRoutes,
  NewSellerDrivers,
  NewServiceOrders,
  NewServices,
  NewSupplierContract,
  NewTransfer,
  NewVehicles,
  Occurrences,
  OrderBlocks,
  Page403,
  Page404,
  Page500,
  Payrolls,
  PenaltiesReport,
  People,
  PermissionDeniedPage,
  Positions,
  Projects,
  Refenreces,
  RegisterPage,
  Reports,
  ResetPasswordPage,
  Resources,
  Results,
  Routes,
  SCFStructure,
  SCFStructureEditPage,
  SCFStructureNewPage,
  SCSEStructure,
  SCSEStructureEditPage,
  SCSEStructureNewPage,
  SEStructure,
  SEStructureEditPage,
  SEStructureNewPage,
  SellerDrivers,
  ServiceOrders,
  Services,
  SupplierContract,
  SyntheticBalanceSheetReport,
  Transfer,
  TransferReportApproved,
  TransferReportAwaitingAudition,
  TransferReportGeneral,
  TransferReportReproved,
  UserAccountPage,
  UserCardsPage,
  UserCreatePage,
  UserEditPage,
  UserListPage,
  UserProfilePage,
  Vehicles,
  VerifyCodePage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: 'main',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'accountability',
          children: [
            { element: <Navigate to="/main/accountability/dashboard" replace />, index: true },
            { path: 'dashboard', element: <GeneralAppPage /> },
            { path: 'declarations', element: <Declarations /> },
            { path: 'declarations/:protocol/edit', element: <EditDeclarations /> },
            { path: 'declarations/new', element: <NewDeclarations /> },
            { path: 'new', element: <NewAccountability /> },
          ],
        },
        {
          path: 'accounting',
          children: [
            {
              element: <Navigate to="/main/accounting/register/ledger-account" replace />,
              index: true,
            },
            {
              path: 'register',
              children: [
                {
                  element: <Navigate to="/main/accounting/register/ledger-account" replace />,
                  index: true,
                },
                { path: 'ledger-account', element: <LedgerAccount /> },
                { path: 'ledger-account/:protocol/edit', element: <EditLedgerAccount /> },
                { path: 'ledger-account/new', element: <NewLedgerAccount /> },
                { path: 'account-types', element: <AccountTypes /> },
                { path: 'account-types/:protocol/edit', element: <EditAccountTypes /> },
                { path: 'account-types/new', element: <NewAccountTypes /> },
                { path: 'cost-center', element: <CostCenter /> },
                { path: 'cost-center/:protocol/edit', element: <EditCostCenter /> },
                { path: 'cost-center/new', element: <NewCostCenter /> },
                { path: 'history', element: <History /> },
                { path: 'history/:protocol/edit', element: <EditHistory /> },
                { path: 'history/new', element: <NewHistory /> },
                { path: 'department', element: <Department /> },
                { path: 'department/:protocol/edit', element: <EditDepartment /> },
                { path: 'department/new', element: <NewDepartment /> },
                { path: 'bank-reconciliation', element: <BankReconciliation /> },
                {
                  path: 'structures',
                  children: [
                    {
                      element: <Navigate to="/main/accounting/register/structures/ais" replace />,
                      index: true,
                    },
                    { path: 'ais', element: <AISStructure /> },
                    { path: 'ais/:protocol/edit', element: <AISStructureEditPage /> },
                    { path: 'ais/new', element: <AISStructureNewPage /> },
                    { path: 'al', element: <ALStructure /> },
                    { path: 'al/:protocol/edit', element: <ALStructureEditPage /> },
                    { path: 'al/new', element: <ALStructureNewPage /> },
                    { path: 'ebitda', element: <EBITDAStructure /> },
                    {
                      path: 'ebitda/:protocol/edit',
                      element: <EBITDAStructureEditPage />,
                    },
                    { path: 'ebitda/new', element: <EBITDAStructureNewPage /> },
                    { path: 'is', element: <ISStructure /> },
                    { path: 'is/:protocol/edit', element: <ISStructureEditPage /> },
                    { path: 'is/new', element: <ISStructureNewPage /> },
                    { path: 'scf', element: <SCFStructure /> },
                    { path: 'scf/:protocol/edit', element: <SCFStructureEditPage /> },
                    { path: 'scf/new', element: <SCFStructureNewPage /> },
                    { path: 'scse', element: <SCSEStructure /> },
                    { path: 'scse/:protocol/edit', element: <SCSEStructureEditPage /> },
                    { path: 'scse/new', element: <SCSEStructureNewPage /> },
                    { path: 'se', element: <SEStructure /> },
                    { path: 'se/:protocol/edit', element: <SEStructureEditPage /> },
                    { path: 'se/new', element: <SEStructureNewPage /> },
                  ],
                },
              ],
            },
            {
              path: 'movements',
              children: [
                {
                  element: <Navigate to="/main/accounting/movements/accounting-entries" replace />,
                  index: true,
                },
                { path: 'accounting-entries', element: <AccountingEntries /> },
                { path: 'accounting-entries/:protocol/edit', element: <EditAccountingEntries /> },
                { path: 'accounting-entries/new', element: <NewAccountingEntries /> },
                { path: 'entries-import', element: <EntriesImport /> },
                { path: 'entries-import/:protocol/edit', element: <EditEntriesImport /> },
              ],
            },
          ],
        },

        {
          path: 'bidding',
          children: [
            { element: <Navigate to="/main/bidding/list" replace />, index: true },
            { path: 'list', element: <Biddings /> },
            { path: 'new', element: <NewBiddings /> },
            { path: ':protocol/edit', element: <EditBiddings /> },
            { path: 'companies', element: <Companies /> },
            { path: 'companies/new', element: <NewCompanies /> },
            { path: 'companies/:protocol/edit', element: <EditCompanies /> },
            { path: 'contractors', element: <Contractors /> },
            { path: 'contractors/new', element: <NewContractors /> },
            { path: 'contractors/:protocol/edit', element: <EditContractors /> },
            { path: 'events', element: <Events /> },
            { path: 'events/new', element: <NewEvents /> },
            { path: 'events/:protocol/edit', element: <EditEvents /> },
            { path: 'lots', element: <Lots /> },
            { path: 'lots/new', element: <NewLots /> },
            { path: 'lots/:protocol/edit', element: <EditLots /> },
            { path: 'results', element: <Results /> },
            { path: 'results/new', element: <NewResults /> },
            { path: 'results/:protocol/edit', element: <EditResults /> },
          ],
        },

        {
          path: 'contracts',
          children: [
            {
              element: <Navigate to="/main/accountability/contracts/contracts" replace />,
              index: true,
            },
            { path: 'additive', element: <Additive /> },
            { path: 'additive/new', element: <NewAdditive /> },
            { path: 'additive/:protocol/edit', element: <EditAdditive /> },
            { path: 'contracts', element: <Contracts /> },
            { path: 'contracts/new', element: <NewContracts /> },
            { path: 'contracts/:protocol/edit', element: <EditContracts /> },
            { path: 'management-contract', element: <ManagementContract /> },
            { path: 'management-contract/new', element: <NewManagementContract /> },
            { path: 'management-contract/:protocol/edit', element: <EditManagementContract /> },
            { path: 'occurrences', element: <Occurrences /> },
            { path: 'occurrences/new', element: <NewOccurrences /> },
            { path: 'occurrences/:protocol/edit', element: <EditOccurrences /> },
            { path: 'people', element: <People /> },
            { path: 'people/new', element: <NewPeople /> },
            { path: 'people/:protocol/edit', element: <EditPeople /> },
            { path: 'resources', element: <Resources /> },
            { path: 'resources/new', element: <NewResources /> },
            { path: 'resources/:protocol/edit', element: <EditResources /> },
            { path: 'supplier-contract', element: <SupplierContract /> },
            { path: 'supplier-contract/new', element: <NewSupplierContract /> },
            { path: 'supplier-contract/:protocol/edit', element: <EditSupplierContract /> },
          ],
        },

        {
          path: 'financial',
          children: [
            { element: <Navigate to="/main/financial/transfer" replace />, index: true },
            { path: 'bank-statements', element: <BankStatements /> },
            { path: 'bank-statements/new', element: <NewBankStatements /> },
            { path: 'bank-statements/:protocol/edit', element: <EditBankStatements /> },
            { path: 'bank', element: <Bank /> },
            { path: 'bank/new', element: <NewBank /> },
            { path: 'bank/:code/edit', element: <EditBank /> },
            { path: 'commitments', element: <Commitments /> },
            { path: 'commitments/new', element: <NewCommitments /> },
            { path: 'commitments/:protocol/edit', element: <EditCommitments /> },
            { path: 'financial-accounts', element: <FinancialAccounts /> },
            { path: 'financial-accounts/new', element: <NewFinancialAccounts /> },
            { path: 'financial-accounts/:protocol/edit', element: <EditFinancialAccounts /> },
            { path: 'financial-entiresAudition', element: <FinancialEntriesAudition /> },
            { path: 'financial-entiresAudition/new', element: <NewFinancialEntriesAudition /> },
            {
              path: 'financial-entiresAudition/:protocol/edit',
              element: <EditFinancialEntriesAudition />,
            },
            { path: 'financial-entries', element: <FinancialEntries /> },
            { path: 'financial-entries/new', element: <NewFinancialEntries /> },
            { path: 'financial-entries/:protocol/edit', element: <EditFinancialEntries /> },
            { path: 'financial-entries/:protocol/account', element: <AccountFinancialEntries /> },
            { path: 'transfer', element: <Transfer /> },
            { path: 'transfer/new', element: <NewTransfer /> },
            { path: 'transfer/:protocol/edit', element: <EditTransfer /> },
          ],
        },

        {
          path: 'human-resources',
          children: [
            { element: <Navigate to="/main/human-resources/payroll" replace />, index: true },
            { path: 'collaborators', element: <Collaborators /> },
            { path: 'collaborators/new', element: <NewCollaborators /> },
            { path: 'collaborators/:protocol/edit', element: <EditCollaborators /> },
            { path: 'payroll', element: <Payrolls /> },
            { path: 'payroll/new', element: <NewPayrolls /> },
            { path: 'payroll/:protocol/edit', element: <EditPayrolls /> },
            { path: 'positions', element: <Positions /> },
            { path: 'positions/new', element: <NewPositions /> },
            { path: 'positions/:protocol/edit', element: <EditPositions /> },
            { path: 'references', element: <Refenreces /> },
            { path: 'references/new', element: <NewRefenreces /> },
            { path: 'references/:protocol/edit', element: <EditRefenreces /> },
            { path: 'income-tax', element: <IncomeTax /> },
            { path: 'income-tax/new', element: <NewIncomeTax /> },
            { path: 'income-tax/:protocol/edit', element: <EditIncomeTax /> },
            { path: 'inss', element: <INSS /> },
            { path: 'inss/new', element: <NewINSS /> },
            { path: 'inss/:protocol/edit', element: <EditINSS /> },
            { path: 'payroll-preferences', element: <NewPayrollPreferences /> },
          ],
        },

        {
          path: 'lawsuits',
          children: [
            { path: '', element: <Lawsuits /> },
            { path: 'new', element: <NewLawsuits /> },
            { path: ':protocol/edit', element: <EditLawsuits /> },
          ],
        },

        {
          path: 'logistics',
          children: [
            { element: <Navigate to="/main/logistics/balance" replace />, index: true },
            { path: 'balance', element: <Balance /> },
            { path: 'balance/new', element: <NewBalance /> },
            { path: 'balance/:protocol/edit', element: <EditBalance /> },
            { path: 'order-blocks', element: <OrderBlocks /> },
            { path: 'order-blocks/new', element: <NewOrderBlocks /> },
            { path: 'order-blocks/:protocol/edit', element: <EditOrderBlocks /> },
            { path: 'routes', element: <Routes /> },
            { path: 'routes/new', element: <NewRoutes /> },
            { path: 'routes/:protocol/edit', element: <EditRoutes /> },
            { path: 'vehicles', element: <Vehicles /> },
            { path: 'vehicles/new', element: <NewVehicles /> },
            { path: 'vehicles/:protocol/edit', element: <EditVehicles /> },
            { path: 'seller-driver', element: <SellerDrivers /> },
            { path: 'seller-driver/new', element: <NewSellerDrivers /> },
            { path: 'seller-driver/:protocol/edit', element: <EditSellerDrivers /> },
          ],
        },

        {
          path: 'projects',
          children: [
            { element: <Navigate to="/main/projects/projects" replace />, index: true },
            { path: 'projects', element: <Projects /> },
            { path: 'projects/new', element: <NewProjects /> },
            { path: 'projects/:protocol/edit', element: <EditProjects /> },
            { path: 'service-orders', element: <ServiceOrders /> },
            { path: 'service-orders/new', element: <NewServiceOrders /> },
            { path: 'service-orders/:protocol/edit', element: <EditServiceOrders /> },
            { path: 'services', element: <Services /> },
            { path: 'services/new', element: <NewServices /> },
            { path: 'services/:protocol/edit', element: <EditServices /> },
            { path: 'clients', element: <Clients /> },
            { path: 'clients/new', element: <NewClients /> },
            { path: 'clients/:protocol/edit', element: <EditClients /> },
          ],
        },

        {
          path: 'reports',
          children: [
            { element: <Reports />, index: true },
            {
              path: 'financial',
              children: [
                {
                  element: <Navigate to="/main/reports/financial/transfer/general" replace />,
                  index: true,
                },
                {
                  path: 'transfer',
                  children: [
                    {
                      element: <Navigate to="/main/reports/financial/transfer/general" replace />,
                      index: true,
                    },
                    {
                      path: 'general',
                      element: <TransferReportGeneral />,
                    },
                    {
                      path: 'approved',
                      element: <TransferReportApproved />,
                    },
                    {
                      path: 'reproved',
                      element: <TransferReportReproved />,
                    },
                    {
                      path: 'awaiting-audition',
                      element: <TransferReportAwaitingAudition />,
                    },
                  ],
                },
                {
                  path: 'commitments',
                  children: [
                    {
                      element: (
                        <Navigate to="/main/reports/financial/commitments/general" replace />
                      ),
                      index: true,
                    },
                    {
                      path: 'general',
                      element: <CommitmentsReportGeneral />,
                    },
                    {
                      path: 'awaiting-audition',
                      element: <CommitmentsReportAwaitingAudition />,
                    },
                    {
                      path: 'approved',
                      element: <CommitmentsReportApproved />,
                    },
                    {
                      path: 'reproved',
                      element: <CommitmentsReportReproved />,
                    },
                  ],
                },
                {
                  path: 'financial-entries',
                  children: [
                    {
                      element: (
                        <Navigate to="/main/reports/financial/financial-entries/general" replace />
                      ),
                      index: true,
                    },
                    {
                      path: 'general',
                      element: <FinancialEntryReportGeneral />,
                    },
                    {
                      path: 'awaiting-audition',
                      element: <FinancialEntryReportAwaitingAudition />,
                    },
                    {
                      path: 'approved',
                      element: <FinancialEntryReportApproved />,
                    },
                    {
                      path: 'reproved',
                      element: <FinancialEntryReportReproved />,
                    },
                    {
                      path: 'formal-error',
                      element: <FinancialEntryReportFormalError />,
                    },
                    {
                      path: 'damage-to-the-public-treasury',
                      element: <FinancialEntryReportDamageToThePublicTreasury />,
                    },
                    {
                      path: 'other-non-conformities',
                      element: <FinancialEntryReportOtherNonConformities />,
                    },
                  ],
                },
                {
                  path: 'penalties',
                  element: <PenaltiesReport />,
                },
                {
                  path: 'future-entries',
                  element: <FutureEntriesReport />,
                },
                {
                  path: 'entries-by-contract',
                  element: <EntriesByContractReport />,
                },
              ],
            },
            {
              path: 'accounting',
              children: [
                {
                  element: <Navigate to="/main/reports/accounting/is" replace />,
                  index: true,
                },
                {
                  path: 'is',
                  element: <ISReport />,
                },
                {
                  path: 'balance-sheet',
                  children: [
                    {
                      element: (
                        <Navigate to="/main/reports/accounting/balance-sheet/analytic" replace />
                      ),
                      index: true,
                    },
                    {
                      path: 'analytic',
                      element: <AnalyticalBalanceSheetReport />,
                    },
                    {
                      path: 'analytic-accumulated',
                      element: <AccumulatedAnalyticalBalanceSheetReport />,
                    },
                    {
                      path: 'synthetic',
                      element: <SyntheticBalanceSheetReport />,
                    },
                  ],
                },
                {
                  path: 'balance-sheet-trial',
                  element: <BalanceSheetReport />,
                },
                {
                  path: 'diary',
                  element: <DiaryReport />,
                },
                {
                  path: 'ledger',
                  element: <LedgerReport />,
                },
              ],
            },
          ],
        },
        {
          path: 'settings',
          element: <GeneralSettingsPage />,
        },

        // demo
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        { path: 'banking', element: <GeneralBankingPage /> },
        { path: 'booking', element: <GeneralBookingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/main/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShopPage /> },
            { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
            { path: 'list', element: <EcommerceProductListPage /> },
            { path: 'product/new', element: <EcommerceProductCreatePage /> },
            { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/main/user/account" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':protocol/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/main/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/main/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/main/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
        { path: 'faqs', element: <FaqsPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
