import { Stack, Typography } from '@mui/material';
import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();

  return (
    <LoginLayout>
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ mb: 5, position: 'relative' }} variant="h4">
          {translate('loginSystem')}
        </Typography>

        <LanguagePopover />
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
