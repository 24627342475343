import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_DARK = theme.palette.primary.darker;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.534"
            x2="1"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_DARK} />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="0.534"
            x2="1"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_DARK} />
          </linearGradient>
          <linearGradient id="linear-gradient-3" x1="0.534" x2="1" y2="1">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_DARK} />
          </linearGradient>
          <linearGradient id="linear-gradient-4" x1="0.534" x2="1" y2="1">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_DARK} />
          </linearGradient>
          <clipPath id="clip-iPhone_13_12_Pro_1">
            <rect width="512" height="512" />
          </clipPath>
        </defs>
        <path
          id="União_2"
          data-name="União 2"
          d="M27,512A27,27,0,0,1,0,485V407a23,23,0,0,1,23-23H256V23A23,23,0,0,1,279,0h78a27,27,0,0,1,27,27V424a88,88,0,0,1-88,88Z"
          transform="translate(128)"
          fill="url(#linear-gradient)"
        />
        <path
          id="União_3"
          data-name="União 3"
          d="M0,384V280a24,24,0,0,1,24-24H221.033V24a24,24,0,0,1,24-24H349V296a88,88,0,0,1-88,88Z"
          transform="translate(35 128)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="União_1"
          data-name="União 1"
          d="M133,256H0V128H93.053V25a25,25,0,0,1,25-25H221V169a87,87,0,0,1-87,87Z"
          transform="translate(35 256)"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Retângulo_17"
          data-name="Retângulo 17"
          d="M25,0H128a0,0,0,0,1,0,0V63a65,65,0,0,1-65,65H23A23,23,0,0,1,0,105V25A25,25,0,0,1,25,0Z"
          transform="translate(0 384)"
          fill="url(#linear-gradient-4)"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
