const pt = {
  demo: {
    title: `Português`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Olá`,
    description: `Precisa de ajuda? \n Olhe nossa documentação.`,
    documentation: `documentação`,
  },
  app: `app`,
  user: `Usuário`,
  list: `Lista`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  start: `inicio`,
  end: `fim`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  product: `product`,
  deleteAll: `Del. Todos`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  file_type_not_supported: 'Tipo de arquivo não suportado',
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Descrição`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  loginSystem: `Login no Sistema`,
  emailAddress: `Endereço de Email`,
  emailSent: `Email enviado`,
  password: `Senha`,
  forgotPassword: `Esqueceu a senha?`,
  welcomeBack: `Olá, Bem-vindo de volta`,
  login: `Entrar`,
  settings: `Configurações`,
  mode: `Modo`,
  contrast: `Contraste`,
  direction: `Direção`,
  layout: `Layout`,
  stretch: `Esticar`,
  largeResolutionNotice: `Disponível apenas em resoluções grandes > 1600px (xl)`,
  presets: `Predefinições`,
  fullscreen: `Tela cheia`,
  exitFullscreen: `Sair da tela cheia`,
  accountant: `Contador`,
  auditor: `Auditor`,
  system: `Sistema`,
  logout: `Sair`,
  accountability: `Prestação de Contas`,
  selectAccountability: `Selecionar Prestação de Contas`,
  cancel: `Cancelar`,
  general: `Geral`,
  declarations: `Declarações`,
  financial: `Financeiro`,
  commitments: `Empenhos`,
  commitment: `Empenho`,
  banks: `Bancos`,
  financialAccounts: `Contas Financeiras`,
  bankStatements: `Extratos Bancários`,
  bankStatement: `Extrato Bancário`,
  areYouSureYouWantToImport: 'Certeza que deseja importar?',
  financialEntriesAudition: `Auditoria de Lançamentos Financeiros`,
  financialEntries: `Lançamentos Financeiros`,
  accounting: `Contabilidade`,
  register: `Registro`,
  ledgerAccount: `Conta Contábil`,
  costCenter: `Centro de Custo`,
  history: `Histórico`,
  department: `Departamento`,
  structures: `Estruturas`,
  al: `DLPA`,
  se: `DOAR`,
  is: `DRE`,
  ais: `DRA`,
  scse: `DMPL`,
  scf: `DSP`,
  ebitda: `EBITDA`,
  movements: `Movimentos`,
  accountingEntries: `Lançamentos Contábeis`,
  entriesImport: `Importar Lançamentos`,
  humanResources: `Recursos Humanos`,
  payroll: `Folha de Pagamento`,
  collaborators: `Colaboradores`,
  positions: `Cargos`,
  references: `Referências`,
  incomeTax: `Imposto de Renda`,
  inss: `INSS`,
  payrollPreferences: `Preferências de Folha de Pagamento`,
  logistics: `Logística`,
  balance: `Balanço`,
  orderBlocks: `Blocos de Pedidos`,
  routes: `Rotas`,
  vehicles: `Veículos`,
  sellerDriver: `Vendedor/Motorista`,
  projects: `Projetos`,
  services: `Serviços`,
  serviceOrders: `Ordens de Serviço`,
  clients: `Clientes`,
  lawsuits: `Processos Judiciais`,
  configurations: `Configurações`,
  userList: `Lista de Usuários`,
  userAccount: `Conta de Usuário`,
  dashboard: `Painel de Controle`,
  newAccountability: `Nova Prestação de Contas`,
  monthlyTransferTotal: `Total Mensal de Repasses`,
  expensesTotal: `Total de Empenhos`,
  entriesTotal: `Total de Lançamentos`,
  total: `Total`,
  awaitingAudition: `Aguardando Auditoria`,
  awaiting_audition: `Aguardando Auditoria`,
  approved: `Aprovado`,
  approve: `Aprovar`,
  corrected: `Corrigido`,
  correct: `Corrigir`,
  reproved: `Reprovado`,
  reprove: `Reprovar`,
  transfersCommitments: `Repasses/Empenhos`,
  transfers: `Repasses`,
  number: `Nr.°`,
  documentDate: `Data do Documento`,
  documentData: `Dados do Documento`,
  type: `Tipo`,
  operation: `Operação`,
  value: `Valor`,
  status: `Status`,
  viewAll: `Ver Tudo`,
  declarationsList: `Lista de Declarações`,
  newDeclaration: `Nova Declaração`,
  search: `Pesquisar...`,
  code: `Código`,
  date: `Data`,
  responsibleForRegister: `Responsável pelo Registro`,
  noData: `Nenhum Dado`,
  dense: `Compacto`,
  transferList: `Lista de Repasses`,
  newTransfer: `Novo Repasse`,
  cost: `Custo`,
  classification: `Classificação`,
  bankFee: `Taxa Bancária`,
  commitmentsList: `Lista de Empenhos`,
  newCommitment: `Novo Empenho`,
  commitmentNumber: `Nr. do Empenho`,
  phase: `Fase`,
  transferCode: `Código de Repasse`,
  bankList: `Lista de Bancos`,
  newBank: `Novo Banco`,
  name: `Nome`,
  financialAccountsList: `Lista de Contas Financeiras`,
  newFinancialAccount: `Nova Conta Financeira`,
  account: `Conta`,
  agency: `Agência`,
  applicationAccount: `Conta Aplicação`,
  bank: `Banco`,
  bankStatementsList: `Lista de Extratos Bancários`,
  newBankStatement: `Novo Extrato Bancário`,
  financialAccount: `Conta Financeira`,
  statementNumber: `Número do Extrato`,
  financialEntriesAuditionList: `Lista de Auditoria de Lançamentos Financeiros`,
  auditedDocument: `Documento Auditado`,
  auditionDate: `Data da Auditoria`,
  reason: `Motivo`,
  financialEntriesList: `Lista de Lançamentos Financeiros`,
  newFinancialEntry: `Novo Lançamento Financeiro`,
  editFinancialEntry: `Editar Lançamento Financeiro`,
  accountFinancialEntry: `Contabilizar Lançamento Financeiro`,
  awaiting: `Aguardando`,
  cancelled: `Cancelado`,
  amountPaid: `Valor Pago`,
  all: `Todos`,
  contract: `Contrato`,
  contracts: `Contratos`,
  paymentDate: `Data de Pagamento`,
  ledgerAccountsList: `Lista de Contas Contábil`,
  importLedgerAccounts: `Importar Contas Contábil`,
  editLedgerAccountsMask: `Editar Máscara`,
  newLedgerAccount: `Nova Conta Contábil`,
  mask_is_required: 'Máscara é obrigatória',
  mask_must_contain_only_nines_and_dots: 'Máscara deve ser composta pelo numeral "9" e "." (ponto)',
  registryDate: `Data do Registro`,
  situation: `Situação`,
  inactiveFrom: `Inativo a Partir de`,
  costCenterList: `Lista de Centros de Custo`,
  newCostCenter: `Novo Centro de Custo`,
  historyList: `Lista de Histórico`,
  newHistory: `Novo Histórico`,
  departmentList: `Lista de Departamentos`,
  newDepartment: `Novo Departamento`,
  alStructureList: `Lista de Estruturas DLPA`,
  newALStructure: `Nova Estrutura DLPA`,
  inform: `Informar`,
  seStructureList: `Lista de Estruturas DOAR`,
  newSEStructure: `Nova Estrutura DOAR`,
  level: `Nível`,
  option: `Opção`,
  searchValue: `Valor de Pesquisa`,
  isStructureList: `Lista de Estruturas DRE`,
  newISStructure: `Nova Estrutura DRE`,
  configuration: `Configuração`,
  sumGroupsBelow: `Somar Grupos Abaixo`,
  printGroup: `Imprimir Grupo`,
  aisStructureList: `Lista de Estruturas DRA`,
  newAISStructure: `Nova Estrutura DRA`,
  sum: `Somar`,
  groups: `Grupos`,
  scseStructureList: `Lista de Estruturas DMPL`,
  newSCSEStructure: `Nova Estrutura DMPL`,
  hasSubgroup: `Tem Subgrupo`,
  scfStructureList: `Lista de Estruturas DSP`,
  newSCFStructure: `Nova Estrutura DSP`,
  ebitdaStructureList: `Lista de Estruturas EBITDA`,
  newEBITDAStructure: `Nova Estrutura EBITDA`,
  accountingEntriesList: `Lista de Lançamentos Contábeis`,
  newAccountingEntry: `Novo Lançamento Contábil`,
  origin: `Origem`,
  locator: `Localizador`,
  debitLedgerAccount: `Conta Contábil do Débito`,
  creditLedgerAccount: `Conta Contábil do Credito`,
  entriesImportList: `Lista de Importação de Lançamentos`,
  upload: `Carregar`,
  accounted: `Contabilizado`,
  documentIdentifier: `Identificador do Documento`,
  hasOffsetting: `Tem Compensação`,
  offsetting: 'compensação',
  payrollsList: `Lista de Folhas de Pagamento`,
  newPayroll: `Nova Folha de Pagamento`,
  collaborator: `Colaborador`,
  incomeBase: `Base de Rendimento`,
  collaboratorsList: `Lista de Colaboradores`,
  newCollaborator: `Novo Colaborador`,
  fullName: `Nome Completo`,
  registryNumber: `CNPJ`,
  phoneNumber: `Número de Telefone`,
  position: `Cargo`,
  admissionDate: `Data de Admissão`,
  sector: `Setor`,
  dependents: `Dependentes`,
  monthlyHours: `Horas Mensais`,
  positionsList: `Lista de Cargos`,
  newPosition: `Novo Cargo`,
  referencesList: `Lista de Referências`,
  newReference: `Nova Referência`,
  rate: `Taxa`,
  referenceType: `Tipo de Referência`,
  subtract: `Subtrair`,
  hourRate: `Taxa por Hora`,
  incomeTaxList: `Lista de Imposto de Renda`,
  from: `De:`,
  to: `Para:`,
  deduction: `Dedução`,
  newIncomeTax: `Novo Imposto de Renda`,
  inssList: `Lista de INSS`,
  newINSS: `Novo INSS`,
  newPayrollPreferences: `Preferências de Folha de Pagamento`,
  editPayroll: `Editar Folha de Pagamento`,
  incomeTaxPerDependent: `Imposto de Renda por Dependente`,
  inssCap: `Teto INSS`,
  maxINSSDiscount: `Desconto Máximo do INSS`,
  fgtsRate: `Taxa do FGTS`,
  editPosition: `Editar Cargo`,
  editReference: 'Editar Referências',
  editIncomeTax: 'Editar Imposto de Renda',
  save: `Salvar`,
  balanceList: `Lista de Balanço`,
  newBalance: `Novo Balanço`,
  seller: `Vendedor`,
  return: `Retorno`,
  orderBlocksList: `Lista de Bloco de Pedidos`,
  newOrderBlock: `Novo Bloco de Pedido`,
  routesList: `Lista de Rotas`,
  newRoute: `Nova Rota`,
  cities: `Cidades`,
  vehiclesList: `Lista de Veículos`,
  newVehicle: `Novo Veículo`,
  driver: `Motorista`,
  licensePlate: `Placa do Veículo`,
  reindeer: `Rena`,
  chassi: `Chassi`,
  sellerDriverList: `Lista de Vendedores/Motoristas`,
  newSellerDriver: `Novo Vendedor/Motorista`,
  licenseNumber: `Número da Licença`,
  projectsList: `Lista de Projetos`,
  newProject: `Novo Projeto`,
  servicesList: `Lista de Serviços`,
  newService: `Novo Serviço`,
  price: `Preço`,
  project: `Projeto`,
  serviceOrdersList: `Lista de Ordens de Serviço`,
  newServiceOrder: `Nova Ordem de Serviço`,
  protocolNr: `Nr. de Protocolo`,
  protocol: `Protocolo`,
  client: `Cliente`,
  service: `Serviço`,
  openingDate: `Data de Abertura`,
  serviceDate: `Data do Serviço`,
  clientsList: `Lista de Clientes`,
  ssn: `CPF`,
  email: `Email`,
  lawsuitsList: `Lista de Processos Judiciais`,
  processNr: `Nr. do Processo`,
  defendant: `Réu`,
  applicant: `Requerente`,
  causeCost: `Custo da Causa`,
  riskRating: `Classificação de Risco`,
  newLawsuit: `Novo Processo Judicial`,
  newUser: `Novo Usuário`,
  active: `Ativo`,
  company: `Empresa`,
  role: `Cargo`,
  professionalDesignation: `Designação Profissional`,
  notifications: `Notificações`,
  youHaveUnreadMessages: 'Você tem {totalUnRead} notificações não lidas',
  changePassword: `Alterar Senha`,
  allowed: `Permitido`,
  maxSizeOf: `Tamanho Máximo de`,
  address: `Endereço`,
  country: `País`,
  stateRegion: `Estado/Região`,
  city: `Cidade`,
  zipCode: `CEP`,
  activity: `Atividade`,
  systemGeneral: `Sistema (Geral)`,
  audition: `Auditoria`,
  morningActivityReport: `Relatório de Atividades Matutinas`,
  afternoonActivityReport: `Relatório de Atividades Vespertinas`,
  alertMeWhenNewUpdate: `Alertar-me quando uma nova atualização for lançada`,
  inAppNotification: `Notificação no Aplicativo`,
  emailNotification: `Notificação por E-mail`,
  whatsappNotification: `Notificação por WhatsApp`,
  inAppAuditionNotification: `Notificação de Auditoria no Aplicativo`,
  emailAuditionNotification: `Notificação de Auditoria por E-mail`,
  whatsappAuditionNotification: `Notificação de Auditoria por WhatsApp`,
  oldPassword: `Senha Antiga`,
  newPassword: `Nova Senha`,
  passwordMinimum6: `A senha deve ter no mínimo 6 caracteres`,
  confirmNewPassword: `Confirmar Nova Senha`,
  saveChanges: `Salvar Alterações`,
  cycleManagementMaintenance: `A Cycle Gestões está atualmente em manutenção`,
  workingHardToBringBack: `Estamos trabalhando arduamente para trazê-lo de volta!`,
  goToHome: `Ir para a Página Inicial`,
  needHelp: `Precisa de Ajuda?`,
  maintenance: `Manutenção`,
  initialDate: `Data Inicial`,
  endDate: `Data Final`,
  payrollDiscount: `Desconto na Folha de Pagamento`,
  contractDiscount: `Desconto Contratual`,
  otherDiscount: `Outro Desconto`,
  rectifier: `Retificador`,
  pisExemption: `Isenção de PIS`,
  managementContract: `Contrato de Gestão`,
  firstResponsible: `1º Responsável`,
  secondResponsible: `2º Responsável`,
  createAccountability: `Criar Prestação de Contas`,
  dropOrSelectFile: `Arraste ou selecione o arquivo`,
  dropFilesHere: `Arraste os arquivos aqui ou clique para %1 pelo seu dispositivo`,
  browse: `navegar`,
  createLedgerAccount: `Criar Conta Contábil`,
  transferClassification: `Classificação do Repasse`,
  none: `Nenhum`,
  fixedVariable: `Fixo + Variável`,
  dentistry: `Odontologia`,
  investment: `Investimento`,
  educationResearch: `Educação e Pesquisa`,
  specialPrograms: `Programas Especiais`,
  authorizedInvestmentPlan: `Plano de Investimento Autorizado`,
  totalFare: `Valor Total da Tarifa`,
  transferPrice: `Preço do Repasse`,
  commitmentCode: `Código do Empenho`,
  commitmentPhase: `Fase do Empenho`,
  editCommitment: `Editar Empenho`,
  budget: `Orçamento`,
  reserved: `Reservado`,
  liquidated: `Liquidado`,
  paid: `Pago`,
  transferOperation: `Operação do Repasse`,
  commitmentPrice: `Preço do Empenho`,
  createTransfer: `Criar Repasse`,
  createCommitment: `Criar Empenho`,
  addDeclaration: `Adicionar Declaração`,
  addBank: `Adicionar Banco`,
  accountingAccount: `Conta Contábil`,
  openingBalance: `Saldo Inicial`,
  addFinancialAccount: `Adicionar Conta Financeira`,
  addBankStatement: `Adicionar Extrato Bancário`,
  taxes: `Impostos`,
  files: `Arquivos`,
  docDate: `Data do Doc.`,
  documentPrice: `Valor do Documento`,
  documentType: `Tipo do Documento`,
  operationType: `Tipo de Operação`,
  supplierContract: `Contrato de Fornecedor`,
  futureEntry: `Lançamento Futuro`,
  conciliated: `Conciliado`,
  complement: `Complemento`,
  next: `Próximo`,
  check: `Cheque`,
  deposit: `Depósito`,
  eft: `Transferência Eletrônica de Fundos`,
  bankWireTransfer: `Transferência Bancária`,
  other: `Outros`,
  cash: `Dinheiro`,
  paymentSlip: `Boleto`,
  electronicTaxVoucher: `Documento de Arrecadação Municipal Eletrônico (Brasil)`,
  unifiedCustomsDocument: `Documento Único Aduaneiro`,
  unifiedEnvironmentDocument: `Documento Único de Meio Ambiente`,
  stateRevenueDocument: `Documento de Receita Estadual`,
  federalRevenueDocument: `Documento de Receita Federal`,
  bankFees: `Tarifas Bancárias`,
  propertyTax: `Imposto Predial`,
  vehicleTax: `Imposto sobre Veículos`,
  invoiceBill: `Fatura ou Conta`,
  socialSecurityPayment: `Documento de Pagamento da Previdência Social`,
  severancePayFund: `Fundo de Garantia por Tempo de Serviço`,
  instantPaymentSystem: `Sistema de Pagamento Instantâneo`,
  purchaseOrders: `Pedidos de Compra`,
  costAllocationReimbursement: `Reembolso de Alocação de Custos`,
  laborTerminationCharges: `Encargos de Rescisão de Contrato de Trabalho`,
  residencyStipend: `Bolsa de Residência`,
  loanContractingC: `Contratação de Empréstimo/Financiamento (C)`,
  loanContractingD: `Contratação de Empréstimo/Financiamento (D)`,
  convenantRevenues: `Receitas de Convênios`,
  extracontractualResources: `Recursos Extrateto`,
  expenseReimbursement: `Reembolso de Despesas`,
  cashBalanceRefundC: `Reembolso de Saldo em Dinheiro (C)`,
  cashBalanceRefundD: `Reembolso de Saldo em Dinheiro (D)`,
  payrollCharges: `Encargos de Folha de Pagamento`,
  bankAccountUnlocking: `Desbloqueio de Conta Bancária`,
  bankAccountBlocking: `Bloqueio de Conta Bancária`,
  fundsRefund: `Reembolso de Fundos`,
  rent: `Aluguel`,
  advances: `Adiantamentos`,
  alimony: `Pensão Alimentícia`,
  dailyAllowances: `Diárias`,
  travelExpenses: `Despesas de Viagem`,
  reversal: `Estorno`,
  laborTermination: `Rescisão de Contrato de Trabalho`,
  suppliersCollateralC: `Garantia do Fornecedor (C)`,
  suppliersCollateralD: `Garantia do Fornecedor (D)`,
  courtDepositC: `Depósito Judicial (C)`,
  courtDepositD: `Depósito Judicial (D)`,
  capitalInjectionC: `Injeção de Capital (C)`,
  capitalInjectionD: `Injeção de Capital (D)`,
  individualServicesProvision: `Prestação Individual de Serviços`,
  nonGovernmentalRevenues: `Receitas Não Governamentais`,
  tevsIncome: `Receitas de TEVs`,
  tevsTransfers: `Transferências de TEVs`,
  applicationRedemption: `Resgate de Aplicação`,
  taxesWithheldAtSource: `Impostos Retidos na Fonte`,
  financialInvestments: `Investimentos Financeiros`,
  transfersToInvestmentAccount: `Transferências para Conta de Investimento`,
  otherOutflows: `Outros Fluxos de Saída`,
  taxesFeesContributionsPayment: `Pagamento de Impostos, Taxas e Contribuições`,
  suppliersPayment: `Pagamento a Fornecedores`,
  utilityBillsPayment: `Pagamento de Contas de Utilidades (Água, Energia Elétrica, Telefone)`,
  payrollPayment: `Pagamento de Folha de Pagamento`,
  expenseRecovery: `Recuperação de Despesas`,
  investmentEarnings: `Rendimentos de Investimento`,
  otherInflows: `Outros Fluxos de Entrada`,
  back: `Voltar`,
  discount: `Desconto`,
  penalties: `Multas/Juros`,
  penaltiesReason: `Motivo das Multas/Juros`,
  entryByContract: 'Lançamento por Contrato',
  balanceSheet: 'Balancete',
  icms: `ICMS`,
  iss: `ISS`,
  ir: `IR`,
  pis: `PIS`,
  cofins: `COFINS`,
  csll: `CSLL`,
  ipi: `IPI`,
  frete: `FRETE`,
  csrf: `CSRF`,
  skip: `Pular`,
  redo: `Desfazer`,
  invoiceDue: `Vencimento da Nota Físcal`,
  formalError: `Erro Formal`,
  damageToThePublicTreasury: `Dano ao Erário`,
  otherNonConformities: `Outras não conformidades`,
  danfe: `DANFE`,
  invoiceSerie: `Série da Nota Físcal`,
  invoiceNumber: `Número da Nota Físcal`,
  invoiceKey: `Chave da Nota Físcal`,
  uploadInvoice: `Enviar Nota Físcal`,
  certificateNr: `Número do Certificado`,
  certificateCode: `Código do Certificado`,
  uploadCertificate: `Enviar Certificado`,
  uploadXML: `Enviar XML`,
  addFinancialEntry: `Adicionar Lançamento Financeiro`,
  downloadTemplate: `Baixar Modelo`,
  download: `Baixar`,
  analytic: `Analítico`,
  synthetic: `Sintético`,
  inactive: `Inativo`,
  alGroup: `Grupo de AL`,
  seGroup: `Grupo de SE`,
  isGroup: `Grupo de IS`,
  aisGroup: `Grupo de AIS`,
  scseGroup: `Grupo de SCSE`,
  ebitdaGroup: `Grupo de EBITDA`,
  scfGroup: `Grupo de SCF`,
  scfAct: `Ato de SCF`,
  addLedgerAccount: `Adicionar Conta de Razão`,
  addCostCenter: `Adicionar Centro de Custo`,
  addHistory: `Adicionar Histórico`,
  variables: `Variáveis`,
  addDepartment: `Adicionar Departamento`,
  profitLoss: `Lucro/Prejuízo`,
  appropriations: `Apropriações`,
  totalDebit: `Débito Total`,
  totalCredit: `Crédito Total`,
  accountingPeriod: `Período Contábil`,
  priorYear: `Ano Anterior`,
  fiscalYear: `Ano Fiscal`,
  addALStructure: `Adicionar Estrutura de AL`,
  sourceOfFunds: `Fonte de Recursos`,
  usesOfFunds: `Utilização de Recursos`,
  currentAssets: `Ativos Circulantes`,
  currentLiabilities: `Passivos Circulantes`,
  fromOperations: `Das Operações`,
  thirdParties: `Terceiros`,
  shareholders: `Acionistas`,
  isBalance: `Balanço de IS`,
  creditAmount: `Valor do Crédito`,
  debitAmount: `Valor do Débito`,
  addSEStructure: `Adicionar Estrutura de SE`,
  accounts: `Contas`,
  incomeBeforeTaxes: `Lucro Antes dos Impostos`,
  beginsSubtotal: `Início do Subtotal`,
  closesSubtotal: `Fim do Subtotal`,
  accountBase: `Base da Conta`,
  addISStructure: `Adicionar Estrutura de IS`,
  totalBalance: `Saldo Total`,
  addAISStructure: `Adicionar Estrutura de AIS`,
  group: `Grupo`,
  subGroup: `Subgrupo`,
  addSCSEStructure: `Adicionar Estrutura de SCSE`,
  addSCFStructure: `Adicionar Estrutura de SCF`,
  accountsInvertNatureOfBalance: `Contas - Inverter Natureza do Saldo`,
  addEBITDAStructure: `Adicionar Estrutura de EBITDA`,
  debitAccount: `Conta de Débito`,
  debitCostCenter: `Centro de Custo de Débito`,
  debitValue: `Valor de Débito`,
  creditAccount: `Conta de Crédito`,
  creditCostCenter: `Centro de Custo de Crédito`,
  creditValue: `Valor de Crédito`,
  difference: `Diferença`,
  addAccountingEntry: `Adicionar Lançamento Contábil`,
  uploadBankStatement: `Enviar Extrato Bancário`,
  employee: `Funcionário`,
  addReferences: `Adicionar Referências`,
  addPayroll: `Adicionar Folha de Pagamento`,
  reference: `Referência`,
  amount: `Valor`,
  registrationCode: `Código de Registro`,
  addCollaborator: `Adicionar Colaborador`,
  addPosition: `Adicionar Cargo`,
  empty: `Vazio`,
  percentage: `Porcentagem`,
  accountTypes: 'Natureza das Conta',
  addAccountType: 'Adicionar Natureza',
  editAccountType: 'Editar Natureza',
  accountType: 'Natureza',
  accountGroup: 'Grupo',
  debitGroup: 'Devedora',
  creditGroup: 'Credora',
  assets: 'Ativo',
  liabilities: 'Passivo',
  equity: 'Patrimônio Líquido',
  income_statement_accounts: 'Contas de Resultado',
  contra_accounts: 'Contas de Compensação',
  wrdp: `RDPV`,
  addReference: `Adicionar Referência`,
  incomeBaseLimit: `Limite da Base de Rendimento`,
  addIncomeTax: `Adicionar Imposto de Renda`,
  addINSS: `Adicionar INSS`,
  addBalance: `Adicionar Saldo`,
  addOrderBlock: `Adicionar Bloco de Pedido`,
  addRoute: `Adicionar Rota`,
  federalUnit: `Unidade Federal`,
  manufacturingYear: `Ano de Fabricação`,
  modelYear: `Ano do Modelo`,
  addVehicle: `Adicionar Veículo`,
  addSellerDriver: `Adicionar Vendedor/Condutor`,
  createProject: `Criar Projeto`,
  addService: `Adicionar Serviço`,
  responsible: `Responsável`,
  open: `Aberto`,
  inProgress: `Em Progresso`,
  completed: `Concluído`,
  warranty: `Garantia`,
  defect: `Defeito`,
  comments: `Comentários`,
  technicalReport: `Relatório Técnico`,
  finalPrice: `Preço Final`,
  billed: `Faturado`,
  addServiceOrder: `Adicionar Ordem de Serviço`,
  phone: `Telefone`,
  addClient: `Adicionar Cliente`,
  judicialCourt: `Tribunal Judicial`,
  object: `Objeto`,
  riskClassification: `Classificação de Risco`,
  canceled: `Cancelado`,
  causePrice: `Valor da Causa`,
  addLawsuit: `Adicionar Processo Judicial`,
  createUser: `Criar Usuário`,
  permissions: `Permissões`,
  read: `Ler`,
  add: `Adicionar`,
  edit: `Editar`,
  delete: `Deletar`,
  entry: `Lançamento`,
  entries: `Lançamentos`,
  additives: `Aditivos`,
  occurrences: `Ocorrências`,
  resources: `Recursos`,
  generalContracts: `Contratos Gerais`,
  people: `Pessoas`,
  biddings: `Licitações`,
  biddingList: `Lista de Licitações`,
  events: `Eventos`,
  lots: `Lotes`,
  results: `Resultados`,
  contractors: `Contratantes`,
  companies: `Empresas`,
  isThisUserAuditor: `Este Usuário é uim auditor?`,
  applyDisableAccount: `Aplicar 'inativar conta'`,
  reports: `Relatórios`,
  report: `Relatório`,
  new: 'Novo',
  newClient: `Novo Cliente`,
  true: 'Sim',
  false: 'Não',
  addCity: 'Adicionar Cidade',
  yes: `Sim`,
  no: `Não`,
  eni: 'CNPJ',
  contractList: 'Lista De Contratos',
  newContract: 'Contrato Novo',
  editContract: 'Editar Contrato',
  contractNumber: 'Nr. Contrato',
  inicialDate: 'Data de Inicio',
  terminationDate: 'Data de Termino',
  managementContractList: 'Lista de Contratos de Gestão',
  managementContracts: 'Contratos de Gestão',
  newManagementContract: 'Novo Contrato de Gestão',
  supplierContractList: 'Lista de Contratos de Fornecedores',
  supplierContracts: 'Contratos de Fornecedores',
  newSupplierContract: 'Novo Contrato de Fornecedor',
  installmentCount: 'Qtd. Parcelas',
  installmentSerieNumber: 'Nr. Serie Parcela',
  contractPrice: 'Preço do Contrato',
  installmentPrice: 'Preço da Parcela',
  supplierTypes: 'Tipo de Fornecedores',
  supplierClassifications: 'Classificação de Fornecedor',
  additiveList: 'Lista de Aditivos',
  newAdditive: 'Novo Aditivo',
  additive: 'Aditivo',
  occurences: 'Ocorrências',
  occurence: 'Ocorrência',
  occurencesList: 'Lista de Ocorrências',
  newOccurrences: 'Nova Ocorrencia',
  resourcesList: 'Lista de Recursos',
  newResources: 'Novo Recurso',
  peopleList: 'Lista de Pessoas',
  newPeople: 'Nova Pessoa',
  contactNumber: 'Numero de Contato',
  bidding: 'Licitação',
  companiesList: 'Lista de Empresas',
  newCompany: 'Nova Empresa',
  businessName: 'Nome Fantasia',
  addressComplement: 'Complemento de Endereço',
  district: 'Distrito',
  state: 'Estado',
  website: 'Website',
  contact: 'Contato',
  businessType: 'Tipo de Empresa',
  contractorsList: 'Lista de Contratantes',
  newContractor: 'Novo Contratante',
  category: 'Categoria',
  resultsList: 'Lista de Resultados',
  newResult: 'Novo Resultado',
  winner: 'Ganhador',
  qualification: 'Qualificação',
  technicalNote: 'Nota Técnica',
  priceNote: 'Nota de Preço',
  finalNote: 'Nota Final',
  lotsList: 'Lista de Lotes',
  newLots: 'Novos Lotes',
  newLot: 'Novo Lote',
  biddingNr: 'Nr de Licitação',
  term: 'Prazo',
  eventsList: 'Lista de Eventos',
  newEvents: 'Novo Evento',
  eventDate: 'Data do Evento',
  eventType: 'Tipo do Evento',
  biddingsList: 'Lista de Licitações',
  newBiddings: 'Nova Licitação',
  noticeCode: 'Código de Aviso',
  openning: 'Abertura',
  modality: 'Modalidade',
  editLedgerAccount: 'Editar Conta Contábil',
  selectedDocument: 'Documento Selecionado',
  editDeclaration: 'Editar Declaração',
  visualize: 'Visualizar',
  viewDocument: 'Visualizar Documento',
  document: 'Documento',
  menu: 'Menu',
  forgotPasswordText:
    'Por favor, insira o endereço de e-mail associado à sua conta e nós lhe enviaremos um link para redefinir sua senha.',
  returnToSignIn: 'Voltar para login',
  sendRequest: 'Enviar Pedido',
  editTransfer: 'Editar Repasse',
  editBank: 'Editar Banco',
  editFinancialAccount: 'Editar Conta Financeira',
  editBankStatement: 'Editar Extrato Bancário',
  editCostCenter: 'Editar Centro de Custos',
  aLGroup: 'Grupo DLPA',
  sEGroup: 'Grupo DOAR',
  iSGroup: 'Grupo DRE',
  aISGroup: 'Grupo DRA',
  sCSEGroup: 'Grupo DMPL',
  eBITDAGroup: 'Grupo EBITDA ',
  sCFGroup: 'Grupo DSP',
  sCFAct: 'Ato DSP',
  both: 'Ambos',
  member: 'Membro',
  nonMember: 'Não-Membro',
  editHistory: 'Editar Histórico',
  editDepartment: 'Editar Departamento',
  aLStructureList: 'Estrutura DLPA',
  sEStructuresList: 'Estrutura DOAR',
  iSStructureList: 'Estrutura DRE',
  aISStructureList: 'Estrutura DRA',
  sCSEStructureList: 'Estrutura DMPL',
  sCFStructureList: 'Estrutura DSP',
  eBITDAStructureList: 'Estrutura EBITDA',
  newAL: 'Nova Estrutura',
  newSE: 'Nova Estrutura',
  newIS: 'Nova Estrutura',
  newAIS: 'Nova Estrutura',
  newSCSE: 'Nova Estrutura',
  newSCF: 'Nova Estrutura',
  newEBITDA: 'Nova Estrutura',
  editALStructure: 'Editar Estrutura',
  editSEStructure: 'Editar Estrutura',
  editISStructure: 'Editar Estrutura',
  editAISStructure: 'Editar Estrutura',
  editSCSEStructure: 'Editar Estrutura',
  editSCFStructure: 'Editar Estrutura',
  editEBITDAStructure: 'Editar Estrutura',
  aLStructure: 'Estrutura DLPA',
  sEStructure: 'Estrutura DOAR',
  iSStructure: 'Estrutura DRE',
  aISStructure: 'Estrutura DRA',
  sCSEStructure: 'Estrutura DMPL',
  sCFStructure: 'Estrutura DSP',
  eBITDAStructure: 'Estrutura EBITDA',
  statement: 'Demonstrações',
  above: 'Acima',
  below: 'Abaixo',
  accountInvertNatureOfBalance: 'Conta - Natureza do Balanço',
  editINSS: 'Editar INSS',
  viewInvoice: 'Visualizar Nota Fiscal',
  viewCertificate: 'Visualizar Certificado',
  downloadXml: 'Download XML',
  areYouSureYouWantToDelete: 'Certeza que deseja deletar?',
  areYouSureYouWantToApprove: 'Certeza que deseja aprovar?',
  areYouSureYouWantToRedo: 'Certeza que deseja desfazer?',
  mobile: 'Celular',
  fax: 'Fax',
  editPeople: 'Editar Pessoas',
  baseDate: 'Data Base',
  globalValue: 'Valor Global',
  quotaValue: 'Valor Parcela',
  signatureDate: 'Data Assinatura',
  publicationDate: 'Data Publicação',
  processNumber: 'Número Processo',
  processYear: 'Ano Processo',
  process: 'Processo',
  generateReport: 'Gerar Relatório',
  reGenerateReport: 'Re-Gerar Relatório',
  accountabilityReport: 'Relatório por Prestação de Contas',
  publicationVehicle: 'Veiculo Publicação',
  contractDash: 'Contrato Nr. ',
  changeOfObject: 'Alteração de Objeto',
  changeOfTime: 'Alteração de Tempo',
  changeOfValue: 'Alteração de Valor',
  editAdditive: 'Editar Aditivos',
  newOccurence: 'Nova Ocorrência',
  editOccurrence: 'Editar Ocorrência',
  newResource: 'Novo Recurso',
  editResource: 'Editar Recurso',
  validityTerm: 'Prazo Vigencia',
  validity_term_type: 'Tipo de Prazo',
  year: 'Anos',
  month: 'Meses',
  week: 'Semanas',
  day: 'Dias',
  executionSystem: 'Regime Execução',
  paymentConditions: 'Condições Pagamento',
  criteria: 'Criterios',
  readjustment: 'Reajuste',
  restatement: 'Atualização Monetaria',
  warrantyPercentage: 'Percental Garantia',
  raw_materials: 'Matérias-Primas',
  packaging_material: 'Materiais de Embalagem',
  finished_goods: 'Produtos Acabados',
  maintenance_repair_operations: 'Manutenção, Reparo & Operações',
  marketing_advertising: 'Marketing & Publicidade',
  information_technology: 'Tecnologia da Informação',
  consulting_professional_services: 'Consultoria & Serviços Profissionais',
  logistics_transportation: 'Logística & Transporte',
  utilities_energy: 'Utilidades & Energia',
  insurance_risk_management: 'Seguros & Gestão de Riscos',
  human_resources_recruitment: 'Recursos Humanos & Recrutamento',
  office_supplies_equipment: 'Materiais de Escritório & Equipamentos',
  facilities_real_estate: 'Instalações & Imóveis',
  legal_services: 'Serviços Legais',
  financial_banking_services: 'Serviços Financeiros & Bancários',
  serviceProvider: 'Prestador de Serviços',
  materialsSupplier: 'Fornecedor de Materiais',
  medicalSupplier: 'Fornecedor Médico',
  concluded: 'Concluído',
  rescinded: 'Rescindido',
  suspended: 'Suspenso',
  current: 'Atual',
  annual: 'Anual',
  semiAnnual: 'Semestral',
  quarterly: 'Trimestal',
  bimonthly: 'Bimestral',
  monthly: 'Mensal',
  bankGuarantee: 'Garantia Bancária',
  cashDeposit: 'Depósito em Dinheiro',
  suretyBond: 'Fiança',
  editBalance: 'Editar Balanço',
  editOrderBlock: 'Editar Bloco de Pedido',
  editRoute: 'Editar Rota',
  editVehicle: 'Editar Veiculo',
  editSellerDriver: 'Editar Vendedor/Motorista',
  editService: 'Editar Serviço',
  editServiceOrder: 'Editar Ordem de Serviço',
  editClient: 'Editar Cliente',
  documentNr: 'Documento Nr.',
  editLawsuits: 'Editar Processo Judicial',
  competitor: 'Competidores',
  partner: 'Parceiro',
  others: 'Outros',
  editCompany: 'Editar Empresa',
  directAdministrationAgency: 'Agência de Administração Direta',
  specialFund: 'Fundo Especial',
  autarchy: 'Autarquia',
  publicFoundation: 'Fundação Pública',
  publicCompany: 'Empresa Pública',
  mixedEconomyCompany: 'Empresa de Economia Mista',
  federal: 'Federal',
  state_category: 'Estadual',
  federalDistrict: 'Distrital Federal',
  municipal: 'Municipal',
  editContractor: 'Editar Contratante',
  result: 'Resultado',
  appealDisqualification: 'Recurso de Desqualificação',
  publicationInTheOfficialGazette: 'Publicação no Diário Oficial',
  extension: 'Prorrogação',
  noticeInTheOfficialGazette: 'Aviso no Diário Oficial',
  openingOfDocumentation: 'Abertura da Documentação',
  openingOfPriceProposals: 'Abertura das Propostas de Preço',
  openingOfTechnicalProposal: 'Abertura da Proposta Técnica',
  certificatesAttest: 'Certificados de Atestado',
  certificates: 'Certificados',
  proposalSubmission: 'Submissão da Proposta',
  correctionNotice: 'Aviso de Correção',
  clarifications: 'Esclarecimentos',
  awardingOfTheContract: 'Adjudicação do Contrato',
  published: 'Publicado',
  closed: 'Encerrado',
  revoked: 'Revogado',
  validated: 'Validado',
  contractor: 'Contratante',
  biddingType: 'Tipo de Licitação',
  biddingSystem: 'Sistema de Licitação',
  index: 'Indice',
  local: 'Local',
  bestTechnicalProposal: 'Melhor Proposta Técnica',
  lowestBid: 'Menor Lance',
  technicalAndPrice: 'Técnica e Preço',
  lumpSumContract: 'Contrato de Preço Global',
  unitPriceContract: 'Contrato de Preço Unitário',
  competitiveBidding: 'Licitação Competitiva',
  contest: 'Concurso',
  invitationToBid: 'Convite para Licitar',
  auction: 'Leilão',
  electronicAuction: 'Leilão Eletrônico',
  invitationForBids: 'Convite para Propostas',
  editBidding: 'Editar Licitação',
  editEvent: 'Editar Evento',
  editResult: 'Editar Resultado',
  editLot: 'Editar Lote',
  editEntriesImport: 'Editar Lançamento Importado',
  notAllEntryHasAOffsetting: 'Nem todas as entradas selecionadas possuem um compensação!',
  someSelectedRowsWasAlreadyAccounted: 'Algumas das linhas selecionadas já foram contabilizadas!',
  requestSentSuccessfully: 'Pedido Enviado com sucesso!',
  toAccount: 'Contabilizar',
  are_you_sure_want_to_account: 'Deseja contabilizar',
  enterTheCodeInBelowBoxToVerifyYourEmail:
    'Digite o codigo no campo abaixo para verificar seu e-mail.',
  weHaveSentYouA6DigitCodeConfirmationEmai:
    'Enviamos um código de confirmação de 6 Digitos para seu e-mail.',
  dontHaveACode: 'Não recebeu o código?',
  resendCode: 'Re-enviar código',
  updatePassword: 'Atualizar Senha',
  date_is_required: 'A data é obrigatória.',
  field_is_required: 'Campo obrigatório.',
  operation_number_is_required: 'O número da operação é obrigatório.',
  total_fare_is_required: 'A tarifa total é obrigatória.',
  transfer_price_is_required: 'O preço do repasse é obrigatório.',
  transfer_classification_is_required: 'A classificação do repasse é obrigatória.',
  name_is_required: 'O nome é obrigatório.',
  email_is_required: 'O email é obrigatório.',
  email_must_be_a_valid_email_address: 'O email deve ser um endereço de email válido.',
  phone_number_is_required: 'O número de telefone é obrigatório.',
  address_is_required: 'O endereço é obrigatório.',
  country_is_required: 'O país é obrigatório.',
  company_is_required: 'A empresa é obrigatória.',
  state_is_required: 'O estado é obrigatório.',
  city_is_required: 'A cidade é obrigatória.',
  role_is_required: 'O papel/função é obrigatório.',
  avatar_must_be_an_image: 'O avatar deve ser uma imagem.',
  avatar_is_required: 'O avatar é obrigatório.',
  ledger_account_is_required: 'A conta contábil é obrigatória.',
  file_is_required: 'O arquivo é obrigatório.',
  initial_date_is_required: 'A data inicial é obrigatória.',
  end_date_is_required: 'A data final é obrigatória.',
  first_responsible_is_required: 'O primeiro responsável é obrigatório.',
  bank_statement_id_is_required: 'O Extrato Bancário é obrigatório.',
  second_responsible_is_required: 'O segundo responsável é obrigatório.',
  description_is_required: 'A descrição é obrigatória.',
  code_is_required: 'O código é obrigatório.',
  date_id_required: 'O ID da data é obrigatório.',
  department_id_id_required: 'O ID do departamento é obrigatório.',
  debit_ledger_account_id_is_required: 'O ID da conta contábil de débito é obrigatório.',
  debit_value_is_required: 'O valor do débito é obrigatório.',
  credit_ledger_account_id_is_required: 'O ID da conta contábil de crédito é obrigatório.',
  credit_value_is_required: 'O valor do crédito é obrigatório.',
  history_is_required: 'O histórico é obrigatório.',
  bankReconciliation: 'Conciliação Bancária',
  classification_is_required: 'A classificação é obrigatória.',
  type_is_required: 'O tipo é obrigatório.',
  situation_is_required: 'A situação é obrigatória.',
  status_id_required: 'O ID do status é obrigatório.',
  contractor_id_id_required: 'O ID do contratante é obrigatório.',
  modality_id_required: 'O ID da modalidade é obrigatório.',
  bidding_type_id_required: 'O ID do tipo de licitação é obrigatório.',
  bidding_system_id_required: 'O ID do sistema de licitação é obrigatório.',
  opening_date_id_required: 'O ID da data de abertura é obrigatório.',
  notice_code_id_required: 'O ID do código de aviso é obrigatório.',
  bidding_code_id_required: 'O ID do código de licitação é obrigatório.',
  bidding_index_id_required: 'O ID do índice de licitação é obrigatório.',
  bidding_price_id_required: 'O ID do preço de licitação é obrigatório.',
  local_id_required: 'O ID do local é obrigatório.',
  object_id_required: 'O ID do objeto é obrigatório.',
  history_id_required: 'O ID do histórico é obrigatório.',
  business_name_is_required: 'O nome da empresa é obrigatório.',
  registry_number_is_required: 'O número de registro é obrigatório.',
  district_is_required: 'O distrito é obrigatório.',
  zip_code_is_required: 'O CEP é obrigatório.',
  website_is_required: 'O website é obrigatório.',
  business_type_is_required: 'O tipo de negócio é obrigatório.',
  category_is_required: 'A categoria é obrigatória.',
  event_date_is_required: 'A data do evento é obrigatória.',
  bidding_id_is_required: 'O ID da licitação é obrigatório.',
  event_type_is_required: 'O tipo de evento é obrigatório.',
  lot_number_is_required: 'O número do lote é obrigatório.',
  lot_price_is_required: 'O preço do lote é obrigatório.',
  lot_term_is_required: 'O prazo do lote é obrigatório.',
  company_id_is_required: 'O ID da empresa é obrigatório.',
  qualification_is_required: 'A qualificação é obrigatória.',
  winner_is_required: 'O vencedor é obrigatório.',
  technical_note_is_required: 'A nota técnica é obrigatória.',
  price_note_is_required: 'A nota de preço é obrigatória.',
  final_note_is_required: 'A nota final é obrigatória.',
  price_is_required: 'O preço é obrigatório.',
  discount_is_required: 'O desconto é obrigatório.',
  title_is_required: 'O título é obrigatório.',
  signature_date_is_required: 'A data de assinatura é obrigatória.',
  publication_date_is_required: 'A data de publicação é obrigatória.',
  number_is_required: 'O número é obrigatório.',
  process_number_is_required: 'O número do processo é obrigatório.',
  contract_id_is_required: 'O ID do contrato é obrigatório.',
  additivestype_is_required: 'O tipo de aditivo é obrigatório.',
  process_year_is_required: 'O ano do processo é obrigatório.',
  publication_vehicle_is_required: 'O veículo de publicação é obrigatório.',
  global_value_is_required: 'O valor global é obrigatório.',
  quota_value_is_required: 'O valor da cota é obrigatório.',
  term_is_required: 'O prazo é obrigatório.',
  document_is_required: 'O documento é obrigatório.',
  base_date_is_required: 'A data base é obrigatória.',
  inicial_date_is_required: 'A data inicial é obrigatória.',
  validity_term_is_required: 'O prazo de validade é obrigatório.',
  validity_term_type_is_required: 'O tipo de prazo é obrigatório.',
  object_is_required: 'O objeto é obrigatório.',
  execution_bidding_system_is_required: 'O sistema de licitação de execução é obrigatório.',
  payment_conditions_is_required: 'As condições de pagamento são obrigatórias.',
  criteria_is_required: 'Os critérios são obrigatórios.',
  readjustment_is_required: 'O reajuste é obrigatório.',
  restatement_is_required: 'A reavaliação é obrigatória.',
  warranty_is_required: 'A garantia é obrigatória.',
  warranty_percentage_is_required: 'O percentual de garantia é obrigatório.',
  commitment_number_is_required: 'O número de empenho é obrigatório.',
  contract_number_is_required: 'O número do contrato é obrigatório.',
  occurrence_date_is_required: 'A data da ocorrência é obrigatória.',
  occurrence_date: 'Data da ocorrência',
  occurrence_type_is_required: 'O tipo de ocorrência é obrigatório.',
  occurrence_text_is_required: 'O texto da ocorrência é obrigatório.',
  mobile_is_required: 'O número de celular é obrigatório.',
  fax_is_required: 'O número de fax é obrigatório.',
  people_id_is_required: 'O ID de pessoa é obrigatório.',
  installment_count_is_required: 'A contagem de parcelas é obrigatória.',
  installment_serie_number_is_required: 'O número da série da parcela é obrigatório.',
  installment_price_is_required: 'O preço da parcela é obrigatório.',
  supplier_types_is_required: 'Os tipos de fornecedor são obrigatórios.',
  supplier_classification_is_required: 'A classificação do fornecedor é obrigatória.',
  cost_center_id_is_required: 'O ID do centro de custo é obrigatório.',
  financial_account_id_is_required: 'O ID da conta financeira é obrigatório.',
  statement_code_is_required: 'O código do extrato é obrigatório.',
  data_is_required: 'A data é obrigatória.',
  commitment_code_is_required: 'O código do empenho é obrigatório.',
  commitment_phase_is_required: 'A fase do empenho é obrigatória.',
  transfer_id_is_required: 'O ID do repasse é obrigatório.',
  commitment_price_is_required: 'O preço do empenho é obrigatório.',
  account_is_required: 'A conta é obrigatória.',
  account_code_is_required: 'O código da conta é obrigatório.',
  agency_is_required: 'A agência é obrigatória.',
  agency_code_is_required: 'O código da agência é obrigatório.',
  accounting_account_is_required: 'A conta contábil é obrigatória.',
  bank_id_is_required: 'O ID do banco é obrigatório.',
  opening_balance_is_required: 'O saldo de abertura é obrigatório.',
  document_date_is_required: 'A data do documento é obrigatória.',
  payment_date_is_required: 'A data de pagamento é obrigatória.',
  document_price_is_required: 'O preço do documento é obrigatório.',
  amount_paid_is_required: 'O valor pago é obrigatório.',
  document_type_is_required: 'O tipo de documento é obrigatório.',
  operation_type_is_required: 'O tipo de operação é obrigatório.',
  registration_code_is_required: 'O código de registro é obrigatório.',
  full_name_is_required: 'O nome completo é obrigatório.',
  income_base_is_required: 'A base de renda é obrigatória.',
  position_id_is_required: 'O ID da posição é obrigatório.',
  admission_date_is_required: 'A data de admissão é obrigatória.',
  department_id_is_required: 'O ID do departamento é obrigatório.',
  sector_is_required: 'O setor é obrigatório.',
  dependents_is_required: 'Os dependentes são obrigatórios.',
  monthly_hours_is_required: 'As horas mensais são obrigatórias.',
  income_base_limit_is_required: 'O limite da base de renda é obrigatório.',
  deduction_is_required: 'A dedução é obrigatória.',
  rate_is_required: 'A taxa é obrigatória.',
  employee_id_is_required: 'O ID do empregado é obrigatório.',
  income_tax_per_dependent_is_required: 'O imposto de renda por dependente é obrigatório.',
  inss_cap_is_required: 'O teto do INSS é obrigatório.',
  max_inss_discount_is_required: 'O desconto máximo do INSS é obrigatório.',
  fgts_rate_is_required: 'A taxa do FGTS é obrigatória.',
  hour_rate_is_required: 'A taxa horária é obrigatória.',
  subtract_is_required: 'A subtração é obrigatória.',
  calculateWRDP_is_required: 'O cálculo WRDP é obrigatório.',
  reference_type_is_required: 'O tipo de referência é obrigatório.',
  judicial_court_is_required: 'O tribunal judicial é obrigatório.',
  opening_date_is_required: 'A data de abertura é obrigatória.',
  seller_id_is_required: 'O ID do vendedor é obrigatório.',
  return_is_required: 'O retorno é obrigatório.',
  driver_id_is_required: 'O ID do motorista é obrigatório.',
  license_plate_is_required: 'A placa do veículo é obrigatória.',
  federal_unit_is_required: 'A unidade federal é obrigatória.',
  reindeer_is_required: 'A rena é obrigatória.',
  chassi_is_required: 'O chassi é obrigatório.',
  manufacturing_year_is_required: 'O ano de fabricação é obrigatório.',
  model_is_required: 'O modelo é obrigatório.',
  phone_is_required: 'O telefone é obrigatório.',
  clients_id_is_required: 'O ID do cliente é obrigatório.',
  responsible_is_required: 'O responsável é obrigatório.',
  service_date_is_required: 'A data do serviço é obrigatória.',
  status_is_required: 'O status é obrigatório.',
  final_price_is_required: 'O preço final é obrigatório.',
  services_id_is_required: 'O ID dos serviços é obrigatório.',
  billed_is_required: 'Faturado é obrigatório.',
  license_number_is_required: 'O número da licença é obrigatório.',
  old_Password_is_required: 'A senha antiga é obrigatória.',
  password_must_be_at_least_6_characters: 'A senha deve ter pelo menos 6 caracteres.',
  new_password_is_required: 'A nova senha é obrigatória.',
  passwords_must_match: 'As senhas devem coincidir.',
  password_is_required: 'A senha é obrigatória.',
  confirm_password_is_required: 'A confirmação de senha é obrigatória.',
  first_name_required: 'O primeiro nome é obrigatório.',
  last_name_required: 'O último nome é obrigatório.',
  registryNumberPerson: 'CPF',
  end_date_cannot_be_smaller_than_initial_date:
    'A data final não pode ser anterior à data inicial.',
  termination_date_cannot_be_smaller_than_initial_date:
    'A data de termino não pode ser anterior à data inicial.',
  editCollaborator: 'Editar Colaborador',
  difference_in_debit_and_credit_values: 'Diferença nos valores de débito e crédito',
  the_debit_and_credit_values_are_different_do_you_want_to_continue:
    'Os valores de débito e crédito são diferentes. Deseja continuar?',
  continue: 'Continuar',
  editAccountingEntry: 'Editar Lançamento Contabil',
  inactivation_date_cannot_be_smaller_than_current_date:
    'A data de inativação não pode ser anterior à data atual.',
  supplier_contract_is_required: 'Contrato de Fornecedor é obrigatório',
  invalid_date: 'Data Inválida.',
  file_too_large: 'Arquivo excede o limite máximo de tamanho (75MB)',
  something_went_wrong_please_check_your_files_again:
    'Algo deu errado. Por favor verifique seus arquivos novamente.',
  clear: 'Limpar',
  create_success: 'Criado com sucesso!',
  edit_success: 'Editado com sucesso!',
  away: 'Ausente',
  busy: 'Ocupado',
  offline: 'Offline',
  invisible: 'Invisivel',
  selected: 'selecionado(s)',
  are_you_sure_you_want_to_delete_multiple_1: 'Tem certeza de que quer excluir ',
  are_you_sure_you_want_to_delete_multiple_2: 'itens?',
  account_already_exists: 'Conta com esta classificação ja existente!',
  history_already_exists: 'Histórico com este codigo ja existente',
  field_cannot_exceed_240_char: 'O campo não pode exceder 240 caracteres',
  field_cannot_exceed_124_char: 'O campo não pode exceder 124 caracteres',
  price_limit_exceed: 'Limite de preço excedido',
  required_field: 'Campo Obrigatório',
  publication_date_cannot_be_smaller_than_signature_date:
    'Data de publicação não pode ser inferior a data de assinatura',
  user_or_password_invalid: 'Credenciais Inválidas',
  payment_date_cannot_be_smaller_than_openning_date:
    'Data de Pagamento não pode ser anterior a data de assinatura',
  service_date_cannot_be_smaller_than_openning_date:
    'Data do Serviço não pode ser anterior a data de abertura',
  cost_center_already_exists: 'O código de Centro de Custos ja esta sendo utilizado',
  department_already_exists: 'O código de Departamento ja esta sendo utilizado',
  editSupplierContract: 'Editar contrato de Forncedor',
  position_aleary_exists: 'Cargo com este codigo ja existente',
};

export default pt;
