import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  biddings: [],
  bidding: null,
  companies: [],
  company: null,
  contractors: [],
  contractor: null,
  events: [],
  event: null,
  lots: [],
  lot: null,
  results: [],
  result: null,
};

const slice = createSlice({
  name: 'biddings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BIDDINGS
    getBiddingsSuccess(state, action) {
      const biddings = action.payload;

      state.isLoading = false;
      state.biddings = biddings;
    },

    // GET BIDDING
    getBiddingSuccess(state, action) {
      const bidding = action.payload;

      state.isLoading = false;
      state.bidding = bidding;
    },

    // GET COMPANIES
    getCompaniesSuccess(state, action) {
      const companies = action.payload;

      state.isLoading = false;
      state.companies = companies;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      const company = action.payload;

      state.isLoading = false;
      state.company = company;
    },

    // GET CONTRACTORS
    getContractorsSuccess(state, action) {
      const contractors = action.payload;

      state.isLoading = false;
      state.contractors = contractors;
    },

    // GET CONTRACTOR
    getContractorSuccess(state, action) {
      const contractor = action.payload;

      state.isLoading = false;
      state.contractor = contractor;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      const events = action.payload;

      state.isLoading = false;
      state.events = events;
    },

    // GET EVENT
    getEventSuccess(state, action) {
      const event = action.payload;

      state.isLoading = false;
      state.event = event;
    },

    // GET LOTS
    getLotsSuccess(state, action) {
      const lots = action.payload;

      state.isLoading = false;
      state.lots = lots;
    },

    // GET LOT
    getLotSuccess(state, action) {
      const lot = action.payload;

      state.isLoading = false;
      state.lot = lot;
    },

    // GET RESULTS
    getResultsSuccess(state, action) {
      const results = action.payload;

      state.isLoading = false;
      state.results = results;
    },

    // GET RESULT
    getResultSuccess(state, action) {
      const result = action.payload;

      state.isLoading = false;
      state.result = result;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBiddings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/biddings');
      dispatch(slice.actions.getBiddingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBidding(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/biddings/${protocol}`);
      dispatch(slice.actions.getBiddingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/companies');
      dispatch(slice.actions.getCompaniesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies/${protocol}`);
      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContractors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/contractors');
      dispatch(slice.actions.getContractorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContractor(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/contractors/${protocol}`);
      dispatch(slice.actions.getContractorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/events');
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEvent(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/events/${protocol}`);
      dispatch(slice.actions.getEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLots() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/lots');
      dispatch(slice.actions.getLotsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLot(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/lots/${protocol}`);
      dispatch(slice.actions.getLotSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getResults() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/results');
      dispatch(slice.actions.getResultsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getResult(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/results/${protocol}`);
      dispatch(slice.actions.getResultSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
