// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/main';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  accountability: {
    root: path(ROOTS_DASHBOARD, '/accountability'),
    dashboards: path(ROOTS_DASHBOARD, '/accountability/dashboard'),
    declarations: path(ROOTS_DASHBOARD, '/accountability/declarations'),
    newDeclarations: path(ROOTS_DASHBOARD, '/accountability/declarations/new'),
    newAccountability: path(ROOTS_DASHBOARD, '/accountability/new'),
    editDeclarations: (protocol) =>
      path(ROOTS_DASHBOARD, `/accountability/declarations/${protocol}/edit`),
  },
  accounting: {
    root: path(ROOTS_DASHBOARD, '/accounting'),
    register: {
      root: path(ROOTS_DASHBOARD, '/accounting/register'),
      ledgerAccount: path(ROOTS_DASHBOARD, '/accounting/register/ledger-account'),
      editledgerAccount: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/register/ledger-account/${protocol}/edit`),
      newledgerAccount: path(ROOTS_DASHBOARD, '/accounting/register/ledger-account/new'),
      accountTypes: path(ROOTS_DASHBOARD, '/accounting/register/account-types'),
      editaccountTypes: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/register/account-types/${protocol}/edit`),
      newaccountTypes: path(ROOTS_DASHBOARD, '/accounting/register/account-types/new'),
      costCenter: path(ROOTS_DASHBOARD, '/accounting/register/cost-center'),
      editCostCenter: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/register/cost-center/${protocol}/edit`),
      newCostCenter: path(ROOTS_DASHBOARD, '/accounting/register/cost-center/new'),
      history: path(ROOTS_DASHBOARD, '/accounting/register/history'),
      edithistory: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/register/history/${protocol}/edit`),
      newhistory: path(ROOTS_DASHBOARD, '/accounting/register/history/new'),
      bankReconciliation: path(ROOTS_DASHBOARD, '/accounting/register/bank-reconciliation'),
      department: path(ROOTS_DASHBOARD, '/accounting/register/department'),
      editdepartment: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/register/department/${protocol}/edit`),
      newdepartment: path(ROOTS_DASHBOARD, '/accounting/register/department/new'),
      structures: {
        root: path(ROOTS_DASHBOARD, '/accounting/register/structures'),
        ais: path(ROOTS_DASHBOARD, '/accounting/register/structures/ais'),
        editais: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/ais/${protocol}/edit`),
        newais: path(ROOTS_DASHBOARD, '/accounting/register/structures/ais/new'),
        al: path(ROOTS_DASHBOARD, '/accounting/register/structures/al'),
        edital: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/al/${protocol}/edit`),
        newal: path(ROOTS_DASHBOARD, '/accounting/register/structures/al/new'),
        ebitda: path(ROOTS_DASHBOARD, '/accounting/register/structures/ebitda'),
        editebitda: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/ebitda/${protocol}/edit`),
        newebitda: path(ROOTS_DASHBOARD, '/accounting/register/structures/ebitda/new'),
        is: path(ROOTS_DASHBOARD, '/accounting/register/structures/is'),
        editis: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/is/${protocol}/edit`),
        newis: path(ROOTS_DASHBOARD, '/accounting/register/structures/is/new'),
        scf: path(ROOTS_DASHBOARD, '/accounting/register/structures/scf'),
        editscf: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/scf/${protocol}/edit`),
        newscf: path(ROOTS_DASHBOARD, '/accounting/register/structures/scf/new'),
        scse: path(ROOTS_DASHBOARD, '/accounting/register/structures/scse'),
        editscse: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/scse/${protocol}/edit`),
        newscse: path(ROOTS_DASHBOARD, '/accounting/register/structures/scse/new'),
        se: path(ROOTS_DASHBOARD, '/accounting/register/structures/se'),
        editse: (protocol) =>
          path(ROOTS_DASHBOARD, `/accounting/register/structures/se/${protocol}/edit`),
        newse: path(ROOTS_DASHBOARD, '/accounting/register/structures/se/new'),
      },
    },
    movements: {
      root: path(ROOTS_DASHBOARD, '/accounting/movements'),
      accountingEntries: path(ROOTS_DASHBOARD, '/accounting/movements/accounting-entries'),
      editaccountingEntries: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/movements/accounting-entries/${protocol}/edit`),
      newaccountingEntries: path(ROOTS_DASHBOARD, '/accounting/movements/accounting-entries/new'),
      entriesImport: path(ROOTS_DASHBOARD, '/accounting/movements/entries-import'),
      editentriesImport: (protocol) =>
        path(ROOTS_DASHBOARD, `/accounting/movements/entries-import/${protocol}/edit`),
    },
  },
  bidding: {
    root: path(ROOTS_DASHBOARD, '/bidding'),
    biddings: path(ROOTS_DASHBOARD, '/bidding/list'),
    newBiddings: path(ROOTS_DASHBOARD, '/bidding/new'),
    editBiddings: (protocol) => path(ROOTS_DASHBOARD, `/bidding/${protocol}/edit`),
    companies: path(ROOTS_DASHBOARD, '/bidding/companies'),
    newCompanies: path(ROOTS_DASHBOARD, '/bidding/companies/new'),
    editCompanies: (protocol) => path(ROOTS_DASHBOARD, `/bidding/companies/${protocol}/edit`),
    contractors: path(ROOTS_DASHBOARD, '/bidding/contractors'),
    newContractors: path(ROOTS_DASHBOARD, '/bidding/contractors/new'),
    editContractors: (protocol) => path(ROOTS_DASHBOARD, `/bidding/contractors/${protocol}/edit`),
    events: path(ROOTS_DASHBOARD, '/bidding/events'),
    newEvents: path(ROOTS_DASHBOARD, '/bidding/events/new'),
    editEvents: (protocol) => path(ROOTS_DASHBOARD, `/bidding/events/${protocol}/edit`),
    lots: path(ROOTS_DASHBOARD, '/bidding/lots'),
    newLots: path(ROOTS_DASHBOARD, '/bidding/lots/new'),
    editLots: (protocol) => path(ROOTS_DASHBOARD, `/bidding/lots/${protocol}/edit`),
    results: path(ROOTS_DASHBOARD, '/bidding/results'),
    newResults: path(ROOTS_DASHBOARD, '/bidding/results/new'),
    editResults: (protocol) => path(ROOTS_DASHBOARD, `/bidding/results/${protocol}/edit`),
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, '/user/account'),
  },
  contracts: {
    root: path(ROOTS_DASHBOARD, '/contracts'),
    additive: path(ROOTS_DASHBOARD, '/contracts/additive'),
    newAdditive: path(ROOTS_DASHBOARD, '/contracts/additive/new'),
    editAdditive: (protocol) => path(ROOTS_DASHBOARD, `/contracts/additive/${protocol}/edit`),
    contracts: path(ROOTS_DASHBOARD, '/contracts/contracts'),
    newContracts: path(ROOTS_DASHBOARD, '/contracts/contracts/new'),
    editContracts: (protocol) => path(ROOTS_DASHBOARD, `/contracts/contracts/${protocol}/edit`),
    managementContract: path(ROOTS_DASHBOARD, '/contracts/management-contract'),
    newManagementContract: path(ROOTS_DASHBOARD, '/contracts/management-contract/new'),
    editManagementContract: (protocol) =>
      path(ROOTS_DASHBOARD, `/contracts/management-contract/${protocol}/edit`),
    occurrences: path(ROOTS_DASHBOARD, '/contracts/occurrences'),
    newOccurrences: path(ROOTS_DASHBOARD, '/contracts/occurrences/new'),
    editOccurrences: (protocol) => path(ROOTS_DASHBOARD, `/contracts/occurrences/${protocol}/edit`),
    people: path(ROOTS_DASHBOARD, '/contracts/people'),
    newPeople: path(ROOTS_DASHBOARD, '/contracts/people/new'),
    editPeople: (protocol) => path(ROOTS_DASHBOARD, `/contracts/people/${protocol}/edit`),
    readjustments: path(ROOTS_DASHBOARD, '/contracts/readjustments'),
    newReadjustments: path(ROOTS_DASHBOARD, '/contracts/readjustments/new'),
    editReadjustments: (protocol) =>
      path(ROOTS_DASHBOARD, `/contracts/readjustments/${protocol}/edit`),
    resources: path(ROOTS_DASHBOARD, '/contracts/resources'),
    newResources: path(ROOTS_DASHBOARD, '/contracts/resources/new'),
    editResources: (protocol) => path(ROOTS_DASHBOARD, `/contracts/resources/${protocol}/edit`),
    situations: path(ROOTS_DASHBOARD, '/contracts/situations'),
    newRituations: path(ROOTS_DASHBOARD, '/contracts/situations/new'),
    editRituations: (protocol) => path(ROOTS_DASHBOARD, `/contracts/situations/${protocol}/edit`),
    supplierContract: path(ROOTS_DASHBOARD, '/contracts/supplier-contract'),
    newSupplierContract: path(ROOTS_DASHBOARD, '/contracts/supplier-contract/new'),
    editSupplierContract: (protocol) =>
      path(ROOTS_DASHBOARD, `/contracts/supplier-contract/${protocol}/edit`),
    warranties: path(ROOTS_DASHBOARD, '/contracts/warranties'),
    newWarranties: path(ROOTS_DASHBOARD, '/contracts/warranties/new'),
    editWarranties: (protocol) => path(ROOTS_DASHBOARD, `/contracts/warranties/${protocol}/edit`),
  },
  customerRelatorioship: {
    root: path(ROOTS_DASHBOARD, '/'),
    budgets: path(ROOTS_DASHBOARD, '/'),
    clients: path(ROOTS_DASHBOARD, '/'),
    leads: path(ROOTS_DASHBOARD, '/'),
    subscriptions: path(ROOTS_DASHBOARD, '/'),
  },
  financial: {
    root: path(ROOTS_DASHBOARD, '/financial'),
    banks: path(ROOTS_DASHBOARD, '/financial/banks'),
    newBanks: path(ROOTS_DASHBOARD, '/financial/banks/new'),
    editBanks: (protocol) => path(ROOTS_DASHBOARD, `/financial/banks/${protocol}/edit`),
    bankStatements: path(ROOTS_DASHBOARD, '/financial/bank-statements'),
    newBankStatements: path(ROOTS_DASHBOARD, '/financial/bank-statements/new'),
    editBankStatements: (protocol) =>
      path(ROOTS_DASHBOARD, `/financial/bank-statements/${protocol}/edit`),
    bank: path(ROOTS_DASHBOARD, '/financial/bank'),
    newBank: path(ROOTS_DASHBOARD, '/financial/bank/new'),
    editBank: (code) => path(ROOTS_DASHBOARD, `/financial/bank/${code}/edit`),
    commitments: path(ROOTS_DASHBOARD, '/financial/commitments'),
    newCommitments: path(ROOTS_DASHBOARD, '/financial/commitments/new'),
    editCommitments: (protocol) => path(ROOTS_DASHBOARD, `/financial/commitments/${protocol}/edit`),
    financialAccounts: path(ROOTS_DASHBOARD, '/financial/financial-accounts'),
    newFinancialAccounts: path(ROOTS_DASHBOARD, '/financial/financial-accounts/new'),
    editFinancialAccounts: (protocol) =>
      path(ROOTS_DASHBOARD, `/financial/financial-accounts/${protocol}/edit`),
    financialEntriesAudition: path(ROOTS_DASHBOARD, '/financial/financial-entiresAudition'),
    newFinancialEntriesAudition: path(ROOTS_DASHBOARD, '/financial/financial-entiresAudition/new'),
    editFinancialEntriesAudition: (protocol) =>
      path(ROOTS_DASHBOARD, `/financial/financial-entiresAudition/${protocol}/edit`),
    financialEntries: path(ROOTS_DASHBOARD, '/financial/financial-entries'),
    newFinancialEntries: path(ROOTS_DASHBOARD, '/financial/financial-entries/new'),
    editFinancialEntries: (protocol) =>
      path(ROOTS_DASHBOARD, `/financial/financial-entries/${protocol}/edit`),
    accountFinancialEntries: (protocol) =>
      path(ROOTS_DASHBOARD, `/financial/financial-entries/${protocol}/account`),
    supplierClassificaion: path(ROOTS_DASHBOARD, '/financial/supplier-classificaion'),
    newSupplierClassificaion: path(ROOTS_DASHBOARD, '/financial/supplier-classificaion/new'),
    editSupplierClassificaion: (protocol) =>
      path(ROOTS_DASHBOARD, `/financial/supplier-classificaion/${protocol}/edit`),
    transfer: path(ROOTS_DASHBOARD, '/financial/transfer'),
    newTransfer: path(ROOTS_DASHBOARD, '/financial/transfer/new'),
    editTransfer: (protocol) => path(ROOTS_DASHBOARD, `/financial/transfer/${protocol}/edit`),
  },
  fiscal: {
    root: path(ROOTS_DASHBOARD, '/fiscal'),
  },
  humanResources: {
    root: path(ROOTS_DASHBOARD, '/human-resources'),
    collaborators: path(ROOTS_DASHBOARD, '/human-resources/collaborators'),
    newCollaborators: path(ROOTS_DASHBOARD, '/human-resources/collaborators/new'),
    editCollaborators: (protocol) =>
      path(ROOTS_DASHBOARD, `/human-resources/collaborators/${protocol}/edit`),
    payroll: path(ROOTS_DASHBOARD, '/human-resources/payroll'),
    newPayroll: path(ROOTS_DASHBOARD, '/human-resources/payroll/new'),
    editPayroll: (protocol) => path(ROOTS_DASHBOARD, `/human-resources/payroll/${protocol}/edit`),
    positions: path(ROOTS_DASHBOARD, '/human-resources/positions'),
    newPositions: path(ROOTS_DASHBOARD, '/human-resources/positions/new'),
    editPositions: (protocol) =>
      path(ROOTS_DASHBOARD, `/human-resources/positions/${protocol}/edit`),
    references: path(ROOTS_DASHBOARD, '/human-resources/references'),
    newReferences: path(ROOTS_DASHBOARD, '/human-resources/references/new'),
    editReferences: (protocol) =>
      path(ROOTS_DASHBOARD, `/human-resources/references/${protocol}/edit`),
    incomeTax: path(ROOTS_DASHBOARD, '/human-resources/income-tax'),
    newIncomeTax: path(ROOTS_DASHBOARD, '/human-resources/income-tax/new'),
    editIncomeTax: (protocol) =>
      path(ROOTS_DASHBOARD, `/human-resources/income-tax/${protocol}/edit`),
    inss: path(ROOTS_DASHBOARD, '/human-resources/inss'),
    newInss: path(ROOTS_DASHBOARD, '/human-resources/inss/new'),
    editInss: (protocol) => path(ROOTS_DASHBOARD, `/human-resources/inss/${protocol}/edit`),
    payrollPreferences: path(ROOTS_DASHBOARD, '/human-resources/payroll-preferences'),
  },
  lawsuits: {
    root: path(ROOTS_DASHBOARD, '/lawsuits'),
    newLawsuits: path(ROOTS_DASHBOARD, '/lawsuits/new'),
    editLawsuits: (protocol) => path(ROOTS_DASHBOARD, `/lawsuits/${protocol}/edit`),
  },
  logistics: {
    root: path(ROOTS_DASHBOARD, '/logistics'),
    balance: path(ROOTS_DASHBOARD, '/logistics/balance'),
    newBalance: path(ROOTS_DASHBOARD, '/logistics/balance/new'),
    editBalance: (protocol) => path(ROOTS_DASHBOARD, `/logistics/balance/${protocol}/edit`),
    orderBlocks: path(ROOTS_DASHBOARD, '/logistics/order-blocks'),
    newOrderBlocks: path(ROOTS_DASHBOARD, '/logistics/order-blocks/new'),
    editOrderBlocks: (protocol) =>
      path(ROOTS_DASHBOARD, `/logistics/order-blocks/${protocol}/edit`),
    routes: path(ROOTS_DASHBOARD, '/logistics/routes'),
    newRoutes: path(ROOTS_DASHBOARD, '/logistics/routes/new'),
    editRoutes: (protocol) => path(ROOTS_DASHBOARD, `/logistics/routes/${protocol}/edit`),
    vehicles: path(ROOTS_DASHBOARD, '/logistics/vehicles'),
    newVehicles: path(ROOTS_DASHBOARD, '/logistics/vehicles/new'),
    editVehicles: (protocol) => path(ROOTS_DASHBOARD, `/logistics/vehicles/${protocol}/edit`),
    sellerDriver: path(ROOTS_DASHBOARD, '/logistics/seller-driver'),
    newSellerDriver: path(ROOTS_DASHBOARD, '/logistics/seller-driver/new'),
    editSellerDriver: (protocol) =>
      path(ROOTS_DASHBOARD, `/logistics/seller-driver/${protocol}/edit`),
  },
  projects: {
    root: path(ROOTS_DASHBOARD, '/projects'),
    projects: path(ROOTS_DASHBOARD, '/projects/projects'),
    newProjects: path(ROOTS_DASHBOARD, '/projects/projects/new'),
    editProjects: (protocol) => path(ROOTS_DASHBOARD, `/projects/projects/${protocol}/edit`),
    serviceOrders: path(ROOTS_DASHBOARD, '/projects/service-orders'),
    newServiceOrders: path(ROOTS_DASHBOARD, '/projects/service-orders/new'),
    editServiceOrders: (protocol) =>
      path(ROOTS_DASHBOARD, `/projects/service-orders/${protocol}/edit`),
    services: path(ROOTS_DASHBOARD, '/projects/services'),
    newServices: path(ROOTS_DASHBOARD, '/projects/services/new'),
    editServices: (protocol) => path(ROOTS_DASHBOARD, `/projects/services/${protocol}/edit`),
    clients: path(ROOTS_DASHBOARD, '/projects/clients'),
    newClients: path(ROOTS_DASHBOARD, '/projects/clients/new'),
    editClients: (protocol) => path(ROOTS_DASHBOARD, `/projects/clients/${protocol}/edit`),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    accountability: {
      root: path(ROOTS_DASHBOARD, '/reports/accountability'),
    },
    financial: {
      root: path(ROOTS_DASHBOARD, '/reports/financial'),
      transfer: {
        root: path(ROOTS_DASHBOARD, '/reports/financial/transfer'),
        general: path(ROOTS_DASHBOARD, '/reports/financial/transfer/general'),
        approved: path(ROOTS_DASHBOARD, '/reports/financial/transfer/approved'),
        reproved: path(ROOTS_DASHBOARD, '/reports/financial/transfer/reproved'),
        awaiting_audition: path(ROOTS_DASHBOARD, '/reports/financial/transfer/awaiting-audition'),
      },
      commitments: {
        root: path(ROOTS_DASHBOARD, '/reports/financial/commitments'),
        general: path(ROOTS_DASHBOARD, '/reports/financial/commitments/general'),
        approved: path(ROOTS_DASHBOARD, '/reports/financial/commitments/approved'),
        reproved: path(ROOTS_DASHBOARD, '/reports/financial/commitments/reproved'),
        awaiting_audition: path(
          ROOTS_DASHBOARD,
          '/reports/financial/commitments/awaiting-audition'
        ),
      },
      financialEntries: {
        root: path(ROOTS_DASHBOARD, '/reports/financial/financial-entries'),
        general: path(ROOTS_DASHBOARD, '/reports/financial/financial-entries/general'),
        approved: path(ROOTS_DASHBOARD, '/reports/financial/financial-entries/approved'),
        reproved: path(ROOTS_DASHBOARD, '/reports/financial/financial-entries/reproved'),
        awaiting_audition: path(
          ROOTS_DASHBOARD,
          '/reports/financial/financial-entries/awaiting-audition'
        ),
        formalError: path(ROOTS_DASHBOARD, '/reports/financial/financial-entries/formal-error'),
        damageToThePublicTreasury: path(
          ROOTS_DASHBOARD,
          '/reports/financial/financial-entries/damage-to-the-public-treasury'
        ),
        otherNonConformities: path(
          ROOTS_DASHBOARD,
          '/reports/financial/financial-entries/other-non-conformities'
        ),
      },
      penaltiesReport: path(ROOTS_DASHBOARD, '/reports/financial/penalties'),
      futureEntriesReport: path(ROOTS_DASHBOARD, '/reports/financial/future-entries'),
      entriesByContractReport: path(ROOTS_DASHBOARD, '/reports/financial/entries-by-contract'),
    },
    accounting: {
      root: path(ROOTS_DASHBOARD, '/reports/accounting'),
      is: path(ROOTS_DASHBOARD, '/reports/accounting/is'),
      balanceSheetTrial: path(ROOTS_DASHBOARD, '/reports/accounting/balance-sheet-trial'),
      balanceSheet: {
        root: path(ROOTS_DASHBOARD, '/reports/accounting/balance-sheet'),
        analytical: path(ROOTS_DASHBOARD, '/reports/accounting/balance-sheet/analytic'),
        analyticalAccumulated: path(
          ROOTS_DASHBOARD,
          '/reports/accounting/balance-sheet/analytic-accumulated'
        ),
        synthetic: path(ROOTS_DASHBOARD, '/reports/accounting/balance-sheet/synthetic'),
      },
      diary: path(ROOTS_DASHBOARD, '/reports/accounting/diary'),
      ledger: path(ROOTS_DASHBOARD, '/reports/accounting/ledger'),
    },
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/accountability'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (protocol) => path(ROOTS_DASHBOARD, `/user/${protocol}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
