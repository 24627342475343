import PropTypes from 'prop-types';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns'; // Import parseISO from date-fns

RHFDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
};

function RHFDatePicker(props) {
  const { value, onChange, ...otherProps } = props;

  const handleDateChange = async (date) => {
    if (date.getFullYear() > 1000) {
      onChange(new Date(date.getTime()));
    }
  };

  const parsedValue = typeof value === 'string' ? parseISO(value) : value;

  return <DatePicker value={parsedValue} onChange={handleDateChange} {...otherProps} />;
}

export default RHFDatePicker;
