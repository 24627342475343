const { createSlice } = require('@reduxjs/toolkit');

const slice = createSlice({
  name: 'dialog',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openDialog(state) {
      state.isOpen = true;
    },
    closeDialog(state) {
      state.isOpen = false;
    },
  },
});

export const { openDialog, closeDialog } = slice.actions;
export default slice.reducer;
