import { Divider, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import { IconButtonAnimate } from '../../../components/animate';
import BadgeStatus from '../../../components/badge-status/BadgeStatus';
import { CustomAvatar } from '../../../components/custom-avatar';
import Iconify from '../../../components/iconify/Iconify';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------
const STATUS = ['online', 'away', 'busy', 'offline', 'invisible'];
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const [status, setStatus] = useState('online');

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  const handleChangeStatus = (e) => {
    setStatus(e);
    localStorage.setItem('userStatus', e);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={user?.avatarUrl}
          alt={user?.displayName}
          name={user?.displayName}
          BadgeProps={{
            badgeContent: <BadgeStatus status={status} />,
          }}
        />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ p: 0, pb: 1.5 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 2, pr: 1, pl: 2.5 }}>
          <div>
            <Typography variant="subtitle2" noWrap>
              {user?.displayName}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {user?.role}
            </Typography>
          </div>

          <Tooltip title={translate('logout')}>
            <IconButton color="error" onClick={handleLogout}>
              <Iconify icon="ic:round-power-settings-new" />
            </IconButton>
          </Tooltip>
        </Stack>

        <MenuItem>
          <BadgeStatus size="large" status={status} sx={{ m: 0.5, flexShrink: 0 }} />

          <Select
            native
            fullWidth
            value={status}
            onChange={(event) => handleChangeStatus(event.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                p: 0,
                pl: 2,
                typography: 'body2',
                textTransform: 'capitalize',
              },
              '& .MuiNativeSelect-icon': {
                right: -16,
                top: 'unset',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
            }}
          >
            {STATUS.map((option) => (
              <option key={option} value={option}>
                {translate(option)}
              </option>
            ))}
          </Select>
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => handleClickItem('/')}>
          <Iconify icon="ic:round-account-box" />
          {translate('system')}
        </MenuItem>

        <MenuItem onClick={() => handleClickItem(PATH_DASHBOARD.user.account)}>
          <Iconify icon="eva:settings-2-fill" />
          {translate('configurations')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
