import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  transfers: [],
  transfer: null,
  commitments: [],
  commitment: null,
  financialAccounts: [],
  financialAccount: null,
  bankStatements: [],
  bankStatement: null,
  financialEntriesAuditions: [],
  financialEntryAudition: null,
  financialEntries: [],
  financialEntry: null,
  banks: [],
  bank: null,
};

const slice = createSlice({
  name: 'financial',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TRANSFERS
    getTransfersSuccess(state, action) {
      const transfers = action.payload;

      state.isLoading = false;
      state.transfers = transfers;
    },

    // GET TRANSFER
    getTransferSuccess(state, action) {
      const transfer = action.payload;
      console.log(transfer);
      if (
        !transfer.total_fare.toString().includes('.') &&
        !transfer.total_fare.toString().endsWith('.00')
      ) {
        transfer.total_fare += '.00';
      }

      transfer.total_fare = transfer.total_fare.toString().replace('.', ',');

      if (
        !transfer.transfer_price.toString().includes('.') &&
        !transfer.transfer_price.toString().endsWith('.00')
      ) {
        transfer.transfer_price += '.00';
      }

      transfer.transfer_price = transfer.transfer_price.toString().replace('.', ',');

      state.isLoading = false;
      state.transfer = transfer;
    },

    // CLEAR TRANSFER
    clearTransferSuccess(state, action) {
      state.transfer = null;
    },

    // GET COMMITMENTS
    getCommitmentsSuccess(state, action) {
      const commitments = action.payload;

      state.isLoading = false;
      state.commitments = commitments;
    },

    // GET COMMITMENT
    getCommitmentSuccess(state, action) {
      const commitment = action.payload;

      if (
        !commitment.commitment_price.toString().includes('.') &&
        !commitment.commitment_price.toString().endsWith('.00')
      ) {
        commitment.commitment_price += '.00';
      }

      commitment.commitment_price = commitment.commitment_price.toString().replace('.', ',');

      state.isLoading = false;
      state.commitment = commitment;
    },

    clearCommitmentSuccess(state, action) {
      state.commitment = null;
    },

    // GET FINANCIAL ACCOUNTS
    getFinancialAccountsSuccess(state, action) {
      const financialAccounts = action.payload;

      state.isLoading = false;
      state.financialAccounts = financialAccounts;
    },

    // GET FINANCIAL ACCOUNT
    getFinancialAccountSuccess(state, action) {
      const financialAccount = action.payload;

      financialAccount.opening_balance = financialAccount.opening_balance
        .toString()
        .replace('.', ',');

      state.isLoading = false;
      state.financialAccount = financialAccount;
    },

    clearFinancialAccountsSuccess(state, action) {
      state.financialAccount = null;
    },

    // GET BANK STATEMENTS
    getBankStatementsSuccess(state, action) {
      const bankStatements = action.payload;

      state.isLoading = false;
      state.bankStatements = bankStatements;
    },

    // GET BANK STATEMENT
    getBankStatementSuccess(state, action) {
      const bankStatement = action.payload;

      state.isLoading = false;
      state.bankStatement = bankStatement;
    },

    // GET FINANCIAL ENTRIES AUDITIONS
    getFinancialEntriesAuditionsSuccess(state, action) {
      const financialEntriesAuditions = action.payload;

      state.isLoading = false;
      state.commitments = financialEntriesAuditions;
    },

    // GET FINANCIAL ENTRY AUDITION
    getFinancialEntriesAuditionSuccess(state, action) {
      const financialEntryAudition = action.payload;

      state.isLoading = false;
      state.transfer = financialEntryAudition;
    },

    // GET FINANCIAL ENTRIES
    getFinancialEntriesSuccess(state, action) {
      const financialEntries = action.payload;

      state.isLoading = false;
      state.financialEntries = financialEntries;
    },

    // GET FINANCIAL ENTRY
    getFinancialEntrySuccess(state, action) {
      const financialEntry = action.payload;

      state.isLoading = false;
      state.financialEntry = financialEntry;
    },

    // GET BANKS
    getBanksSuccess(state, action) {
      const banks = action.payload;

      state.isLoading = false;
      state.banks = banks;
    },

    // GET BANK
    getBankSuccess(state, action) {
      const bank = action.payload;

      state.isLoading = false;
      state.bank = bank;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTransfers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/transfers', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getTransfersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTransfer(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/transfers/${protocol}`);
      dispatch(slice.actions.getTransferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearTransfer() {
  return async (dispatch) => {
    dispatch(slice.actions.clearTransferSuccess());
  };
}

// ----------------------------------------------------------------------

export function getCommitments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/commitments', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getCommitmentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCommitment(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/commitments/${protocol}`);
      dispatch(slice.actions.getCommitmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearCommitment() {
  return async (dispatch) => {
    dispatch(slice.actions.clearCommitmentSuccess());
  };
}

// ----------------------------------------------------------------------

export function getFinancialAccounts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/financial-accounts');
      dispatch(slice.actions.getFinancialAccountsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFinancialAccount(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/financial-accounts/${protocol}`);
      dispatch(slice.actions.getFinancialAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearFinancialAccounts(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.clearFinancialAccountsSuccess());
  };
}

// ----------------------------------------------------------------------

export function getBankStatements() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/bank-statements', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getBankStatementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBankStatement(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/bank-statements/${protocol}`);
      dispatch(slice.actions.getBankStatementSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFinancialEntriesAuditions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/financial-entries-audition');
      dispatch(slice.actions.getFinancialEntriesAuditionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFinancialEntriesAudition(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/financial-entries-audition/${protocol}`);
      dispatch(slice.actions.getFinancialEntriesAuditionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFinancialEntries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/financial-entries', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getFinancialEntriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFinancialEntry(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/financial-entries/${protocol}`);
      dispatch(slice.actions.getFinancialEntrySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBanks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/bank');
      dispatch(slice.actions.getBanksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBank(code) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/bank/${code}`);
      dispatch(slice.actions.getBankSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  };
}
