import { format, getTime, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment-timezone';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const formatDate = (dt, fmt) => {
    const day = dt.getDate();
    const month = months[dt.getMonth()];
    const year = dt.getFullYear();

    return fmt
      .replace('dd', day.toString().padStart(2, '0'))
      .replace('MMM', month)
      .replace('yyyy', year);
  };

  return date ? formatDate(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: ptBR,
      })
    : '';
}

export function fDataBR(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const formatDate = (dt, fmt) => {
    const day = dt.getDate();
    const month = months[dt.getMonth()];
    const year = dt.getFullYear();

    return fmt
      .replace('dd', day.toString().padStart(2, '0'))
      .replace('MMM', month)
      .replace('yyyy', year);
  };

  return date ? formatDate(new Date(date), fm) : '';
}

export function setToMidnightLocal(date) {
  return moment(date).startOf('day').toDate();
}
