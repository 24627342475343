const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `Edit`,
  delete: `Delete`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  deleteAll: `Delete All`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  balanceSheet: 'Balance Sheet',
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  file_type_not_supported: 'File type not supported',
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  loginSystem: `Login to the System`,
  emailAddress: `Email Address`,
  password: `Password`,
  forgotPassword: `Forgot password?`,
  welcomeBack: `Hi, Welcome Back`,
  login: `Login`,
  settings: `Settings`,
  mode: `Mode`,
  contrast: `Contrast`,
  direction: `Direction`,
  layout: `Layout`,
  stretch: `Strech`,
  largeResolutionNotice: `Only available at large resolutions > 1600px (xl)`,
  presets: `Presets`,
  fullscreen: `Fullscreen`,
  exitFullscreen: `Exit Fullscreen`,
  accountant: `Accountant`,
  auditor: `Auditor`,
  system: `System`,
  configurations: `Configurations`,
  logout: `Logout`,
  accountability: `Accountability`,
  selectAccountability: `Select Accountability`,
  cancel: `Cancel`,
  declarations: `Declarations`,
  financial: `Financial`,
  transfers: `Transfers`,
  commitments: `Commitments`,
  commitment: `Commitment`,
  banks: `Banks`,
  financialAccounts: `Financial Accounts`,
  bankStatements: `Bank Statements`,
  bankStatement: `Bank Statement`,
  areYouSureYouWantToImport: 'Are you shure you want to import?',
  financialEntriesAudition: `Financial Entries Audition`,
  financialEntries: `Financial Entries`,
  accountFinancialEntry: `Account Financial Entries`,
  bankReconciliation: 'Bank Reconciliation',
  bank_statement_id_is_required: 'Bank Statement is required.',
  accounting: `Accounting`,
  register: `Register`,
  ledgerAccount: `Ledger Account`,
  costCenter: `Cost Center`,
  history: `History`,
  department: `Department`,
  structures: `Structures`,
  al: `AL (DLPA)`,
  se: `SE (DOAR)`,
  is: `IS (DRE)`,
  ais: `AIS (DRA)`,
  payment_date_cannot_be_smaller_than_openning_date:
    'Payment Date cannot be smaller than openning date',
  scse: `SCSE (DMPL)`,
  scf: `SCF (DSP)`,
  entryByContract: 'Entry by Contract',
  ebitda: `EBITDA`,
  movements: `Movements`,
  accountingEntries: `Accounting Entries`,
  entriesImport: `Entries Import`,
  humanResources: `Human Resources`,
  payroll: `Payroll`,
  collaborators: `Collaborators`,
  positions: `Positions`,
  references: `References`,
  incomeTax: `Income Tax`,
  inss: `INSS`,
  payrollPreferences: `Payroll Preferences`,
  logistics: `Logistics`,
  balance: `Balance`,
  orderBlocks: `Order Blocks`,
  routes: `Routes`,
  vehicles: `Vehicles`,
  sellerDriver: `Seller/Driver`,
  projects: `Projects`,
  services: `Services`,
  serviceOrders: `Service Orders`,
  clients: `Clients`,
  lawsuits: `Lawsuits`,
  userList: `User List`,
  userAccount: `User Account`,
  dashboard: `Dashboard`,
  newAccountability: `New Accountability`,
  monthlyTransferTotal: `Monthly Transfer Total`,
  expensesTotal: `Expenses Total`,
  entriesTotal: `Entries Total`,
  total: `Total`,
  awaitingAudition: `Awaiting Audition`,
  awaiting_audition: `Awaiting Audition`,
  approved: `Approved`,
  approve: `Approve`,
  corrected: `Corrected`,
  correct: `Correct`,
  reproved: `Reproved`,
  reprove: `Reprove`,
  transfersCommitments: `Transfers/Commitments`,
  number: `Nr.°`,
  documentDate: `Document Date`,
  documentData: `Document Data`,
  type: `Type`,
  operation: `Operation`,
  value: `Value`,
  status: `Status`,
  viewAll: `View All`,
  declarationsList: `Declarations List`,
  newDeclaration: `New Declaration`,
  search: `Search...`,
  code: `Code`,
  date: `Date`,
  responsibleForRegister: `Responsible For The Register`,
  noData: `No Data`,
  dense: `Dense`,
  transferList: `Transfer List`,
  newTransfer: `New Transfer`,
  cost: `Cost`,
  classification: `Classification`,
  bankFee: `Bank Fee`,
  commitmentsList: `Commitments List`,
  newCommitment: `New Commitment`,
  commitmentNumber: `Commitment Nr.`,
  phase: `Phase`,
  transferCode: `Transfer Code`,
  bankList: `Bank List`,
  newBank: `New Bank`,
  name: `Name`,
  financialAccountsList: `Financial Accounts List`,
  newFinancialAccount: `New Financial Account`,
  agency: `Agency`,
  applicationAccount: `Application Account`,
  bank: `Bank`,
  bankStatementsList: `Bank Statements List`,
  newBankStatement: `New Bank Statement`,
  financialAccount: `Financial Account`,
  statementNumber: `Statement Number`,
  financialEntriesAuditionList: `Financial Entries Audition List`,
  auditedDocument: `Audited Document`,
  auditionDate: `Audition Date`,
  reason: `Reason`,
  financialEntriesList: `Financial Entries List`,
  newFinancialEntry: `New Financial Entry`,
  editFinancialEntry: `Edit Financial Entry`,
  awaiting: `Awaiting `,
  cancelled: `Cancelled`,
  all: `All`,
  contract: `Contract`,
  contracts: `Contracts`,
  amountPaid: `Amount Paid`,
  paymentDate: `Payment Date`,
  ledgerAccountsList: `Ledger Accounts List`,
  importLedgerAccounts: `Import Ledger Accounts`,
  editLedgerAccountsMask: `Edit Mask`,
  mask_is_required: 'Mask is required',
  mask_must_contain_only_nines_and_dots: 'Mask must only contain nines and dots',
  newLedgerAccount: `New Ledger Account`,
  registryDate: `Registry Date`,
  situation: `Situation`,
  inactiveFrom: `Inactive From`,
  costCenterList: `Cost Center List`,
  newCostCenter: `New Cost Center`,
  historyList: `History List`,
  newHistory: `New History`,
  departmentList: `Department List`,
  newDepartment: `New Department`,
  alStructureList: `AL Structure List`,
  newALStructure: `New AL Structure`,
  inform: `Inform`,
  seStructureList: `SE Structure List`,
  newSEStructure: `New SE Structure`,
  level: `Level`,
  option: `Option`,
  searchValue: `Search Value`,
  isStructureList: `IS Structure List`,
  newISStructure: `New IS Structure`,
  configuration: `Configuration`,
  sumGroupsBelow: `Sum Groups Below`,
  printGroup: `Print Group`,
  aisStructureList: `AIS Structure List`,
  newAISStructure: `New AIS Structure`,
  sum: `Sum`,
  groups: `Groups`,
  scseStructureList: `SCSE Structure List`,
  newSCSEStructure: `New SCSE Structure`,
  hasSubgroup: `Has Subgroup`,
  scfStructureList: `SCF Structure List`,
  newSCFStructure: `New SCF Structure`,
  ebitdaStructureList: `EBITDA Structure List`,
  newEBITDAStructure: `New EBITDA Structure`,
  accountingEntriesList: `Accounting Entries List`,
  newAccountingEntry: `New Accounting Entry`,
  origin: `Origin`,
  locator: `Locator`,
  debitLedgerAccount: `Debit Ledger Account`,
  creditLedgerAccount: `Credit Ledger Account`,
  entriesImportList: `Entries Import List`,
  upload: `Upload`,
  accounted: `Accounted`,
  documentIdentifier: `Document Identifier`,
  hasOffsetting: `Has Offsetting`,
  payrollsList: `Payrolls List`,
  newPayroll: `New Payroll`,
  collaborator: `Collaborator`,
  incomeBase: `Income Base`,
  collaboratorsList: `Collaborators List`,
  newCollaborator: `New Collaborator`,
  fullName: `Full Name`,
  registryNumber: `Registry Number`,
  phoneNumber: `Phone Number`,
  position: `Position`,
  admissionDate: `Admission Date`,
  sector: `Sector`,
  dependents: `Dependents`,
  monthlyHours: `Monthly Hours`,
  positionsList: `Positions List`,
  newPosition: `New Position`,
  referencesList: `References List`,
  newReference: `New Reference`,
  rate: `Rate`,
  referenceType: `Reference Type`,
  subtract: `Subtract`,
  hourRate: `Hour Rate`,
  incomeTaxList: `Income Tax List`,
  from: `From:`,
  to: `To:`,
  deduction: `Deduction`,
  newIncomeTax: `New Income Tax`,
  inssList: `INSS List`,
  newINSS: `New INSS`,
  newPayrollPreferences: `Payroll Preferences`,
  editPayroll: `Edit Payroll`,
  editPosition: `Edit Position`,
  editReference: 'Edit References',
  editIncomeTax: 'Edit Income Tax',
  incomeTaxPerDependent: `Income Tax Per Dependent`,
  inssCap: `INSS Cap`,
  maxINSSDiscount: `Max INSS Discount`,
  fgtsRate: `FGTS Rate`,
  save: `Save`,
  balanceList: `Balance List`,
  newBalance: `New Balance`,
  seller: `Seller`,
  return: `Return`,
  orderBlocksList: `Order Blocks List`,
  newOrderBlock: `New Order Block`,
  routesList: `Routes List`,
  newRoute: `New Route`,
  cities: `Cities`,
  vehiclesList: `Vehicles List`,
  newVehicle: `New Vehicle`,
  driver: `Driver`,
  licensePlate: `License Plate`,
  reindeer: `Reindeer`,
  chassi: `Chassis`,
  sellerDriverList: `Seller/Driver List`,
  newSellerDriver: `New Seller/Driver`,
  licenseNumber: `License Number`,
  projectsList: `Projects List`,
  newProject: `New Project`,
  servicesList: `Services List`,
  newService: `New Service`,
  price: `Price`,
  project: `Project`,
  serviceOrdersList: `Service Orders List`,
  newServiceOrder: `New Service Order`,
  protocolNr: `Protocol Nr.`,
  protocol: `Protocol`,
  client: `Client`,
  service: `Service`,
  openingDate: `Opening Date`,
  serviceDate: `Service Date`,
  clientsList: `Clients List`,
  ssn: `SSN`,
  email: `Email`,
  lawsuitsList: `Lawsuits List`,
  processNr: `Process Nr.`,
  defendant: `Defendant`,
  applicant: `Applicant`,
  causeCost: `Cause Cost`,
  riskRating: `Risk Rating`,
  newLawsuit: `New Lawsuit`,
  newUser: `New User`,
  active: `Active`,
  company: `Company`,
  role: `Role`,
  professionalDesignation: `Professional Designation`,
  notifications: `Notifications`,
  changePassword: `Change Password`,
  youHaveUnreadMessages: 'You have {totalUnRead} unread messages',
  allowed: `Allowed`,
  maxSizeOf: `Max Size of`,
  address: `Address`,
  country: `Country`,
  stateRegion: `State/Region`,
  city: `City`,
  zipCode: `Zip/Code`,
  activity: `Activity`,
  systemGeneral: `System (General)`,
  audition: `Audition`,
  morningActivityReport: `Monring Activity Report`,
  afternoonActivityReport: `Afternoon Activity Report`,
  alertMeWhenNewUpdate: `Alert me when a new update will be released`,
  inAppNotification: `In App Notification`,
  emailNotification: `E-Mail Notification`,
  whatsappNotification: `WhatsApp Notification`,
  inAppAuditionNotification: `In App Audition Notification`,
  emailAuditionNotification: `E-Mail Audition Notification`,
  whatsappAuditionNotification: `WhatsApp Audition Notification`,
  oldPassword: `Old Password`,
  newPassword: `New Password`,
  passwordMinimum6: `Password must be minimum 6+`,
  confirmNewPassword: `Confirm New Password`,
  saveChanges: `Save Changes`,
  cycleManagementMaintenance: `Cycle Management currently under maintenance`,
  workingHardToBringBack: `We are currently working hard to bring it back to you!`,
  goToHome: `Go To Home`,
  needHelp: `Need Help?`,
  maintenance: `Maintenance`,
  initialDate: `Initial Date`,
  endDate: `End Date`,
  payrollDiscount: `Payroll Discount`,
  contractDiscount: `Contract Discount`,
  otherDiscount: `Other Discount`,
  rectifier: `Rectifier`,
  pisExemption: `PIS Exemption`,
  managementContract: `Management Contract`,
  firstResponsible: `1° Responsible`,
  secondResponsible: `2° Responsible`,
  createAccountability: `Create Accountability`,
  dropOrSelectFile: `Drop Or Select File`,
  dropFilesHere: `Drop files here or click to %1 thorough your machine`,
  browse: `browse`,
  createLedgerAccount: `Create Ledger Account`,
  transferClassification: `Transfer Classification`,
  none: `None`,
  fixedVariable: `Fixed + Variable`,
  dentistry: `Dentistry`,
  investment: `Investment`,
  educationResearch: `Education And Research`,
  specialPrograms: `Special Programs`,
  authorizedInvestmentPlan: `Authorized Investment Plan`,
  totalFare: `Total Fare`,
  transferPrice: `Transfer Price`,
  commitmentCode: `Commitment Code`,
  commitmentPhase: `Commitment Phase`,
  budget: `Budget`,
  reserved: `Reserved`,
  liquidated: `Liquidated`,
  paid: `Paid`,
  transferOperation: `Transfer Operation`,
  commitmentPrice: `Commitment Price`,
  createTransfer: `Create Transfer`,
  createCommitment: `Create Commitment`,
  addDeclaration: `Add Declaration`,
  addBank: `Add Bank`,
  accountingAccount: `Accounting Account`,
  openingBalance: `Opening Balance`,
  addFinancialAccount: `Add Financial Account`,
  addBankStatement: `Add Bank Statement`,
  taxes: `Taxes`,
  files: `Files`,
  docDate: `Doc. Date`,
  documentPrice: `Document Price`,
  documentType: `Document Type`,
  operationType: `Operation Type`,
  supplierContract: `Supplier Contract`,
  futureEntry: `Future Entry`,
  conciliated: `Conciliated`,
  complement: `Complement`,
  next: `Next`,
  check: `Check`,
  deposit: `Deposit`,
  eft: `Electronic Funds Transfer`,
  bankWireTransfer: `Bank Wire Transfer`,
  other: `Other`,
  cash: `Cash`,
  paymentSlip: `Payment Slip`,
  electronicTaxVoucher: `Electronic Tax Voucher For City Taxes (Brazil)`,
  unifiedCustomsDocument: `Unified Customs Document`,
  unifiedEnvironmentDocument: `Unified Environment Document`,
  stateRevenueDocument: `State Revenue Document`,
  federalRevenueDocument: `Federal Revenue Document`,
  bankFees: `Bank Fees`,
  propertyTax: `Property Tax`,
  vehicleTax: `Vehicle Tax`,
  invoiceBill: `Invoice or Bill`,
  socialSecurityPayment: `Social Security Payment Document`,
  severancePayFund: `Severance Pay Fund`,
  instantPaymentSystem: `Instant Payment System`,
  purchaseOrders: `Purchase Orders`,
  costAllocationReimbursement: `Cost Allocation Reimbursement`,
  laborTerminationCharges: `Labor Termination Charges`,
  residencyStipend: `Residency Stipend`,
  loanContractingC: `Loan/Financing Contracting (C )`,
  loanContractingD: `Loan/Financing Contracting (D)`,
  convenantRevenues: `Convenant Revenues`,
  extracontractualResources: `Extracontractual Resources`,
  expenseReimbursement: `Expense Reimbursement`,
  cashBalanceRefundC: `Cash Balance Refund (C )`,
  cashBalanceRefundD: `Cash Balance Refund (D)`,
  payrollCharges: `Payroll Charges`,
  bankAccountUnlocking: `Bank Account Unlocking`,
  bankAccountBlocking: `Bank Account Blocking`,
  fundsRefund: `Funds Refund`,
  rent: `Rent`,
  advances: `Advances`,
  alimony: `Alimony`,
  dailyAllowances: `Daily Allowances`,
  travelExpenses: `Travel Expenses`,
  reversal: `Reversal`,
  laborTermination: `Labor Termination`,
  suppliersCollateralC: `Supplier's Collateral (C )`,
  suppliersCollateralD: `Supplier's Collateral (D)`,
  courtDepositC: `Court Deposit (C )`,
  courtDepositD: `Court Deposit (D)`,
  capitalInjectionC: `Capital Injection (C )`,
  capitalInjectionD: `Capital Injection (D)`,
  individualServicesProvision: `Individual Services Provision`,
  nonGovernmentalRevenues: `Non-Governmental Revenues`,
  tevsIncome: `TEVs Income`,
  tevsTransfers: `TEVs Transfers`,
  applicationRedemption: `Application Redemption`,
  taxesWithheldAtSource: `Taxes Withheld at Source`,
  financialInvestments: `Financial Investments`,
  transfersToInvestmentAccount: `Transfers to Investment Account`,
  otherOutflows: `Other Outflows`,
  taxesFeesContributionsPayment: `Taxes, Fees and Contributions Payment`,
  suppliersPayment: `Suppliers Payment`,
  utilityBillsPayment: `Utility Bills Payment (Water, Electricity, Telephone)`,
  payrollPayment: `Payroll Payment`,
  expenseRecovery: `Expense Recovery`,
  investmentEarnings: `Investment Earnings`,
  otherInflows: `Other Inflows`,
  back: `Back`,
  discount: `Discount`,
  penalties: `Penalties`,
  penaltiesReason: `Penalties Reason`,
  icms: `ICMS`,
  iss: `ISS`,
  ir: `IR`,
  pis: `PIS`,
  cofins: `COFINS`,
  csll: `CSLL`,
  ipi: `IPI`,
  frete: `FRETE`,
  csrf: `CSRF`,
  skip: `Skip`,
  redo: `Redo`,
  formalError: `Formal Error`,
  damageToThePublicTreasury: `Damage To The Public Treasury`,
  otherNonConformities: `Other Non Conformities`,
  invoiceDue: `Invoince Due`,
  danfe: `DANFE`,
  invoiceSerie: `Invoice Serie`,
  invoiceNumber: `Invoice Number`,
  invoiceKey: `Invoice Key`,
  uploadInvoice: `Upload Invoice`,
  certificateNr: `Certificate Nr.`,
  certificateCode: `Certificate Code`,
  uploadCertificate: `Upload Certificate`,
  uploadXML: `Upload XML`,
  addFinancialEntry: `Add Financial Entry`,
  downloadTemplate: `Download Template`,
  download: `Download`,
  analytic: `Analytic`,
  synthetic: `Synthetic`,
  inactive: `Inactive`,
  alGroup: `AL Group`,
  seGroup: `SE Group`,
  isGroup: `IS Group`,
  aisGroup: `AIS Group`,
  scseGroup: `SCSE Group`,
  ebitdaGroup: `EBITDA Group`,
  scfGroup: `SCF Group`,
  scfAct: `SCF Act`,
  addLedgerAccount: `Add Ledger Account`,
  addCostCenter: `Add Cost Center`,
  addHistory: `Add History`,
  variables: `Variables`,
  addDepartment: `Add Department`,
  profitLoss: `Proft/Loss`,
  appropriations: `Appropriations`,
  totalDebit: `Total Debit`,
  totalCredit: `Total Credit`,
  accountingPeriod: `Accounting Period`,
  priorYear: `Prior Year`,
  fiscalYear: `Fiscal Year`,
  addALStructure: `Add AL Structure`,
  sourceOfFunds: `Source of Funds`,
  usesOfFunds: `Uses of Funds`,
  currentAssets: `Current Assets`,
  currentLiabilities: `Current Liabilities`,
  accountTypes: 'Account Types',
  addAccountType: 'Add Account Type',
  editAccountType: 'Edit Account Type',
  accountType: 'Account Type',
  accountGroup: 'Account Group',
  debitGroup: 'Debit',
  creditGroup: 'Credit',
  assets: 'Assets',
  liabilities: 'Liabilities',
  equity: 'Equity',
  income_statement_accounts: 'Income Statement Accounts',
  contra_accounts: 'Contra Accounts',
  fromOperations: `From Operations`,
  thirdParties: `Third Parties`,
  shareholders: `Shareholders`,
  isBalance: `IS Balance`,
  creditAmount: `Credit Amount`,
  debitAmount: `Debit Amount`,
  addSEStructure: `Add SE Structure`,
  accounts: `Accounts`,
  incomeBeforeTaxes: `Income Before Taxes`,
  beginsSubtotal: `Begins Subtotal`,
  closesSubtotal: `Closes Subtotal`,
  accountBase: `Account Base`,
  addISStructure: `Add IS Structure`,
  totalBalance: `Total Balance`,
  addAISStructure: `Add AIS Structure`,
  group: `Group`,
  subGroup: `Sub Group`,
  addSCSEStructure: `Add SCSE Structure`,
  addSCFStructure: `Add SCF Structure`,
  accountsInvertNatureOfBalance: `Accounts - Invert Nature of Balance`,
  addEBITDAStructure: `Add EBITDA Structure`,
  debitAccount: `Debit Account`,
  debitCostCenter: `Debit Cost Center`,
  debitValue: `Debit Value`,
  creditAccount: `Credit Account`,
  creditCostCenter: `Credit Cost Center`,
  creditValue: `Credit Value`,
  difference: `Difference`,
  addAccountingEntry: `Add Accounting Entry`,
  uploadBankStatement: `Upload Bank Statement`,
  employee: `Employee`,
  addReferences: `Add References`,
  addPayroll: `Add Payroll`,
  reference: `Reference `,
  amount: `Amount`,
  registrationCode: `Registration Code`,
  addCollaborator: `Add Collaborator`,
  addPosition: `Add Position`,
  empty: `Empty`,
  percentage: `Percentage`,
  wrdp: `WRDP`,
  addReference: `Add Reference`,
  incomeBaseLimit: `Income Base Limit`,
  addIncomeTax: `Add Income Tax`,
  addINSS: `Add INSS`,
  addBalance: `Add Balance`,
  addOrderBlock: `Add Order Block`,
  addRoute: `Add Route`,
  federalUnit: `Federal Unit`,
  manufacturingYear: `Manufacturing Year`,
  modelYear: `Model Year`,
  addVehicle: `Add Vehicle`,
  addSellerDriver: `Add Seller/Driver`,
  createProject: `Create Project`,
  addService: `Add Service`,
  responsible: `Responsible`,
  open: `Open`,
  inProgress: `In Progress`,
  completed: `Completed`,
  warranty: `Warranty`,
  defect: `Defect`,
  comments: `Comments`,
  technicalReport: `Technical Report`,
  finalPrice: `Final Price`,
  billed: `Billed`,
  addServiceOrder: `Add Service Order`,
  phone: `Phone`,
  addClient: `Add Client`,
  judicialCourt: `Judicial Court`,
  object: `Object`,
  riskClassification: `Risk Classification`,
  canceled: `Canceled`,
  causePrice: `Cause Price`,
  addLawsuit: `Add Lawsuit`,
  createUser: `Create User`,
  permissions: `Permissions`,
  read: `Read`,
  add: `Add`,
  entry: `Entry`,
  entries: `Entries`,
  additives: `Additives`,
  occurences: `Occurences`,
  occurence: `Occurence`,
  resources: `Resources`,
  generalContracts: `General Contracts`,
  people: `People`,
  biddings: `Biddings`,
  biddingList: `Biddings List`,
  events: `Events`,
  lots: `Lots`,
  results: `Results`,
  contractors: `Contractors`,
  companies: `Companies`,
  isThisUserAuditor: `Is this user an Auditor?`,
  applyDisableAccount: `Apply 'Disable Account'`,
  reports: `Reports`,
  report: `Report`,
  new: `New`,
  newClient: `New Client`,
  true: 'True',
  false: 'False',
  addCity: 'Add City',
  yes: `Yes`,
  no: `No`,
  eni: 'ENI',
  contractList: 'Contract List',
  newContract: 'New Contract',
  editContract: 'Edit Contract',
  contractNumber: 'Contract Nr.',
  inicialDate: 'Inicial Date',
  terminationDate: 'Termination Date',
  managementContractList: 'Management Contract List',
  managementContracts: 'Management Contracts',
  newManagementContract: 'New Management Contract',
  supplierContractList: 'Supplier Contract List',
  supplierContracts: 'Supplier Contracts',
  newSupplierContract: 'New Supplier Contract',
  installmentCount: 'Installment Count',
  installmentSerieNumber: 'Installment Serie Number',
  contractPrice: 'Contract Price',
  installmentPrice: 'Installment Price',
  supplierTypes: 'Supplier Types',
  supplierClassifications: 'Supplier Classifications',
  additiveList: 'Additive List',
  newAdditive: 'New Additive',
  additive: 'Additive',
  occurencesList: 'Occurrences List',
  newOccurrences: 'New Occurrence',
  resourcesList: 'Resources List',
  newResources: 'New Resource',
  peopleList: 'People List',
  newPeople: 'New Prople',
  contactNumber: 'Contact Nr.',
  bidding: 'Bidding',
  companiesList: 'Companies List',
  newCompany: 'New Company',
  businessName: 'Business Name',
  addressComplement: 'Address Complement',
  district: 'District',
  state: 'State',
  website: 'Website',
  contact: 'Contact',
  businessType: 'Business Type',
  contractorsList: 'Contractors List',
  newContractor: 'New Contractor',
  category: 'Category',
  resultsList: 'Results List',
  newResult: 'New Result',
  winner: 'Winner',
  qualification: 'Qualification',
  technicalNote: 'Technical Note',
  priceNote: 'Price Note',
  finalNote: 'Final Note',
  lotsList: 'Lots List',
  newLots: 'New Lots',
  newLot: 'New Lot',
  biddingNr: 'Bidding Nr.',
  term: 'Term',
  eventsList: 'Events List',
  newEvents: 'New Event',
  eventDate: 'Event Date',
  eventType: 'Event Type',
  biddingsList: 'Biddings List',
  newBiddings: 'New Biddings',
  noticeCode: 'Notice Code',
  openning: 'Openning',
  modality: 'Modality',
  editLedgerAccount: 'Edit Ledger Account',
  selectedDocument: 'Selected Document',
  editDeclaration: 'Edit Declaration',
  visualize: 'Visualize',
  viewDocument: 'View Document',
  document: 'Document',
  menu: 'Menu',
  forgotPasswordText:
    'Please enter the email address associated with your account and We will email you a link to reset your password.',
  returnToSignIn: 'Return to sign in',
  sendRequest: 'Send Request',
  editTransfer: 'Edit Transfer',
  editCommitment: `Edit Commitment`,
  editBank: 'Edit Bank',
  editFinancialAccount: 'Edit Financial Account',
  editBankStatement: 'Edit Bank Statement',
  editCostCenter: 'Edit Cost Center',
  aLGroup: 'AL Group',
  sEGroup: 'SE Group',
  iSGroup: 'IS Group',
  aISGroup: 'AIS Group',
  sCSEGroup: 'SCSE Group',
  eBITDAGroup: 'EBITDA Group',
  sCFGroup: 'SCF Group',
  sCFAct: 'SCF Act',
  both: 'Both',
  member: 'Member',
  nonMember: 'Non-Member',
  editHistory: 'Edit History',
  editDepartment: 'Edit Departament',
  aLStructureList: 'AL Structure',
  sEStructuresList: 'SE Structure',
  iSStructureList: 'Structure IS',
  aISStructureList: 'Structure AIS',
  sCSEStructureList: 'Structure SCSE',
  sCFStructureList: 'Structure SCF',
  eBITDAStructureList: 'Structure EBITDA',
  newAL: 'New Structure',
  newSE: 'New Structure',
  newIS: 'New Structure',
  newAIS: 'New Structure',
  newSCSE: 'New Structure',
  newSCF: 'New Structure',
  newEBITDA: 'New Structure',
  editALStructure: 'Edit Structure',
  editSEStructure: 'Edit Structure',
  editISStructure: 'Edit Structure',
  editAISStructure: 'Edit Structure',
  editSCSEStructure: 'Edit Structure',
  editSCFStructure: 'Edit Structure',
  editEBITDAStructure: 'Edit Structure',
  aLStructure: 'AL Structure',
  sEStructure: 'SE Structure',
  iSStructure: 'Structure IS',
  aISStructure: 'Structure AIS',
  sCSEStructure: 'Structure SCSE',
  sCFStructure: 'Structure SCF',
  eBITDAStructure: 'Structure EBITDA',
  statement: 'Statements',
  above: 'Above',
  below: 'Below',
  year: 'Year',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  accountInvertNatureOfBalance: 'Accounts - Invert Nature of Balance',
  editINSS: 'Edit INSS',
  viewInvoice: 'View Invoice',
  viewCertificate: 'View Certificate',
  downloadXml: 'Download XML',
  areYouSureYouWantToDelete: 'Are you sure want to delete?',
  areYouSureYouWantToApprove: 'Are you sure want to approve?',
  areYouSureYouWantToRedo: 'Are you sure want to redo?',
  mobile: 'Mobile',
  fax: 'Fax',
  editPeople: 'Edit People',
  baseDate: 'Base Date',
  globalValue: 'Goblal Value',
  quotaValue: 'Quota Value',
  signatureDate: 'Signature Date',
  publicationDate: 'Publication Date',
  processNumber: 'Process Number',
  processYear: 'Process Year',
  process: 'Process',
  generateReport: 'Generate Report',
  reGenerateReport: 'Re-Generate Report',
  accountabilityReport: 'Accountability Report',
  publicationVehicle: 'Publication Vehicle',
  contractDash: 'Contract Nr. ',
  changeOfObject: 'Change Of Object',
  changeOfTime: 'Change Of Time',
  changeOfValue: 'Change Of Value',
  editAdditive: 'Edit Additive',
  newOccurence: 'New Occurence',
  editOccurrence: 'Edit Occorence',
  newResource: 'New Resource',
  editResource: 'Edit Resource',
  validityTerm: 'Validity Term',
  executionSystem: 'Execution System',
  paymentConditions: 'Payment Conditions',
  criteria: 'Criteria',
  readjustment: 'Readjustment',
  restatement: 'Restatement',
  warrantyPercentage: 'Warranty Percentage',
  raw_materials: 'Raw Materials',
  packaging_material: 'Packaging Materials',
  finished_goods: 'Finished Goods',
  maintenance_repair_operations: 'Maintenance, Repair & Operations',
  marketing_advertising: 'Marketing & Advertising',
  information_technology: 'Information Technology',
  consulting_professional_services: 'Consulting & Professional Services',
  logistics_transportation: 'Logistics & Transportation',
  utilities_energy: 'Utilities & Energy',
  insurance_risk_management: 'Insurance & Risk Management',
  human_resources_recruitment: 'Human Resources & Recruitment',
  office_supplies_equipment: 'Office Supplies & Equipment',
  facilities_real_estate: 'Facilities & Real Estate',
  legal_services: 'Legal Services',
  financial_banking_services: 'Financial & Banking Services',
  serviceProvider: 'Service Provider',
  materialsSupplier: 'Materials Supplier',
  medicalSupplier: 'Medical Supplier',
  concluded: 'Concluded',
  rescinded: 'Rescinded',
  suspended: 'Suspended',
  current: 'Current',
  annual: 'Yearly',
  semiAnnual: 'Biannual',
  quarterly: 'Quarterly',
  bimonthly: 'Bimonthly',
  monthly: 'Monthly',
  bankGuarantee: 'Bank Guarantee',
  cashDeposit: 'Cash Deposit',
  suretyBond: 'Surety Bond',
  editBalance: 'Edit Balance',
  editOrderBlock: 'Edit Order Block',
  editRoute: 'Edit Route',
  editVehicle: 'Edit Vehicle',
  editSellerDriver: 'Edit Seller/Driver',
  editService: 'Edit Service',
  editServiceOrder: 'Edit Service Order',
  editClient: 'Edit Client',
  documentNr: 'Document Nr.',
  editLawsuits: 'Edit Lawsuit',
  competitor: 'Competitor',
  partner: 'Partner',
  others: 'Others',
  editCompany: 'Edit Company',
  directAdministrationAgency: 'Direct Administration Agency',
  specialFund: 'Special Fund',
  autarchy: 'Autarchy',
  publicFoundation: 'Public Foundation',
  publicCompany: 'Public Company',
  mixedEconomyCompany: 'Mixed Economy Company',
  federal: 'Federal',
  state_category: 'State',
  federalDistrict: 'Federal District',
  municipal: 'Municipal',
  editContractor: 'Edit Contractor',
  result: 'Result',
  appealDisqualification: 'Appeal of Disqualification',
  publicationInTheOfficialGazette: 'Publication in the Official Gazette',
  extension: 'Extension',
  noticeInTheOfficialGazette: 'Notice in the Official Gazette',
  openingOfDocumentation: 'Opening of Documentation',
  openingOfPriceProposals: 'Opening of Price Proposals',
  openingOfTechnicalProposal: 'Opening of Technical Proposal',
  certificatesAttest: 'Certificates Attest',
  certificates: 'Certificates',
  proposalSubmission: 'Proposal Submission',
  correctionNotice: 'Correction Notice',
  clarifications: 'Clarifications',
  awardingOfTheContract: 'Awarding of the Contract',
  published: 'Published',
  closed: 'Closed',
  revoked: 'Revoked',
  validated: 'Validated',
  contractor: 'Contrator',
  biddingType: 'Bidding Type',
  biddingSystem: 'Bidding System',
  index: 'Index',
  local: 'Local',
  bestTechnicalProposal: 'Best Technical Proposal',
  lowestBid: 'Lowest Bid',
  technicalAndPrice: 'Technical and Price',
  lumpSumContract: 'Lump Sum Contract',
  unitPriceContract: 'Unit Price Contract',
  competitiveBidding: 'Competitive Bidding',
  contest: 'Contest',
  invitationToBid: 'Invitation to Bid',
  auction: 'Auction',
  electronicAuction: 'Electronic Auction',
  invitationForBids: 'Invitation for Bids',
  editBidding: 'Edit Bidding',
  editEvent: 'Edit Event',
  editResult: 'Edit Result',
  editLot: 'Edit Lot',
  editEntriesImport: 'Edit Entry Import',
  notAllEntryHasAOffsetting: 'Not All Selected Rows Has A Offsetting!',
  someSelectedRowsWasAlreadyAccounted: 'Some Selected Rows Was Already Accounted!',
  requestSentSuccessfully: 'Request sent successfully!',
  enterTheCodeInBelowBoxToVerifyYourEmail:
    'Please enter the code in below box to verify your email.',
  weHaveSentYouA6DigitCodeConfirmationEmai: "We've sent a 6-digit confirmation code to your email.",
  dontHaveACode: 'Don’t have a code?',
  resendCode: 'Resend code',
  updatePassword: 'Update Password',
  emailSent: 'Email sent',
  date_is_required: 'Date is required.',
  field_is_required: 'Field is required.',
  operation_number_is_required: 'Operation number is required.',
  total_fare_is_required: 'Total fare is required.',
  transfer_price_is_required: 'Transfer price is required.',
  transfer_classification_is_required: 'Transfer classification is required.',
  name_is_required: 'Name is required.',
  email_is_required: 'Email is required.',
  email_must_be_a_valid_email_address: 'Email must be a valid email address.',
  phone_number_is_required: 'Phone number is required.',
  address_is_required: 'Address is required.',
  country_is_required: 'Country is required.',
  company_is_required: 'Company is required.',
  state_is_required: 'State is required.',
  city_is_required: 'City is required.',
  role_is_required: 'Role is required.',
  avatar_must_be_an_image: 'Avatar must be an image.',
  avatar_is_required: 'Avatar is required.',
  ledger_account_is_required: 'Ledger account is required.',
  file_is_required: 'File is required.',
  initial_date_is_required: 'Initial date is required.',
  end_date_is_required: 'End date is required.',
  first_responsible_is_required: 'First responsible is required.',
  second_responsible_is_required: 'Second responsible is required.',
  description_is_required: 'Description is required.',
  code_is_required: 'Code is required.',
  date_id_required: 'Date ID required.',
  department_id_id_required: 'Department ID required.',
  debit_ledger_account_id_is_required: 'Debit ledger account ID is required.',
  debit_value_is_required: 'Debit value is required.',
  credit_ledger_account_id_is_required: 'Credit ledger account ID is required.',
  credit_value_is_required: 'Credit value is required.',
  history_is_required: 'History is required.',
  classification_is_required: 'Classification is required.',
  type_is_required: 'Type is required.',
  situation_is_required: 'Situation is required.',
  status_id_required: 'Status ID required.',
  contractor_id_id_required: 'Contractor ID required.',
  modality_id_required: 'Modality ID required.',
  bidding_type_id_required: 'Bidding type ID required.',
  bidding_system_id_required: 'Bidding system ID required.',
  opening_date_id_required: 'Opening date ID required.',
  notice_code_id_required: 'Notice code ID required.',
  bidding_code_id_required: 'Bidding code ID required.',
  bidding_index_id_required: 'Bidding index ID required.',
  bidding_price_id_required: 'Bidding price ID required.',
  local_id_required: 'Local ID required.',
  object_id_required: 'Object ID required.',
  history_id_required: 'History ID required.',
  business_name_is_required: 'Business name is required.',
  registry_number_is_required: 'Registry number is required.',
  district_is_required: 'District is required.',
  zip_code_is_required: 'Zip code is required.',
  website_is_required: 'Website is required.',
  business_type_is_required: 'Business type is required.',
  category_is_required: 'Category is required.',
  event_date_is_required: 'Event date is required.',
  bidding_id_is_required: 'Bidding ID is required.',
  event_type_is_required: 'Event type is required.',
  lot_number_is_required: 'Lot number is required.',
  lot_price_is_required: 'Lot price is required.',
  lot_term_is_required: 'Lot term is required.',
  company_id_is_required: 'Company ID is required.',
  qualification_is_required: 'Qualification is required.',
  winner_is_required: 'Winner is required.',
  technical_note_is_required: 'Technical note is required.',
  price_note_is_required: 'Price note is required.',
  final_note_is_required: 'Final note is required.',
  price_is_required: 'Price is required.',
  discount_is_required: 'Discount is required.',
  title_is_required: 'Title is required.',
  signature_date_is_required: 'Signature date is required.',
  publication_date_is_required: 'Publication date is required.',
  number_is_required: 'Number is required.',
  process_number_is_required: 'Process number is required.',
  contract_id_is_required: 'Contract ID is required.',
  additivestype_is_required: 'Additives type is required.',
  process_year_is_required: 'Process year is required.',
  publication_vehicle_is_required: 'Publication vehicle is required.',
  global_value_is_required: 'Global value is required.',
  quota_value_is_required: 'Quota value is required.',
  term_is_required: 'Term is required.',
  document_is_required: 'Document is required.',
  base_date_is_required: 'Base date is required.',
  inicial_date_is_required: 'Initial date is required.',
  validity_term_is_required: 'Validity term is required.',
  validity_term_type_is_required: 'Validity term type is required.',
  object_is_required: 'Object is required.',
  execution_bidding_system_is_required: 'Execution bidding system is required.',
  payment_conditions_is_required: 'Payment conditions is required.',
  criteria_is_required: 'Criteria is required.',
  readjustment_is_required: 'Readjustment is required.',
  restatement_is_required: 'Restatement is required.',
  warranty_is_required: 'Warranty is required.',
  warranty_percentage_is_required: 'Warranty percentage is required.',
  commitment_number_is_required: 'Commitment number is required.',
  contract_number_is_required: 'Contract number is required.',
  occurrence_date_is_required: 'Occurrence date is required.',
  occurrence_date: 'Occurrence date',
  occurrence_type_is_required: 'Occurrence type is required.',
  occurrence_text_is_required: 'Occurrence text is required.',
  mobile_is_required: 'Mobile number is required.',
  fax_is_required: 'Fax number is required.',
  people_id_is_required: 'People ID is required.',
  installment_count_is_required: 'Installment count is required.',
  installment_serie_number_is_required: 'Installment series number is required.',
  installment_price_is_required: 'Installment price is required.',
  publication_date_cannot_be_smaller_than_signature_date:
    'Publication date cannot be smaller than signature date',
  user_or_password_invalid: 'User Or Password Invalid',
  supplier_types_is_required: 'Supplier types are required.',
  supplier_classification_is_required: 'Supplier classification is required.',
  cost_center_id_is_required: 'Cost center ID is required.',
  financial_account_id_is_required: 'Financial account ID is required.',
  statement_code_is_required: 'Statement code is required.',
  data_is_required: 'Date is required.',
  commitment_code_is_required: 'Commitment code is required.',
  commitment_phase_is_required: 'Commitment phase is required.',
  transfer_id_is_required: 'Transfer ID is required.',
  commitment_price_is_required: 'Commitment price is required.',
  account_is_required: 'Account is required.',
  account_code_is_required: 'Account code is required.',
  agency_is_required: 'Agency is required.',
  agency_code_is_required: 'Agency code is required.',
  accounting_account_is_required: 'Accounting account is required.',
  bank_id_is_required: 'Bank ID is required.',
  opening_balance_is_required: 'Opening balance is required.',
  document_date_is_required: 'Document date is required.',
  payment_date_is_required: 'Payment date is required.',
  document_price_is_required: 'Document price is required.',
  amount_paid_is_required: 'Amount paid is required.',
  document_type_is_required: 'Document type is required.',
  operation_type_is_required: 'Operation type is required.',
  registration_code_is_required: 'Registration code is required.',
  full_name_is_required: 'Full name is required.',
  income_base_is_required: 'Income base is required.',
  position_id_is_required: 'Position ID is required.',
  admission_date_is_required: 'Admission date is required.',
  department_id_is_required: 'Department ID is required.',
  sector_is_required: 'Sector is required.',
  dependents_is_required: 'Dependents are required.',
  monthly_hours_is_required: 'Monthly hours are required.',
  income_base_limit_is_required: 'Income base limit is required.',
  deduction_is_required: 'Deduction is required.',
  rate_is_required: 'Rate is required.',
  employee_id_is_required: 'Employee ID is required.',
  income_tax_per_dependent_is_required: 'Income tax per dependent is required.',
  inss_cap_is_required: 'INSS cap is required.',
  max_inss_discount_is_required: 'Max INSS discount is required.',
  fgts_rate_is_required: 'FGTS rate is required.',
  hour_rate_is_required: 'Hour rate is required.',
  subtract_is_required: 'Subtract is required.',
  calculateWRDP_is_required: 'Calculate WRDP is required.',
  reference_type_is_required: 'Reference type is required.',
  judicial_court_is_required: 'Judicial court is required.',
  opening_date_is_required: 'Opening date is required.',
  seller_id_is_required: 'Seller ID is required.',
  return_is_required: 'Return is required.',
  driver_id_is_required: 'Driver ID is required.',
  license_plate_is_required: 'License plate is required.',
  federal_unit_is_required: 'Federal unit is required.',
  reindeer_is_required: 'Reindeer is required.',
  chassi_is_required: 'Chassi is required.',
  manufacturing_year_is_required: 'Manufacturing year is required.',
  model_is_required: 'Model is required.',
  phone_is_required: 'Phone is required.',
  clients_id_is_required: 'Clients ID is required.',
  responsible_is_required: 'Responsible is required.',
  service_date_is_required: 'Service date is required.',
  status_is_required: 'Status is required.',
  final_price_is_required: 'Final price is required.',
  services_id_is_required: 'Services ID is required.',
  billed_is_required: 'Billed is required.',
  license_number_is_required: 'License number is required.',
  old_Password_is_required: 'Old Password is required.',
  password_must_be_at_least_6_characters: 'Password must be at least 6 characters.',
  new_password_is_required: 'New password is required.',
  passwords_must_match: 'Passwords must match.',
  password_is_required: 'Password is required.',
  confirm_password_is_required: 'Confirm password is required.',
  first_name_required: 'First name is required.',
  last_name_required: 'Last name is required.',
  registryNumberPerson: 'Registry Number',
  end_date_cannot_be_smaller_than_initial_date: 'End date cannot be earlier than the initial date.',
  termination_date_cannot_be_smaller_than_initial_date:
    'Termination date cannot be earlier than the initial date.',
  editCollaborator: 'Edit Collaborator',
  difference_in_debit_and_credit_values: 'Difference in Debit and Credit Values',
  the_debit_and_credit_values_are_different_do_you_want_to_continue:
    'The debit and credit values are different. Do you want to continue?',
  continue: 'Continue',
  editAccountingEntry: 'Edit Accounting Entry',
  inactivation_date_cannot_be_smaller_than_current_date:
    'Inactivation date cannot be earlier than the current date.',
  supplier_contract_is_required: 'Supplier Contract is required.',
  invalid_date: 'Invalid Date, please review it.',
  file_too_large: 'File exceeds the maximum size limit (75MB)',
  something_went_wrong_please_check_your_files_again:
    'Something went wrong. Please check your files again.',
  clear: 'Clear',
  create_success: 'Create Success!',
  edit_success: 'Edit Success!',
  away: 'away',
  busy: 'busy',
  offline: 'offline',
  invisible: 'invisible',
  selected: 'selected',
  are_you_sure_you_want_to_delete_multiple_1: 'Are you sure want to delete',
  are_you_sure_you_want_to_delete_multiple_2: 'items?',
  account_already_exists: 'Account Already Exists',
  toAccount: 'Account',
  are_you_sure_want_to_account: 'Are you sure want to Account',
  history_already_exists: 'History Already Exists',
  field_cannot_exceed_240_char: 'The field cannot exceed 240 characters',
  field_cannot_exceed_124_char: 'The field cannot exceed 124 characters',
  price_limit_exceed: 'Price limit exceeded',
  required_field: 'Required Field',
  cost_center_already_exists: 'Cost Center code already in use',
  department_already_exists: 'Department code already in use',
  validity_term_type: 'Term Type',
  service_date_cannot_be_smaller_than_openning_date:
    'Service date cannot be earlier than the oppening date',
  editSupplierContract: 'Edit Supplier Contract',
  position_already_exists: 'Position already exists',
};

export default en;
