import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

RHFCurrencyField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFCurrencyField({ name, helperText, ...other }) {
  const { control } = useFormContext();

  const handleClick = (event) => {
    event.target.select();
  };

  const formatValue = (value) => {
    if (!value) return '';

    const intValue = parseInt(value.replace(/[^\d]/g, ''), 10);

    const formattedValue = (intValue / 100).toFixed(2);
    return formattedValue.replace('.', ',');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumericFormat
          {...field}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          allowNegative={false}
          isNumericString
          customInput={TextField}
          fullWidth
          decimalScale={2} // limite de 2 casas decimais
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          onClick={handleClick}
          helperText={error ? error?.message : helperText}
          format={formatValue}
          {...other}
        />
      )}
    />
  );
}
