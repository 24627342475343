import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Iconify from '../../../components/iconify/Iconify';
import { closeDialog } from '../../../redux/slices/dialog';
import { useDispatch, useSelector } from '../../../redux/store';
import { getAccountabilities } from '../../../redux/slices/accountability';
import { useLocales } from '../../../locales';

function AccountabilitySelector() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const params = useParams();
  const isOpen = useSelector((state) => state.dialog.isOpen);
  const accountabilities = useSelector((state) => state.accountability.accountabilities);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleListItemClick = (item) => {
    localStorage.setItem('accountability', JSON.stringify(item));
    dispatch(closeDialog());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getAccountabilities(params));
  }, [dispatch, params]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('selectAccountability')}</DialogTitle>
        <DialogContent>
          <List sx={{ pt: 0, height: 270, width: 300 }}>
            {accountabilities.map((c) => (
              <ListItem disableGutters key={c.id}>
                <ListItemButton onClick={() => handleListItemClick(c)} key={c.id}>
                  <ListItemIcon>
                    {c.rectifier ? (
                      <Iconify icon="ci:redo" />
                    ) : (
                      <Iconify icon="ant-design:file-done-outlined" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={c.rectifier ? `${c.description} - rectifier` : c.description}
                    secondary={c.reportingPeriod}
                    about="teste"
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            {translate('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AccountabilitySelector;
