import { noCase } from 'change-case';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { fToNow } from '../../../utils/formatTime';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import Scrollbar from '../../../components/scrollbar';
import { useSnackbar } from '../../../components/snackbar';
import socket from '../../../socket';
// redux
import { useAuthContext } from '../../../auth/useAuthContext';
import {
  getNotifications,
  markAllNotifications,
  markNotifications,
} from '../../../redux/slices/notification';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user: logedUser } = useAuthContext();
  const [openPopover, setOpenPopover] = useState(null);
  const { translate } = useLocales();

  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    dispatch(markAllNotifications(logedUser.id));
  };

  useEffect(() => {
    dispatch(getNotifications(logedUser.id));

    socket.on('newNotification', (response) => {
      dispatch(getNotifications(logedUser.id));
      enqueueSnackbar(response.title, { variant: 'info' });
    });
  }, [dispatch, logedUser, enqueueSnackbar]);

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications')}</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('youHaveUnreadMessages').replace('{totalUnRead}', totalUnRead)}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {notifications.slice(0, 6).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={{ ...notification, createdAt: new Date(notification.createdAt) }}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    description: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);
  const dispatch = useDispatch();

  const handleMarkAsRead = () => {
    if (notification.isUnRead) {
      dispatch(markNotifications(notification.id));
    }
  };

  return (
    <ListItemButton
      onClick={handleMarkAsRead}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
