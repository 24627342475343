import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  federalUnits: [],
  balances: [],
  balance: null,
  seller_drivers: [],
  seller_driver: null,
  vehicles: [],
  vehicle: null,
  routes: [],
  route: null,
  orderBlocks: [],
  orderBlock: null,
};

const slice = createSlice({
  name: 'logistics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.federalUnits = [];
      state.balances = [];
      state.balance = null;
      state.seller_drivers = [];
      state.seller_driver = null;
      state.vehicles = [];
      state.vehicle = null;
      state.routes = [];
      state.route = null;
      state.orderBlocks = [];
      state.orderBlock = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BALANCES
    getBalancesSuccess(state, action) {
      const balances = action.payload;

      state.isLoading = false;
      state.balances = balances;
    },

    // GET BALANCE
    getBalanceSuccess(state, action) {
      const balance = action.payload;

      state.isLoading = false;
      state.balance = balance;
    },

    // GET SELLERS/DRIVERS
    getSellersDriversSuccess(state, action) {
      const seller_drivers = action.payload;

      state.isLoading = false;
      state.seller_drivers = seller_drivers;
    },

    // GET SELLER/DRIVER
    getSellerDriverSuccess(state, action) {
      const seller_driver = action.payload;

      state.isLoading = false;
      state.seller_driver = seller_driver;
    },

    // GET VEHICLES
    getVehiclesSuccess(state, action) {
      const vehicles = action.payload;

      state.isLoading = false;
      state.vehicles = vehicles;
    },

    // GET VEHICLE
    getVehicleSuccess(state, action) {
      const vehicle = action.payload;

      state.isLoading = false;
      state.vehicle = vehicle;
    },

    // GET ROUTES
    getRoutesSuccess(state, action) {
      const routes = action.payload;

      state.isLoading = false;
      state.routes = routes;
    },

    // GET ROUTE
    getRouteSuccess(state, action) {
      const route = action.payload;
      state.route = null;

      state.isLoading = false;
      state.route = route;
    },

    // GET ORDER BLOCKS
    getOrderBlocksSuccess(state, action) {
      const orderBlocks = action.payload;

      state.isLoading = false;
      state.orderBlocks = orderBlocks;
    },

    // GET ORDER BLOCK
    getOrderBlockSuccess(state, action) {
      const orderBlock = action.payload;

      state.isLoading = false;
      state.orderBlock = orderBlock;
    },

    // GET FEDERAL UNITS
    getFederalUnitsSuccess(state, action) {
      const federalUnits = action.payload;

      state.isLoading = false;
      state.federalUnits = federalUnits;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBalances() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/balance');
      dispatch(slice.actions.getBalancesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBalance(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/balance/${protocol}`);
      dispatch(slice.actions.getBalanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSellersDrivers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/seller-driver');
      dispatch(slice.actions.getSellersDriversSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSellerDriver(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/seller-driver/${protocol}`);
      dispatch(slice.actions.getSellerDriverSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVehiclesDrivers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/vehicles');
      dispatch(slice.actions.getVehiclesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVehicleDriver(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/vehicles/${protocol}`);
      dispatch(slice.actions.getVehicleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRoutes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/routes');
      dispatch(slice.actions.getRoutesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRoute(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/routes/${protocol}`);
      dispatch(slice.actions.getRouteSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderBlocks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/order-blocks');
      dispatch(slice.actions.getOrderBlocksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderBlock(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/order-blocks/${protocol}`);
      dispatch(slice.actions.getOrderBlockSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFederalUnits() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch('https://brasilapi.com.br/api/ibge/uf/v1');
      dispatch(slice.actions.getFederalUnitsSuccess(await response.json()));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
