import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

let logoutFunction; // Variable to store the logout function

export const setLogoutFunction = (logout) => {
  logoutFunction = logout;
};

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = (error.response && error.response.data) || 'Something went wrong';

    if (errorMessage === 'Unauthorized' && logoutFunction) {
      logoutFunction();
    }

    return Promise.reject(errorMessage);
  }
);

export default axiosInstance;
