import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  lawsuits: [],
  lawsuit: null,
};

const slice = createSlice({
  name: 'lawsuits',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LAWSUITS
    getLawsuitsSuccess(state, action) {
      const lawsuits = action.payload;

      state.isLoading = false;
      state.lawsuits = lawsuits;
    },

    // GET LAWSUIT
    getLawsuitSuccess(state, action) {
      const lawsuit = action.payload;

      state.isLoading = false;
      state.lawsuit = lawsuit;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLawsuits() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/lawsuits', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getLawsuitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLawsuit(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/lawsuits/${protocol}`);
      dispatch(slice.actions.getLawsuitSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  };
}
