import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  projects: [],
  project: null,
  clients: [],
  client: null,
  services: [],
  service: null,
  service_orders: [],
  service_order: null,
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROJECTS
    getProjectsSuccess(state, action) {
      const projects = action.payload;

      state.isLoading = false;
      state.projects = projects;
    },

    // GET PROJECT
    getProjectSuccess(state, action) {
      const project = action.payload;

      state.isLoading = false;
      state.project = project;
    },

    // GET CLIENTS
    getClientsSuccess(state, action) {
      const clients = action.payload;

      state.isLoading = false;
      state.clients = clients;
    },

    // GET CLIENT
    getClientSuccess(state, action) {
      const client = action.payload;

      state.isLoading = false;
      state.client = client;
    },

    // GET SERVICES
    getServicesSuccess(state, action) {
      const services = action.payload;

      state.isLoading = false;
      state.services = services;
    },

    // GET SERVICE
    getServiceSuccess(state, action) {
      const service = action.payload;

      state.isLoading = false;
      state.service = service;
    },

    // GET SERVICE ORDERS
    getServiceOrdersSuccess(state, action) {
      const service_orders = action.payload;

      state.isLoading = false;
      state.service_orders = service_orders;
    },

    // GET SERVICE ORDER
    getServiceOrderSuccess(state, action) {
      const service_order = action.payload;

      state.isLoading = false;
      state.service_order = service_order;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProjects() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/projects');
      dispatch(slice.actions.getProjectsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProject(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/projects/${protocol}`);
      dispatch(slice.actions.getProjectSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClients() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/clients');
      dispatch(slice.actions.getClientsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClient(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/clients/${protocol}`);
      dispatch(slice.actions.getClientSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/services');
      dispatch(slice.actions.getServicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getService(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/${protocol}`);
      dispatch(slice.actions.getServiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServiceOrders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/service-orders');
      dispatch(slice.actions.getServiceOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServiceOrder(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/service-orders/${protocol}`);
      dispatch(slice.actions.getServiceOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
