import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import userReducer from './user/users';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import notificationReducer from './slices/notification';
import dialogReducer from './slices/dialog';
import accountabilityReducer from './slices/accountability';
import financialReducer from './slices/financial';
import lawsuitsReducer from './slices/lawsuits';
import logisticsReducer from './slices/logistics';
import accountingReducer from './slices/accounting';
import projectsReducer from './slices/projects';
import humanResourcesReducer from './slices/humanResources';
import biddingsResourcesReducer from './slices/biddings';
import contractsResourcesReducer from './slices/contracts';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  user: userReducer,
  notifications: notificationReducer,
  product: persistReducer(productPersistConfig, productReducer),
  dialog: dialogReducer,
  accountability: accountabilityReducer,
  financial: financialReducer,
  lawsuits: lawsuitsReducer,
  logistics: logisticsReducer,
  accounting: accountingReducer,
  projects: projectsReducer,
  humanResources: humanResourcesReducer,
  biddings: biddingsResourcesReducer,
  contracts: contractsResourcesReducer,
});

export default rootReducer;
