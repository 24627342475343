import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  accountabilities: [],
  accountability: null,
  declarations: [],
  declaration: null,
  commitmentTransfer: [],
};

const slice = createSlice({
  name: 'accountability',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACCOUNTABILITIES
    getAccountabilitiesSuccess(state, action) {
      const accountabilities = action.payload;

      state.isLoading = false;
      state.accountabilities = accountabilities;
    },

    // GET ACCOUNTABILITY
    getAccountabilitySuccess(state, action) {
      const accountability = action.payload;

      state.isLoading = false;
      state.accountability = accountability;
    },

    // GET DECLARATIONS
    getDeclarationsSuccess(state, action) {
      const declarations = action.payload;

      state.isLoading = false;
      state.declarations = declarations;
    },

    // GET DECLARATION
    getDeclarationSuccess(state, action) {
      const declaration = action.payload;

      state.isLoading = false;
      state.declaration = declaration;
    },

    clearDeclarationSuccess(state, action) {
      state.declaration = null;
    },

    // GET DECLARATION
    getCommitmentTransferSuccess(state, action) {
      const commitmentTransfer = action.payload;

      state.isLoading = false;
      state.commitmentTransfer = commitmentTransfer;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAccountabilities(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/accountability', { params });
      dispatch(slice.actions.getAccountabilitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAccountability(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/accountability/${protocol}`);
      dispatch(slice.actions.getAccountabilitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDeclarations(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/declarations', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getDeclarationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDeclaration(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/declarations/${protocol}`);
      dispatch(slice.actions.getDeclarationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearDeclaration(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.clearDeclarationSuccess());
  };
}

// ----------------------------------------------------------------------

export function getCommitmentTransfer(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/accountability/transfer-commitment`);
      dispatch(slice.actions.getCommitmentTransferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  };
}

// ----------------------------------------------------------------------

export function stopLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.stopLoading());
  };
}
