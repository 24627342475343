import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  mask: PropTypes.string, // optional mask
};

export default function RHFTextField({ name, helperText, mask, ...other }) {
  const { control } = useFormContext();

  return mask ? (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask mask={mask} value={field.value} onChange={field.onChange} {...other}>
          {(inputProps) => (
            <TextField
              {...inputProps}
              fullWidth
              value={
                typeof field.value === 'number' && (field.value === 0 || field.value < 0)
                  ? ''
                  : field.value
              }
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...other}
            />
          )}
        </InputMask>
      )}
    />
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === 'number' && (field.value === 0 || field.value < 0)
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
