// @mui
import { enUS, ptBR } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Português',
    value: 'pt',
    systemValue: ptBR,
    icon: '/assets/icons/flags/Flag_of_Brazil.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  // {
  //   label: 'Español',
  //   value: 'es',
  //   systemValue: esES,
  //   icon: '/assets/icons/flags/Flag_of_Spain.svg',
  // },
  // {
  //   label: 'Deutsch',
  //   value: 'de',
  //   systemValue: deDE,
  //   icon: '/assets/icons/flags/ic_flag_de.svg',
  // },
  // {
  //   label: 'Italiano',
  //   value: 'it',
  //   systemValue: itIT,
  //   icon: '/assets/icons/flags/ic_flag_it.svg',
  // },
];

export const defaultLang = allLangs[0];
