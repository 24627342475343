import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  accountingEntries: [],
  accountingEntry: null,
  bankStatementAccountingEntries: [],
  bankStatementAccountingEntry: null,
  ledgerAccounts: [],
  ledgerAccount: null,
  departments: [],
  department: null,
  costCenters: [],
  costCenter: null,
  histories: [],
  history: null,
  aisStructures: [],
  aisStructure: null,
  alStructures: [],
  alStructure: null,
  ebitdaStructures: [],
  ebitdaStructure: null,
  isStructures: [],
  isStructure: null,
  scfStructures: [],
  scfStructure: null,
  scseStructures: [],
  scseStructure: null,
  seStructures: [],
  seStructure: null,
};

const slice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.accountingEntries = [];
      state.accountingEntry = null;
      state.bankStatementAccountingEntries = [];
      state.bankStatementAccountingEntry = null;
      state.ledgerAccounts = [];
      state.ledgerAccount = null;
      state.ledgerAccountsMask = '';
      state.departments = [];
      state.department = null;
      state.costCenters = [];
      state.costCenter = null;
      state.histories = [];
      state.history = null;
      state.aisStructures = [];
      state.aisStructure = null;
      state.alStructures = [];
      state.alStructure = null;
      state.ebitdaStructures = [];
      state.ebitdaStructure = null;
      state.isStructures = [];
      state.isStructure = null;
      state.scfStructures = [];
      state.scfStructure = null;
      state.scseStructures = [];
      state.scseStructure = null;
      state.seStructures = [];
      state.seStructure = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACCOUNTING ENTRIES
    getAccountingEntriesSuccess(state, action) {
      const accountingEntries = action.payload;

      state.isLoading = false;
      state.accountingEntries = accountingEntries;
    },

    // GET ACCOUNTING ENTRY
    getAccountingEntrySuccess(state, action) {
      const accountingEntry = action.payload;

      if (
        !accountingEntry.debit_value.toString().includes('.') &&
        !accountingEntry.debit_value.toString().endsWith('.00')
      ) {
        accountingEntry.debit_value += '.00';
      }

      accountingEntry.debit_value = accountingEntry.debit_value.toString().replace('.', ',');

      if (
        !accountingEntry.credit_value.toString().includes('.') &&
        !accountingEntry.credit_value.toString().endsWith('.00')
      ) {
        accountingEntry.credit_value += '.00';
      }

      accountingEntry.credit_value = accountingEntry.credit_value.toString().replace('.', ',');

      state.isLoading = false;
      state.accountingEntry = accountingEntry;
    },

    // GET ACCOUNTING ENTRIES
    getBankStatementAccountingEntriesSuccess(state, action) {
      const bankStatementAccountingEntries = action.payload;

      state.isLoading = false;
      state.bankStatementAccountingEntries = bankStatementAccountingEntries;
    },

    // GET ACCOUNTING ENTRY
    getBankStatementAccountingEntrySuccess(state, action) {
      const bankStatementAccountingEntry = action.payload;

      state.isLoading = false;
      state.bankStatementAccountingEntry = bankStatementAccountingEntry;
    },

    // GET LEDGER ACCOUNTS
    getLedgerAccountsSuccess(state, action) {
      const ledgerAccounts = action.payload;

      state.isLoading = false;
      state.ledgerAccounts = ledgerAccounts;
    },

    // GET LEDGER ACCOUNTS MASK
    getLedgerAccountsMaskSuccess(state, action) {
      const ledgerAccountsMask = action.payload;

      state.isLoading = false;
      state.ledgerAccountsMask = ledgerAccountsMask;
    },

    // GET LEDGER ACCOUNT
    getLedgerAccountSuccess(state, action) {
      const ledgerAccount = action.payload;

      state.isLoading = false;
      state.ledgerAccount = ledgerAccount;
    },

    // GET DEPARTMENTS
    getDepartmentsSuccess(state, action) {
      const departments = action.payload;

      state.isLoading = false;
      state.departments = departments;
    },

    // GET DEPARTMENT
    getDepartmentSuccess(state, action) {
      const department = action.payload;

      state.isLoading = false;
      state.department = department;
    },

    // GET Cost Centers
    getCostCentersSuccess(state, action) {
      const costCenters = action.payload;

      state.isLoading = false;
      state.costCenters = costCenters;
    },

    // GET Cost Center
    getCostCenterSuccess(state, action) {
      const costCenter = action.payload;

      state.isLoading = false;
      state.costCenter = costCenter;
    },

    // GET HISTORIES
    getHistoriesSuccess(state, action) {
      const histories = action.payload;

      state.isLoading = false;
      state.histories = histories;
    },

    // GET HISTORY
    getHistorySuccess(state, action) {
      const history = action.payload;

      state.isLoading = false;
      state.history = history;
    },

    // GET AIS Structures
    getAISStructuresSuccess(state, action) {
      const aisStructures = action.payload;

      state.isLoading = false;
      state.aisStructures = aisStructures;
    },

    // GET AIS Structure
    getAISStructureSuccess(state, action) {
      const aisStructure = action.payload;

      state.isLoading = false;
      state.aisStructure = aisStructure;
    },

    // GET AL Structures
    getALStructuresSuccess(state, action) {
      const alStructures = action.payload;

      state.isLoading = false;
      state.alStructures = alStructures;
    },

    // GET AL Structure
    getALStructureSuccess(state, action) {
      const alStructure = action.payload;

      state.isLoading = false;
      state.alStructure = alStructure;
    },

    // GET EBITDA Structures
    getEBITDAStructuresSuccess(state, action) {
      const ebitdaStructures = action.payload;

      state.isLoading = false;
      state.ebitdaStructures = ebitdaStructures;
    },

    // GET EBITDA Structure
    getEBITDAStructureSuccess(state, action) {
      const ebitdaStructure = action.payload;

      state.isLoading = false;
      state.ebitdaStructure = ebitdaStructure;
    },

    // GET IS Structures
    getISStructuresSuccess(state, action) {
      const isStructures = action.payload;

      state.isLoading = false;
      state.isStructures = isStructures;
    },

    // GET IS Structure
    getISStructureSuccess(state, action) {
      const isStructure = action.payload;

      state.isLoading = false;
      state.isStructure = isStructure;
    },

    // GET SCF Structures
    getSCFStructuresSuccess(state, action) {
      const scfStructures = action.payload;

      state.isLoading = false;
      state.scfStructures = scfStructures;
    },

    // GET SCF Structure
    getSCFStructureSuccess(state, action) {
      const scfStructure = action.payload;

      state.isLoading = false;
      state.scfStructure = scfStructure;
    },

    // GET SCSE Structures
    getSCSEStructuresSuccess(state, action) {
      const scseStructures = action.payload;

      state.isLoading = false;
      state.scseStructures = scseStructures;
    },

    // GET SCSE Structure
    getSCSEStructureSuccess(state, action) {
      const scseStructure = action.payload;

      state.isLoading = false;
      state.scseStructure = scseStructure;
    },

    // GET SE Structures
    getSEStructuresSuccess(state, action) {
      const seStructures = action.payload;

      state.isLoading = false;
      state.seStructures = seStructures;
    },

    // GET SE Structure
    getSEStructureSuccess(state, action) {
      const seStructure = action.payload;

      state.isLoading = false;
      state.seStructure = seStructure;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAccountingEntries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/accountings-entries', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getAccountingEntriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAccountingEntry(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/accountings-entries/${protocol}`);
      dispatch(slice.actions.getAccountingEntrySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBankStatementAccountingEntries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/accountings-entries/bank-statement');
      dispatch(slice.actions.getBankStatementAccountingEntriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBankStatementAccountingEntry(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/accountings-entries/bank-statement/${protocol}`);
      dispatch(slice.actions.getBankStatementAccountingEntrySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLedgerAccounts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/ledger-account');
      dispatch(slice.actions.getLedgerAccountsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLedgerAccountsMask() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/ledger-account/account-mask');
      dispatch(slice.actions.getLedgerAccountsMaskSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLedgerAccount(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ledger-account/${protocol}`);
      dispatch(slice.actions.getLedgerAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDepartments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/department');
      dispatch(slice.actions.getDepartmentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDepartment(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/department/${protocol}`);
      dispatch(slice.actions.getDepartmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCostCenters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/cost-center');
      dispatch(slice.actions.getCostCentersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCostCenter(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/cost-center/${protocol}`);
      dispatch(slice.actions.getCostCenterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getHistories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/history');
      dispatch(slice.actions.getHistoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getHistory(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/history/${protocol}`);
      dispatch(slice.actions.getHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAISStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/ais-structures');
      dispatch(slice.actions.getAISStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAISStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ais-structures/${id}`);
      dispatch(slice.actions.getAISStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getALStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/al-structures');
      dispatch(slice.actions.getALStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getALStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/al-structures/${id}`);
      dispatch(slice.actions.getALStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEBITDAStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/ebitda-structures');
      dispatch(slice.actions.getEBITDAStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEBITDAStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ebitda-structures/${id}`);
      dispatch(slice.actions.getEBITDAStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getISStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/is-structures');
      dispatch(slice.actions.getISStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getISStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/is-structures/${id}`);
      dispatch(slice.actions.getISStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSCFStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/scf-structures');
      dispatch(slice.actions.getSCFStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSCFStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/scf-structures/${id}`);
      dispatch(slice.actions.getSCFStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSCSEStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/scse-structures');
      dispatch(slice.actions.getSCSEStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSCSEStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/scse-structures/${id}`);
      dispatch(slice.actions.getSCSEStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSEStructures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/se-structures');
      dispatch(slice.actions.getSEStructuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSEStructure(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/se-structures/${id}`);
      dispatch(slice.actions.getSEStructureSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  };
}
