import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from './utils/axios';

function ApiHealthCheck() {
  const [isApiAvailable, setIsApiAvailable] = useState(true);
  const navigate = useNavigate();

  async function checkApiHealth() {
    try {
      const response = await axios.get('/app/healthcheck');
      if (response.status === 200) {
        if (!isApiAvailable) {
          navigate('/');
          setIsApiAvailable(true);
        }
      } else {
        setIsApiAvailable(false);
        navigate('/maintenance');
      }
    } catch (error) {
      setIsApiAvailable(false);
      navigate('/maintenance');
    }
  }

  useEffect(() => {
    checkApiHealth();
    const intervalId = setInterval(checkApiHealth, 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  if (!isApiAvailable) {
    return null;
  }

  return <div />;
}

export default ApiHealthCheck;
