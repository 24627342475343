import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  positions: [],
  position: null,
  references: [],
  reference: null,
  payrollPreferences: [],
  payrollPreference: null,
  payrolls: [],
  payroll: null,
  insss: [],
  inss: null,
  incomeTaxes: [],
  incomeTax: null,
  collaborators: [],
  collaborator: null,
};

const slice = createSlice({
  name: 'humanResources',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSITIONS
    getPositionsSuccess(state, action) {
      const positions = action.payload;

      state.isLoading = false;
      state.positions = positions;
    },

    // GET POSITION
    getPositionSuccess(state, action) {
      const position = action.payload;

      state.isLoading = false;
      state.position = position;
    },

    // GET REFERENCES
    getReferencesSuccess(state, action) {
      const references = action.payload;

      state.isLoading = false;
      state.references = references;
    },

    // GET REFERENCE
    getReferenceSuccess(state, action) {
      const reference = action.payload;

      state.isLoading = false;
      state.reference = reference;
    },

    // GET PAYROLL PREFERENCES
    getPayrollPreferencesSuccess(state, action) {
      const payrollPreferences = action.payload;
      payrollPreferences.income_tax_per_dependent = payrollPreferences.income_tax_per_dependent
        .toString()
        .replace('.', ',');
      payrollPreferences.inss_cap = payrollPreferences.inss_cap.toString().replace('.', ',');
      payrollPreferences.max_inss_discount = payrollPreferences.max_inss_discount
        .toString()
        .replace('.', ',');
      payrollPreferences.fgts_rate = payrollPreferences.fgts_rate.toString().replace('.', ',');

      state.isLoading = false;
      state.payrollPreferences = payrollPreferences;
    },

    // GET PAYROLL PREFERENCE
    getPayrollPreferenceSuccess(state, action) {
      const payrollPreference = action.payload;

      payrollPreference.fgts_rate = payrollPreference.fgts_rate
        .toFixed(2)
        .padStart(5, '0')
        .toString();

      payrollPreference.income_tax_per_dependent = payrollPreference.income_tax_per_dependent
        .toString()
        .replace('.', ',');
      payrollPreference.inss_cap = payrollPreference.inss_cap.toString().replace('.', ',');
      payrollPreference.max_inss_discount = payrollPreference.max_inss_discount
        .toString()
        .replace('.', ',');

      state.isLoading = false;
      state.payrollPreference = payrollPreference;
    },

    // GET PAYROLLS
    getPayrollsSuccess(state, action) {
      const payrolls = action.payload;

      state.isLoading = false;
      state.payrolls = payrolls;
    },

    // GET PAYROLL
    getPayrollSuccess(state, action) {
      const payroll = action.payload;

      state.isLoading = false;
      state.payroll = payroll;
    },

    // GET INSSS
    getInsssSuccess(state, action) {
      const insss = action.payload;

      state.isLoading = false;
      state.insss = insss;
    },

    // GET INSS
    getInssSuccess(state, action) {
      const inss = action.payload;

      inss.rate = inss.rate.toFixed(2).padStart(5, '0').toString().replace('.', ',');
      inss.income_base = inss.income_base.toString().replace('.', ',');
      inss.income_base_limit = inss.income_base_limit.toString().replace('.', ',');

      state.isLoading = false;
      state.inss = inss;
    },

    // GET INCOME TAXES
    getIncomeTaxesSuccess(state, action) {
      const incomeTaxes = action.payload;

      state.isLoading = false;
      state.incomeTaxes = incomeTaxes;
    },

    // GET INCOME TAX
    getIncomeTaxSuccess(state, action) {
      const incomeTax = action.payload;

      incomeTax.rate = incomeTax.rate.toFixed(2).padStart(5, '0').toString().replace('.', ',');
      incomeTax.income_base = incomeTax.income_base.toString().replace('.', ',');
      incomeTax.income_base_limit = incomeTax.income_base_limit.toString().replace('.', ',');
      incomeTax.deduction = incomeTax.deduction.toString().replace('.', ',');

      state.isLoading = false;
      state.incomeTax = incomeTax;
    },

    // GET COLLABORATORS
    getCollaboratorsSuccess(state, action) {
      const collaborators = action.payload;

      state.isLoading = false;
      state.collaborators = collaborators;
    },

    // GET COLLABORATOR
    getCollaboratorSuccess(state, action) {
      const collaborator = action.payload;

      state.isLoading = false;
      state.collaborator = collaborator;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPositions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/positions');
      dispatch(slice.actions.getPositionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosition(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/positions/${protocol}`);
      dispatch(slice.actions.getPositionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReferences() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/references');
      dispatch(slice.actions.getReferencesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReference(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/references/${protocol}`);
      dispatch(slice.actions.getReferenceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPayrollPreference() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/payroll-preferences');
      dispatch(slice.actions.getPayrollPreferenceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPayrolls() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const accountability_id = JSON.parse(localStorage.getItem('accountability')).id;

    try {
      const response = await axios.get('/payroll', {
        params: {
          accountability_id,
        },
      });
      dispatch(slice.actions.getPayrollsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPayroll(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/payroll/${protocol}`);
      dispatch(slice.actions.getPayrollSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInsss() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/inss');
      dispatch(slice.actions.getInsssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInss(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/inss/${protocol}`);
      dispatch(slice.actions.getInssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIncomeTaxes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/income-tax');
      dispatch(slice.actions.getIncomeTaxesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIncomeTax(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/income-tax/${protocol}`);
      dispatch(slice.actions.getIncomeTaxSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCollaborators() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/collaborators');
      dispatch(slice.actions.getCollaboratorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCollaborator(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/collaborators/${protocol}`);
      dispatch(slice.actions.getCollaboratorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
