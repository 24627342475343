import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// -------------------------------------------------------------------------------------------------------- //
// Accountability
// -------------------------------------------------------------------------------------------------------- //
export const NewAccountability = Loadable(
  lazy(() => import('../pages/dashboard/accountability/AccountabilityNewPage'))
);
export const Declarations = Loadable(
  lazy(() => import('../pages/dashboard/accountability/Declarations'))
);
export const NewDeclarations = Loadable(
  lazy(() => import('../pages/dashboard/accountability/DeclarationsNewPage'))
);
export const EditDeclarations = Loadable(
  lazy(() => import('../pages/dashboard/accountability/DeclarationsEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Accounting
// -------------------------------------------------------------------------------------------------------- //
export const AccountingEntries = Loadable(
  lazy(() => import('../pages/dashboard/accounting/AccountingEntries'))
);
export const NewAccountingEntries = Loadable(
  lazy(() => import('../pages/dashboard/accounting/AccountingEntriesNewPage'))
);
export const EditAccountingEntries = Loadable(
  lazy(() => import('../pages/dashboard/accounting/AccountingEntriesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const AccountTypes = Loadable(
  lazy(() => import('../pages/dashboard/accounting/AccountTypes'))
);
export const EditAccountTypes = Loadable(
  lazy(() => import('../pages/dashboard/accounting/AccountTypesEditPage'))
);
export const NewAccountTypes = Loadable(
  lazy(() => import('../pages/dashboard/accounting/AccountTypesNewPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const CostCenter = Loadable(lazy(() => import('../pages/dashboard/accounting/CostCenter')));
export const EditCostCenter = Loadable(
  lazy(() => import('../pages/dashboard/accounting/CostCenterEditPage'))
);
export const NewCostCenter = Loadable(
  lazy(() => import('../pages/dashboard/accounting/CostCenterNewPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const EntriesImport = Loadable(
  lazy(() => import('../pages/dashboard/accounting/EntriesImport'))
);
export const EditEntriesImport = Loadable(
  lazy(() => import('../pages/dashboard/accounting/EntriesImportEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const LedgerAccount = Loadable(
  lazy(() => import('../pages/dashboard/accounting/LedgerAccount'))
);
export const EditLedgerAccount = Loadable(
  lazy(() => import('../pages/dashboard/accounting/LedgerAccountEditPage'))
);
export const NewLedgerAccount = Loadable(
  lazy(() => import('../pages/dashboard/accounting/LedgerAccountNewPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const History = Loadable(lazy(() => import('../pages/dashboard/accounting/History')));
export const EditHistory = Loadable(
  lazy(() => import('../pages/dashboard/accounting/HistoryEditPage'))
);
export const NewHistory = Loadable(
  lazy(() => import('../pages/dashboard/accounting/HistoryNewPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Department = Loadable(lazy(() => import('../pages/dashboard/accounting/Department')));
export const BankReconciliation = Loadable(
  lazy(() => import('../pages/dashboard/accounting/BankReconciliation'))
);
export const EditDepartment = Loadable(
  lazy(() => import('../pages/dashboard/accounting/DepartmentEditPage'))
);
export const NewDepartment = Loadable(
  lazy(() => import('../pages/dashboard/accounting/DepartmentNewPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const AISStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/AISStructure'))
);
export const AISStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/AISStructureEditPage'))
);
export const AISStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/AISStructureNewPage'))
);
export const ALStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/ALStructure'))
);
export const ALStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/ALStructureEditPage'))
);
export const ALStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/ALStructureNewPage'))
);
export const EBITDAStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/EBITDAStructure'))
);
export const EBITDAStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/EBITDAStructureEditPage'))
);
export const EBITDAStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/EBITDAStructureNewPage'))
);
export const ISStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/ISStructure'))
);
export const ISStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/ISStructureEditPage'))
);
export const ISStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/ISStructureNewPage'))
);
export const SCFStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SCFStructure'))
);
export const SCFStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SCFStructureEditPage'))
);
export const SCFStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SCFStructureNewPage'))
);
export const SCSEStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SCSEStructure'))
);
export const SCSEStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SCSEStructureEditPage'))
);
export const SCSEStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SCSEStructureNewPage'))
);
export const SEStructure = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SEStructure'))
);
export const SEStructureEditPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SEStructureEditPage'))
);
export const SEStructureNewPage = Loadable(
  lazy(() => import('../pages/dashboard/accounting/structures/SEStructureNewPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Bidding
// -------------------------------------------------------------------------------------------------------- //
export const Biddings = Loadable(lazy(() => import('../pages/dashboard/bidding/Biddings')));
export const NewBiddings = Loadable(
  lazy(() => import('../pages/dashboard/bidding/BiddingsNewPage'))
);
export const EditBiddings = Loadable(
  lazy(() => import('../pages/dashboard/bidding/BiddingsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Companies = Loadable(lazy(() => import('../pages/dashboard/bidding/Companies')));
export const NewCompanies = Loadable(
  lazy(() => import('../pages/dashboard/bidding/CompaniesNewPage'))
);
export const EditCompanies = Loadable(
  lazy(() => import('../pages/dashboard/bidding/CompaniesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Contractors = Loadable(lazy(() => import('../pages/dashboard/bidding/Contractors')));
export const NewContractors = Loadable(
  lazy(() => import('../pages/dashboard/bidding/ContractorsNewPage'))
);
export const EditContractors = Loadable(
  lazy(() => import('../pages/dashboard/bidding/ContractorsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Events = Loadable(lazy(() => import('../pages/dashboard/bidding/Events')));
export const NewEvents = Loadable(lazy(() => import('../pages/dashboard/bidding/EventsNewPage')));
export const EditEvents = Loadable(lazy(() => import('../pages/dashboard/bidding/EventsEditPage')));
// -------------------------------------------------------------------------------------------------------- //
export const Lots = Loadable(lazy(() => import('../pages/dashboard/bidding/Lots')));
export const NewLots = Loadable(lazy(() => import('../pages/dashboard/bidding/LotsNewPage')));
export const EditLots = Loadable(lazy(() => import('../pages/dashboard/bidding/LotsEditPage')));
// -------------------------------------------------------------------------------------------------------- //
export const Results = Loadable(lazy(() => import('../pages/dashboard/bidding/Results')));
export const NewResults = Loadable(lazy(() => import('../pages/dashboard/bidding/ResultsNewPage')));
export const EditResults = Loadable(
  lazy(() => import('../pages/dashboard/bidding/ResultsEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Contracts
// -------------------------------------------------------------------------------------------------------- //
export const Additive = Loadable(lazy(() => import('../pages/dashboard/contracts/Additive')));
export const NewAdditive = Loadable(
  lazy(() => import('../pages/dashboard/contracts/AdditiveNewPage'))
);
export const EditAdditive = Loadable(
  lazy(() => import('../pages/dashboard/contracts/AdditiveEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Contracts = Loadable(lazy(() => import('../pages/dashboard/contracts/Contracts')));
export const NewContracts = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ContractsNewPage'))
);
export const EditContracts = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ContractsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const ManagementContract = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ManagementContract'))
);
export const NewManagementContract = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ManagementContractNewPage'))
);
export const EditManagementContract = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ManagementContractEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Occurrences = Loadable(lazy(() => import('../pages/dashboard/contracts/Occurrences')));
export const NewOccurrences = Loadable(
  lazy(() => import('../pages/dashboard/contracts/OccurrencesNewPage'))
);
export const EditOccurrences = Loadable(
  lazy(() => import('../pages/dashboard/contracts/OccurrencesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const People = Loadable(lazy(() => import('../pages/dashboard/contracts/People')));
export const NewPeople = Loadable(lazy(() => import('../pages/dashboard/contracts/PeopleNewPage')));
export const EditPeople = Loadable(
  lazy(() => import('../pages/dashboard/contracts/PeopleEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Resources = Loadable(lazy(() => import('../pages/dashboard/contracts/Resources')));
export const NewResources = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ResourcesNewPage'))
);
export const EditResources = Loadable(
  lazy(() => import('../pages/dashboard/contracts/ResourcesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const SupplierContract = Loadable(
  lazy(() => import('../pages/dashboard/contracts/SupplierContract'))
);
export const NewSupplierContract = Loadable(
  lazy(() => import('../pages/dashboard/contracts/SupplierContractNewPage'))
);
export const EditSupplierContract = Loadable(
  lazy(() => import('../pages/dashboard/contracts/SupplierContractEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Financial
// -------------------------------------------------------------------------------------------------------- //
export const BankStatements = Loadable(
  lazy(() => import('../pages/dashboard/financial/BankStatements'))
);
export const NewBankStatements = Loadable(
  lazy(() => import('../pages/dashboard/financial/BankStatementsNewPage'))
);
export const EditBankStatements = Loadable(
  lazy(() => import('../pages/dashboard/financial/BankStatementsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Commitments = Loadable(lazy(() => import('../pages/dashboard/financial/Commitments')));
export const NewCommitments = Loadable(
  lazy(() => import('../pages/dashboard/financial/CommitmentsNewPage'))
);
export const EditCommitments = Loadable(
  lazy(() => import('../pages/dashboard/financial/CommitmentsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Bank = Loadable(lazy(() => import('../pages/dashboard/financial/Bank')));
export const NewBank = Loadable(lazy(() => import('../pages/dashboard/financial/BankNewPage')));
export const EditBank = Loadable(lazy(() => import('../pages/dashboard/financial/BankEditPage')));
// -------------------------------------------------------------------------------------------------------- //
export const FinancialAccounts = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialAccounts'))
);
export const NewFinancialAccounts = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialAccountsNewPage'))
);
export const EditFinancialAccounts = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialAccountsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const FinancialEntries = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialEntries'))
);
export const NewFinancialEntries = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialEntriesNewPage'))
);
export const EditFinancialEntries = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialEntriesEditPage'))
);
export const AccountFinancialEntries = Loadable(
  lazy(() => import('../pages/dashboard/financial/AccountFinancialEntriesPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const FinancialEntriesAudition = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialEntriesAudition'))
);
export const NewFinancialEntriesAudition = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialEntriesAuditionNewPage'))
);
export const EditFinancialEntriesAudition = Loadable(
  lazy(() => import('../pages/dashboard/financial/FinancialEntriesAuditionEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Transfer = Loadable(lazy(() => import('../pages/dashboard/financial/Transfer')));
export const NewTransfer = Loadable(
  lazy(() => import('../pages/dashboard/financial/TransferNewPage'))
);
export const EditTransfer = Loadable(
  lazy(() => import('../pages/dashboard/financial/TransferEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Human Resources
// -------------------------------------------------------------------------------------------------------- //
export const Collaborators = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/Collaborators'))
);
export const NewCollaborators = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/CollaboratorsNewPage'))
);
export const EditCollaborators = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/CollaboratorsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Payrolls = Loadable(lazy(() => import('../pages/dashboard/human-resources/Payrolls')));
export const NewPayrolls = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PayrollsNewPage'))
);
export const EditPayrolls = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PayrollsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Positions = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/Positions'))
);
export const NewPositions = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PositionsNewPage'))
);
export const EditPositions = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PositionsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Refenreces = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/References'))
);
export const NewRefenreces = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/ReferencesNewPage'))
);
export const EditRefenreces = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/ReferencesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const IncomeTax = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/IncomeTax'))
);
export const NewIncomeTax = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/IncomeTaxNewPage'))
);
export const EditIncomeTax = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/IncomeTaxEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const INSS = Loadable(lazy(() => import('../pages/dashboard/human-resources/INSS')));
export const NewINSS = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/INSSNewPage'))
);
export const EditINSS = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/INSSEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const PayrollPreferences = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PayrollPreferences'))
);
export const NewPayrollPreferences = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PayrollPreferencesNewPage'))
);
export const EditPayrollPreferences = Loadable(
  lazy(() => import('../pages/dashboard/human-resources/PayrollPreferencesEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Lawsuits
// -------------------------------------------------------------------------------------------------------- //
export const Lawsuits = Loadable(lazy(() => import('../pages/dashboard/lawsuits/Lawsuits')));
export const NewLawsuits = Loadable(
  lazy(() => import('../pages/dashboard/lawsuits/LawsuitsNewPage'))
);
export const EditLawsuits = Loadable(
  lazy(() => import('../pages/dashboard/lawsuits/LawsuitsEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Logistics
// -------------------------------------------------------------------------------------------------------- //
export const Balance = Loadable(lazy(() => import('../pages/dashboard/logistics/Balance')));
export const NewBalance = Loadable(
  lazy(() => import('../pages/dashboard/logistics/BalanceNewPage'))
);
export const EditBalance = Loadable(
  lazy(() => import('../pages/dashboard/logistics/BalanceEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const OrderBlocks = Loadable(lazy(() => import('../pages/dashboard/logistics/OrderBlocks')));
export const NewOrderBlocks = Loadable(
  lazy(() => import('../pages/dashboard/logistics/OrderBlocksNewPage'))
);
export const EditOrderBlocks = Loadable(
  lazy(() => import('../pages/dashboard/logistics/OrderBlocksEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Routes = Loadable(lazy(() => import('../pages/dashboard/logistics/Routes')));
export const NewRoutes = Loadable(lazy(() => import('../pages/dashboard/logistics/RoutesNewPage')));
export const EditRoutes = Loadable(
  lazy(() => import('../pages/dashboard/logistics/RoutesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Vehicles = Loadable(lazy(() => import('../pages/dashboard/logistics/Vehicles')));
export const NewVehicles = Loadable(
  lazy(() => import('../pages/dashboard/logistics/VehiclesNewPage'))
);
export const EditVehicles = Loadable(
  lazy(() => import('../pages/dashboard/logistics/VehiclesEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const SellerDrivers = Loadable(
  lazy(() => import('../pages/dashboard/logistics/SellerDriver'))
);
export const NewSellerDrivers = Loadable(
  lazy(() => import('../pages/dashboard/logistics/SellerDriverNewPage'))
);
export const EditSellerDrivers = Loadable(
  lazy(() => import('../pages/dashboard/logistics/SellerDriverEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Project
// -------------------------------------------------------------------------------------------------------- //
export const Clients = Loadable(lazy(() => import('../pages/dashboard/project/Clients')));
export const NewClients = Loadable(lazy(() => import('../pages/dashboard/project/ClientsNewPage')));
export const EditClients = Loadable(
  lazy(() => import('../pages/dashboard/project/ClientsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Projects = Loadable(lazy(() => import('../pages/dashboard/project/Projects')));
export const NewProjects = Loadable(
  lazy(() => import('../pages/dashboard/project/ProjectsNewPage'))
);
export const EditProjects = Loadable(
  lazy(() => import('../pages/dashboard/project/ProjectsEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const ServiceOrders = Loadable(
  lazy(() => import('../pages/dashboard/project/ServiceOrders'))
);
export const NewServiceOrders = Loadable(
  lazy(() => import('../pages/dashboard/project/ServiceOrdersNewPage'))
);
export const EditServiceOrders = Loadable(
  lazy(() => import('../pages/dashboard/project/ServiceOrdersEditPage'))
);
// -------------------------------------------------------------------------------------------------------- //
export const Services = Loadable(lazy(() => import('../pages/dashboard/project/Services')));
export const NewServices = Loadable(
  lazy(() => import('../pages/dashboard/project/ServicesNewPage'))
);
export const EditServices = Loadable(
  lazy(() => import('../pages/dashboard/project/ServicesEditPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// Reports
// -------------------------------------------------------------------------------------------------------- //
export const Reports = Loadable(lazy(() => import('../pages/dashboard/reports/Reports')));
export const TransferReportGeneral = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/transfer/TransferReportGeneral'))
);
export const TransferReportApproved = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/transfer/TransferReportApproved'))
);
export const TransferReportReproved = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/transfer/TransferReportReproved'))
);
export const TransferReportAwaitingAudition = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/transfer/TransferReportAwaitingAudition'))
);
export const CommitmentsReportGeneral = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/commitments/CommitmentReportGeneral'))
);
export const CommitmentsReportAwaitingAudition = Loadable(
  lazy(() =>
    import('../pages/dashboard/reports/financial/commitments/CommitmentReportAwaitingAudition')
  )
);
export const CommitmentsReportApproved = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/commitments/CommitmentReportApproved'))
);
export const CommitmentsReportReproved = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/commitments/CommitmentReportReproved'))
);
export const FinancialEntryReportGeneral = Loadable(
  lazy(() =>
    import('../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportGeneral')
  )
);
export const FinancialEntryReportAwaitingAudition = Loadable(
  lazy(() =>
    import(
      '../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportAwaitingAudition'
    )
  )
);
export const FinancialEntryReportApproved = Loadable(
  lazy(() =>
    import('../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportApproved')
  )
);
export const FinancialEntryReportReproved = Loadable(
  lazy(() =>
    import('../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportReproved')
  )
);
export const FinancialEntryReportFormalError = Loadable(
  lazy(() =>
    import('../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportFormalError')
  )
);
export const FinancialEntryReportDamageToThePublicTreasury = Loadable(
  lazy(() =>
    import(
      '../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportDamageToThePublicTreasury'
    )
  )
);
export const FinancialEntryReportOtherNonConformities = Loadable(
  lazy(() =>
    import(
      '../pages/dashboard/reports/financial/financial-entries/FinancialEntryReportOtherNonConformities'
    )
  )
);
export const PenaltiesReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/penalties/PenaltiesReport'))
);
export const FutureEntriesReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/financial/future-entries/FutureEntriesReport'))
);
export const EntriesByContractReport = Loadable(
  lazy(() =>
    import('../pages/dashboard/reports/financial/entries-by-contract/EntriesByContractReport')
  )
);
export const ISReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/accounting/is/ISReport'))
);
export const AnalyticalBalanceSheetReport = Loadable(
  lazy(() =>
    import(
      '../pages/dashboard/reports/accounting/balance-sheet/analytic/AnalyticalBalanceSheetReport'
    )
  )
);
export const AccumulatedAnalyticalBalanceSheetReport = Loadable(
  lazy(() =>
    import(
      '../pages/dashboard/reports/accounting/balance-sheet/analytic-accumulated/AccumulatedAnalyticalBalanceSheetReport'
    )
  )
);
export const SyntheticBalanceSheetReport = Loadable(
  lazy(() =>
    import(
      '../pages/dashboard/reports/accounting/balance-sheet/synthetic/SyntheticBalanceSheetReport'
    )
  )
);
export const BalanceSheetReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/accounting/balance-sheet/BalanceSheetReport'))
);
export const DiaryReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/accounting/diary/DiaryReport'))
);
export const LedgerReport = Loadable(
  lazy(() => import('../pages/dashboard/reports/accounting/ledger/LedgerReport'))
);

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: GENERAL
// -------------------------------------------------------------------------------------------------------- //
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const GeneralEcommercePage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralEcommercePage'))
);
export const GeneralAnalyticsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalyticsPage'))
);
export const GeneralBankingPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralBankingPage'))
);
export const GeneralBookingPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralBookingPage'))
);
export const GeneralFilePage = Loadable(lazy(() => import('../pages/dashboard/GeneralFilePage')));

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: ECOMMERCE
// -------------------------------------------------------------------------------------------------------- //
export const EcommerceShopPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceShopPage'))
);
export const EcommerceProductDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetailsPage'))
);
export const EcommerceProductListPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductListPage'))
);
export const EcommerceProductCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreatePage'))
);
export const EcommerceProductEditPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductEditPage'))
);
export const EcommerceCheckoutPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceCheckoutPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: INVOICE
// -------------------------------------------------------------------------------------------------------- //
export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceListPage')));
export const InvoiceDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceDetailsPage'))
);
export const InvoiceCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceCreatePage'))
);
export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceEditPage')));

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: USER
// -------------------------------------------------------------------------------------------------------- //
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));
export const GeneralSettingsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralSettingsPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: BLOG
// -------------------------------------------------------------------------------------------------------- //
export const BlogPostsPage = Loadable(lazy(() => import('../pages/dashboard/BlogPostsPage')));
export const BlogPostPage = Loadable(lazy(() => import('../pages/dashboard/BlogPostPage')));
export const BlogNewPostPage = Loadable(lazy(() => import('../pages/dashboard/BlogNewPostPage')));

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: FILE MANAGER
// -------------------------------------------------------------------------------------------------------- //
export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));

// -------------------------------------------------------------------------------------------------------- //
// DASHBOARD: APP
// -------------------------------------------------------------------------------------------------------- //
export const ChatPage = Loadable(lazy(() => import('../pages/dashboard/ChatPage')));
export const MailPage = Loadable(lazy(() => import('../pages/dashboard/MailPage')));
export const CalendarPage = Loadable(lazy(() => import('../pages/dashboard/CalendarPage')));
export const KanbanPage = Loadable(lazy(() => import('../pages/dashboard/KanbanPage')));

// -------------------------------------------------------------------------------------------------------- //
// TEST RENDER PAGE BY ROLE
// -------------------------------------------------------------------------------------------------------- //
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// -------------------------------------------------------------------------------------------------------- //
// BLANK PAGE
// -------------------------------------------------------------------------------------------------------- //
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// -------------------------------------------------------------------------------------------------------- //
// MAIN
// -------------------------------------------------------------------------------------------------------- //
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
