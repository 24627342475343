import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  generalContracts: [],
  generalContract: null,
  managementContracts: [],
  managementContract: null,
  supplierContracts: [],
  supplierContract: null,
  additives: [],
  additive: null,
  occurrences: [],
  occurrence: null,
  resources: [],
  resource: null,
  peoples: [],
  people: null,
};

const slice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET GENERAL CONTRACTS
    getGeneralContractsSuccess(state, action) {
      const generalContracts = action.payload;

      state.isLoading = false;
      state.generalContracts = generalContracts;
    },

    // GET GENERAL CONTRACT
    getGeneralContractSuccess(state, action) {
      const generalContract = action.payload;

      state.isLoading = false;
      state.generalContract = generalContract;
    },

    // GET MANAGEMENT CONTRACTSS
    getManagementContractsSuccess(state, action) {
      const managementContracts = action.payload;

      state.isLoading = false;
      state.managementContracts = managementContracts;
    },

    // GET MANAGEMENT CONTRACTS
    getManagementContractSuccess(state, action) {
      const managementContract = action.payload;

      state.isLoading = false;
      state.managementContract = managementContract;
    },

    // GET SUPPLIER CONTRACTS
    getSupplierContractsSuccess(state, action) {
      const supplierContracts = action.payload;

      state.isLoading = false;
      state.supplierContracts = supplierContracts;
    },

    // GET SUPPLIER CONTRACT
    getSupplierContractSuccess(state, action) {
      const supplierContract = action.payload;

      state.isLoading = false;
      state.supplierContract = supplierContract;
    },

    // GET ADDITIVES
    getAdditivesSuccess(state, action) {
      const additives = action.payload;

      state.isLoading = false;
      state.additives = additives;
    },

    // GET ADDITIVE
    getAdditiveSuccess(state, action) {
      const additive = action.payload;

      state.isLoading = false;
      state.additive = additive;
    },

    // GET OCCURRENCES
    getOccurrencesSuccess(state, action) {
      const occurrences = action.payload;

      state.isLoading = false;
      state.occurrences = occurrences;
    },

    // GET OCCURRENCE
    getOccurrenceSuccess(state, action) {
      const occurrence = action.payload;

      state.isLoading = false;
      state.occurrence = occurrence;
    },

    // GET RESOURCES
    getResourcesSuccess(state, action) {
      const resources = action.payload;

      state.isLoading = false;
      state.resources = resources;
    },

    // GET RESOURCE
    getResourceSuccess(state, action) {
      const resource = action.payload;

      state.isLoading = false;
      state.resource = resource;
    },

    // GET PEOPLES
    getPeoplesSuccess(state, action) {
      const peoples = action.payload;

      state.isLoading = false;
      state.peoples = peoples;
    },

    // GET PEOPLE
    getPeopleSuccess(state, action) {
      const people = action.payload;

      state.isLoading = false;
      state.people = people;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGeneralContracts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/general-contracts');
      dispatch(slice.actions.getGeneralContractsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGeneralContract(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/general-contracts/${protocol}`);
      dispatch(slice.actions.getGeneralContractSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getManagementContracts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/managements-contracts');
      dispatch(slice.actions.getManagementContractsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getManagementContract(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/managements-contracts/${protocol}`);
      dispatch(slice.actions.getManagementContractSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSupplierContracts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/supplier-contracts');
      dispatch(slice.actions.getSupplierContractsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSupplierContract(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/supplier-contracts/${protocol}`);
      dispatch(slice.actions.getSupplierContractSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAdditives() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/additives');
      dispatch(slice.actions.getAdditivesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAdditive(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/additives/${protocol}`);
      dispatch(slice.actions.getAdditiveSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOccurrences() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/occurrences');
      dispatch(slice.actions.getOccurrencesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOccurrence(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/occurrences/${protocol}`);
      dispatch(slice.actions.getOccurrenceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getResources() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/resources');
      dispatch(slice.actions.getResourcesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getResource(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/resources/${protocol}`);
      dispatch(slice.actions.getResourceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPeoples() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/people');
      dispatch(slice.actions.getPeoplesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPeople(protocol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/people/${protocol}`);
      dispatch(slice.actions.getPeopleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function startLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  };
}
